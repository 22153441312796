import React, { useCallback, useMemo } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import HeaderBreadcrumb from "../../../components/headerbreadcrumb/HeaderBreadcrumb";
import "./productList.css";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import RingDiamondStep from "../../../components/ringdiamondsteps/RingDiamondStep";
import CardProduct from "../../../components/cardproduct/CardProduct";
import useDiamondSteps from "../../../customuse/useDiamondSteps";
import MetaInformation from "../../../components/metainformation/MetaInformation";
import useAuthorizationToken from "../../../auth/useAuthorizationToken";
import Slider from "react-slick";

import { Slider as InputSlider } from "@material-ui/core";
import classNames from "classnames";
import { Close, SearchOutlined, TuneOutlined } from "@material-ui/icons";
import { shapesfilters } from "../../../json/diamondfilters";

function ProductList({ setLoading }) {

  //const priceMinMax = [1400, 20000];
  const selectedfilters = JSON.parse(sessionStorage.getItem("_pointersproductfilters"));
  const priceMinMax = useMemo(() => {
    return [1000, 20000];
  }, []);
  const { getAccessToken } = useAuthorizationToken();
  const { checkNeedDiamondStep } = useDiamondSteps();
  const [headerinfo, setHeaderInfo] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState();
  let params = useParams();
  let location = useLocation();
  const shouldRunEffect = useRef(true);
  const shouldRunBreadcrumbEffect = useRef(true);
  const shouldRunMetaInformationEffect = useRef(true);
  const shouldLoadFiltersEffect = useRef(true);
  const categorySlug = params?.categorySlug;
  const subcategorySlug = params?.subcategorySlug;
  const [category, setCategory] = useState();
  const [subcategories, setSubcategories] = useState();
  const [subcategory, setSubcategory] = useState();
  // const [error, setError] = useState(null);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [items, setItems] = useState([]);
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const handleFiltersModalClose = () => setShowFiltersModal(false);
  const handleFiltersModalShow = () => setShowFiltersModal(true);


  const [filterPriceMin, setFilterPriceMin] = useState(priceMinMax[0]);
  const [filterPriceMax, setFilterPriceMax] = useState(priceMinMax[1]);
  const [priceInputType, setPriceInputType] = useState("text");
  const [shapes, setShapes] = useState([]);

  const subcategoriesslidersetting = {
    slidesToShow: subcategories?.length,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    infinite: subcategories?.length > 8,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
    ],
  };

  const formatPriceWithComma = (price) => {
    console.log(price);
    if (price) return parseFloat(price)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return 0.0;
  };

  const initializeBreadcrumb = useCallback(() => {
    var curBreadcrumb = [];
    if (category?.name) {
      curBreadcrumb.push({
        url:
          "/products/" +
          category?.name
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
            .trim()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, ""),
        label: category?.name,
      });
    }
    if (category?.name && subcategory?.name) {
      curBreadcrumb.push({
        url:
          "/products/" +
          category.name
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
            .trim()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "") +
          "/" +
          subcategory.name
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
            .trim()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, ""),
        label: subcategory?.name,
      });
    }
    setBreadcrumbs(curBreadcrumb);
  }, [category, subcategory]);

  const initializeHeader = useCallback(() => {
    var str = [];

    if (category?.name) str.push(category?.name);

    if (subcategory?.name) str.push(subcategory?.name);

    var strjoin = str.join(" > ");

    var curheader = {
      title: (strjoin ? strjoin : "Products") + " | Pointers Jewellers",
      description: "",
      keyword: "",
      author: "",
      og: {
        title: (strjoin ? strjoin : "Products") + " | Pointers Jewellers",
        description: "",

        image: window.location.origin + "/pointers/pointers_logo_social.png",
        url: window.location.href,
      },
    };
    setHeaderInfo(curheader);
  }, [category, subcategory]);

  const fetchData = useCallback((isreset = false) => {
    async function fetchProductsList() {
      setLoading(true);
      var isMainCategoryHide = [1];
      var categoryid = 0;
      var subcategoryid = 0;

      setIsLoadMore(false);
      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/lov/PRODUCT_CATEGORY`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Channel-ID": "pointers-web",
            "X-Request-ID":
              "POINTERS_WEB" +
              Date.now() +
              (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
                .toString()
                .padStart(7, "0"),
          },
        }
      )
        .then((response) => response.json())
        .then((catdata) => {
          if (catdata?.lov) {
            catdata?.lov
              .filter((item) => {
                return !isMainCategoryHide.includes(parseInt(item.id));
              })
              .forEach((category) => {
                category.slug = category.name
                  .toLowerCase()
                  .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
                  .trim()
                  .replace(/ /g, "-")
                  .replace(/[^\w-]+/g, "");
                if (category.slug === categorySlug) {
                  setCategory(category);
                  setSubcategories(category?.sub);
                  categoryid = category.id;
                  category.sub.forEach((subcategory) => {
                    subcategory.slug = subcategory.name
                      .toLowerCase()
                      .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
                      .trim()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, "");
                    if (subcategory.slug === subcategorySlug) {
                      setSubcategory(subcategory);
                      subcategoryid = subcategory.id;
                    }
                  });
                }
              });
          }
          // initializeBreadcrumb();
        });

      var api = `${process.env.REACT_APP_API_ENDPOINT}/product/list?page=${isreset ? 1 : currentPage}&limit=${perPage}`;
      var filterArr = [];

      const queryParams = new URLSearchParams(location.search);
      const qryKeyword = queryParams.get("keyword");

      if (qryKeyword && qryKeyword !== "") {
        api += `&q=${qryKeyword}`;
      }

      if (categoryid) {
        filterArr.push(`main=${categoryid}`);
      }

      if (subcategoryid) {
        filterArr.push(`sub=${subcategoryid}`);
      }
      if (shapes) {
        filterArr.push(`shape=${shapes}`);
      }

      // if (filterPriceMin) {
      //   filterArr.push(`min_price=${filterPriceMin !== priceMinMax[0] ? filterPriceMin : ""}`);
      // }


      // if (filterPriceMax) {
      //   filterArr.push(`max_price=${filterPriceMax !== priceMinMax[1] ? filterPriceMax : ""}`);
      // }

      if (filterPriceMin) {
        filterArr.push(`min_price=${filterPriceMin}`);
      }


      if (filterPriceMax) {
        filterArr.push(`max_price=${filterPriceMax}`);
      }

      filterArr.push(`active=yes`);

      console.log(filterArr);

      if (filterArr.length > 0) {
        var randomStr = (Math.random() + 1).toString(36).substring(2, 7);
        api += `&filters=${randomStr}${btoa(filterArr.join(`&`))}${randomStr}`;
      }

      fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Channel-ID": "pointers-web",
          "X-Request-ID":
            "POINTERS_WEB" +
            Date.now() +
            (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
              .toString()
              .padStart(7, "0"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          var curItems = null;
          if (isreset) {
            curItems = [...data?.products];
          } else {
            curItems = [...items, ...data?.products];
          }

          // curItems = [...items, ...data?.products];
          setItems(curItems);
          if (data?.next) {
            setCurrentPage(data?.next);
            setIsLoadMore(true);
          }
          // setError(null);
        });
    }
    fetchProductsList();
    sessionStorage.setItem("_pointerssetting", JSON.stringify({}));
  }, [categorySlug, currentPage, items, subcategorySlug, location, setLoading, filterPriceMin, filterPriceMax, shapes]);

  useEffect(() => {
    if (shouldLoadFiltersEffect.current) {
      console.log(selectedfilters);
      if (selectedfilters?.filterPriceMin) setFilterPriceMin(selectedfilters?.filterPriceMin);
      if (selectedfilters?.filterPriceMax) setFilterPriceMax(selectedfilters?.filterPriceMax);
      if (selectedfilters?.shapes) setShapes(selectedfilters?.shapes);
      sessionStorage.removeItem("_pointersproductfilters");
      shouldLoadFiltersEffect.current = false;
    }

    if (shouldRunBreadcrumbEffect.current) {
      initializeBreadcrumb();
      shouldRunBreadcrumbEffect.current = false;
    }
    if (shouldRunMetaInformationEffect.current) {
      initializeHeader();
      shouldRunMetaInformationEffect.current = false;
    }
    if (shouldRunEffect.current) {
      if (!getAccessToken()) {
        sessionStorage.removeItem("_pointerredirect");
        sessionStorage.setItem(
          "_pointerredirect",
          window.location.pathname !== "/login"
            ? window.location.pathname
            : "/profile"
        );
      }

      fetchData();
      shouldRunEffect.current = false;
    }
  }, [fetchData, initializeBreadcrumb, initializeHeader, getAccessToken, priceMinMax, selectedfilters]);

  const handlePriceSliderChange = (event, newValue) => {
    setFilterPriceMin(newValue[0]);
    setFilterPriceMax(newValue[1]);
  };

  const handleFilterPriceMinChange = (e) => {
    var value = parseFloat(e.target.value?.replace(",", ""));
    setFilterPriceMin(value < 0 ? -value : value);
  };

  const handleFilterPriceMaxChange = (e) => {
    var value = parseFloat(e.target.value?.replace(",", ""));

    setFilterPriceMax(value < 0 ? -value : value);
  };

  const handleFilterPriceMinFocus = (e) => {
    setPriceInputType("number");
  };

  const handleFilterPriceMaxFocus = (e) => {
    setPriceInputType("number");
  };

  const handleFilterPriceMinBlur = (e) => {
    setPriceInputType("text");
    var value = parseFloat(e.target.value?.replace(",", ""));
    if (value <= priceMinMax[0]) {
      value = priceMinMax[0];
    } else if (value >= priceMinMax[1]) {
      value = priceMinMax[1];
    }

    if (value > filterPriceMax) {
      setFilterPriceMin(filterPriceMax < 0 ? -filterPriceMax : filterPriceMax);
      setFilterPriceMax(value < 0 ? -value : value);
    } else {
      setFilterPriceMin(value < 0 ? -value : value);
    }
  };

  const handleFilterPriceMaxBlur = (e) => {
    setPriceInputType("text");
    var value = parseFloat(e.target.value?.replace(",", ""));
    // if (/^\d+(\.\d*)?$/.test(value)) {
    if (value <= priceMinMax[0]) {
      value = priceMinMax[0];
    } else if (value >= priceMinMax[1]) {
      value = priceMinMax[1];
    }

    if (value < filterPriceMin) {
      setFilterPriceMax(filterPriceMin < 0 ? -filterPriceMin : filterPriceMin);
      setFilterPriceMin(value < 0 ? -value : value);
    } else {
      setFilterPriceMax(value < 0 ? -value : value);
    }
    // } else {
    //   alert("Please enter the valid price.");
    // }
  };

  const handleShapesChange = (e, shape) => {
    if (shapes === shape)
      setShapes(null);
    else
      setShapes(shape);
  };

  const handleFilterNowClick = (e) => {
    handleFiltersModalClose();
    setItems([]);
    setCurrentPage(1);
    fetchData(true);
  }

  const handleShopByStyleOnClick = (e) => {
    var filter = {
      shapes: shapes,
      filterPriceMin: filterPriceMin,
      filterPriceMax: filterPriceMax
    };
    sessionStorage.setItem("_pointersproductfilters", JSON.stringify(filter));
  }

  return (
    <>
      <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <h1 className=" text-uppercase text-center mb-4">
        {subcategory?.name ? subcategory?.name : category?.name}
      </h1>
      {checkNeedDiamondStep(category) ? (
        <RingDiamondStep currrent_step={1} />
      ) : (
        ""
      )}

      <div className="productlist-content">
        <div className={"container shop-by-categories text-center mb-4 "+ classNames({
          "absolute-d-none": items?.length<=0? true:false
        })}>
          <h4>Shop by Styles</h4>
          <Slider
            {...subcategoriesslidersetting}
            className={classNames({
              "col-md-6 offset-md-3": subcategories?.length <= 6,
            })}
          >
            {subcategories?.map((subcat) => (
              <a
                href={
                  "/products/" +
                  categorySlug +
                  "/" +
                  subcat.name
                    .toLowerCase()
                    .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
                    .trim()
                    .replace(/ /g, "-")
                    .replace(/[^\w-]+/g, "")
                } onClick={handleShopByStyleOnClick}
              >
                <div className={"px-1 " + classNames({
                  "active":subcat?.id===subcategory?.id
                })}>
                  <div className="subcat-img">
                    <img
                      src={"/pointers/sub_category/" + subcat.image_name}
                      alt={subcat.name}
                      className="img-fluid"
                    />
                  </div>
                  <div className="subcat-text">{subcat.name}</div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
        <div className={"container text-center section-filter d-none d-md-block " + classNames({
          "absolute-d-none": items?.length<=0? true:false
        }) } >
          <div className={classNames({
            "tooltip-filter": true,
            "p-3": true,
            "active": showFiltersModal
          })}>
            <div className="form-filters mb-3">
              <div className="text-shape mb-3">
                <h4>Shop by Shape: {shapes}</h4>
              </div>
              <div className="section text-shape mb-4">
                <Row>
                  {shapesfilters.map((shapefilter, index) => (

                    <div className={classNames({
                      "btn-shape": true,
                      active: shapes?.includes(shapefilter.value),
                    })}
                      onClick={(e) => handleShapesChange(e, shapefilter.value)}>
                      <img alt="Round" src={shapefilter.img} className="img-fluid" />
                      <div className="label-shape">{shapefilter.label}</div>
                    </div>

                  ))}

                </Row>

                <div className="clearfix"></div>
              </div>
              <div className="filter-price">
                <h4>Shop by Price (MYR)</h4>
                <div className="section">
                  <InputSlider
                    value={[filterPriceMin ? filterPriceMin : 0.0, filterPriceMax ? filterPriceMax : 0.0]}
                    onChange={handlePriceSliderChange}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    step={0.01}
                    valueLabelDisplay="auto"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMin)
                        : filterPriceMin
                    }
                    onChange={handleFilterPriceMinChange}
                    onFocus={handleFilterPriceMinFocus}
                    onBlur={handleFilterPriceMinBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange left"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMax)
                        : filterPriceMax
                    }
                    onChange={handleFilterPriceMaxChange}
                    onFocus={handleFilterPriceMaxFocus}
                    onBlur={handleFilterPriceMaxBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange right"
                  />
                  <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>
            <Button size="lg" className="btn-red text-uppercase" onClick={handleFilterNowClick}><SearchOutlined /> Search Now</Button>
            <div class="text-center">
              <div className="link-reset mt-2" onClick={e => { handleFiltersModalClose() }} >Back</div>
            </div>
          </div>
          <Button
            type="button"
            variant="primary"
            size="lg"
            className={classNames({
              "btn-red": true,
              "text-uppercase": true,
              "btn-filter":true,
              "d-none": showFiltersModal
            })}
            onClick={e => { showFiltersModal ? handleFiltersModalClose(e) : handleFiltersModalShow(e) }}
          >
            <TuneOutlined /> Advanced Search
          </Button>

        </div>

        <hr />
        <div className="container mb-5">
          {items?.length > 0 ? (
            <>
              <Row>
                {items.map((item, index) => (
                  <Col sm="6" md="4" lg="3">
                    <CardProduct item={item} key={index} />
                  </Col>
                ))}
                {items.map((item, index) => (
                  <Col sm="6" md="4" lg="3">
                    <CardProduct item={item} key={index} />
                  </Col>
                ))}
              </Row>
              {isLoadMore ? (
                <Row className="mb-5">
                  <Col className="text-center">
                    <Button
                      type="button"
                      variant="primary"
                      size="lg"
                      className="btn-red text-uppercase"
                      onClick={(e) => fetchData()}
                    >
                      Load More
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          ) : (
            <img
              src="/pointers/coming-soon.webp"
              className="img-fluid w-100"
              alt="Coming soon"
            />
          )}
        </div>

        {/* <Modal show={showFiltersModal} onHide={handleFiltersModalClose} size="xs" className="modal-filters">

          <Modal.Body>
            <Close className="icon-close" onClick={e => { handleFiltersModalClose() }} />
            <h3>Advanced Search</h3>
            <hr />
            <div className="form-filters mb-4">
              <div className="text-shape">
                <strong>Shop by Shape: {shapes}</strong>
              </div>
              <div className="section text-shape">
                {shapesfilters.map((shapefilter, index) => (

                  <div
                    className={classNames({
                      "btn-shape": true,
                      "mb-4": true,
                      active: shapes?.includes(shapefilter.value),
                    })}
                    onClick={(e) => handleShapesChange(e, shapefilter.value)}
                  >
                    <img alt="Round" src={shapefilter.img} className="img-fluid" />
                    <div className="label-shape">{shapefilter.label}</div>
                  </div>
                ))}

                <div className="clearfix"></div>
              </div>
              <div>
                <strong>Shop by Price (MYR)</strong>
                <div className="section">
                  <InputSlider
                    value={[filterPriceMin ? filterPriceMin : 0.0, filterPriceMax ? filterPriceMax : 0.0]}
                    onChange={handlePriceSliderChange}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    step={0.01}
                    valueLabelDisplay="auto"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMin)
                        : filterPriceMin
                    }
                    onChange={handleFilterPriceMinChange}
                    onFocus={handleFilterPriceMinFocus}
                    onBlur={handleFilterPriceMinBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange left"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMax)
                        : filterPriceMax
                    }
                    onChange={handleFilterPriceMaxChange}
                    onFocus={handleFilterPriceMaxFocus}
                    onBlur={handleFilterPriceMaxBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange right"
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleFilterNowClick}><SearchOutlined /> Search Now</Button>
          </Modal.Body>
        </Modal> */}
        <div className={"m-filters d-block d-md-none" + classNames({
          "absolute-d-none": items?.length<=0? true:false
        })}>
          <div className={classNames({
            "tooltip-filter": true,
            "p-3": true,
            "active": showFiltersModal
          })}>

            <Close className="icon-close" onClick={e => { handleFiltersModalClose() }} />
            <div className="form-filters mb-3">
              <div className="text-shape mb-3">
                <strong>Shop by Shape: {shapes}</strong>
              </div>
              <div className="section text-shape mb-4">
                <Row>
                  {shapesfilters.map((shapefilter, index) => (

                    <div className={classNames({
                      "btn-shape": true,
                      active: shapes?.includes(shapefilter.value),
                    })}
                      onClick={(e) => handleShapesChange(e, shapefilter.value)}>
                      <img alt="Round" src={shapefilter.img} className="img-fluid" />
                      <div className="label-shape">{shapefilter.label}</div>
                    </div>

                  ))}

                </Row>

                <div className="clearfix"></div>
              </div>
              <div>
                <strong>Shop by Price (MYR)</strong>
                <div className="section">
                  <InputSlider
                    value={[filterPriceMin ? filterPriceMin : 0.0, filterPriceMax ? filterPriceMax : 0.0]}
                    onChange={handlePriceSliderChange}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    step={0.01}
                    valueLabelDisplay="auto"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMin)
                        : filterPriceMin
                    }
                    onChange={handleFilterPriceMinChange}
                    onFocus={handleFilterPriceMinFocus}
                    onBlur={handleFilterPriceMinBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange left"
                  />
                  <Form.Control
                    type={priceInputType}
                    value={
                      priceInputType === "text"
                        ? formatPriceWithComma(filterPriceMax)
                        : filterPriceMax
                    }
                    onChange={handleFilterPriceMaxChange}
                    onFocus={handleFilterPriceMaxFocus}
                    onBlur={handleFilterPriceMaxBlur}
                    min={priceMinMax[0]}
                    max={priceMinMax[1]}
                    className="txtPriceRange right"
                  />
                  <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>
            <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleFilterNowClick}><SearchOutlined /> Search Now</Button>
          </div>
          <div className={classNames({
            "d-none": showFiltersModal
          })}>
            <Button size="lg" className="btn-red text-uppercase btn-filter w-100"
              onClick={handleFiltersModalShow}><TuneOutlined /> Advanced Search</Button>
          </div>

        </div>
      </div>

    </>
  );
}

export default ProductList;
