
import axios from "axios";
import jwt_decode from "jwt-decode";

export default function useAuthorizationToken() {
    const axiosJWT = axios.create();
    let refreshPromise = null;

    const getAccessToken = () => {
        const token = localStorage.getItem("_pointerstoken");
        const userToken = JSON.parse(token);
        return userToken?.jwt;
    };

    const getRefreshToken = () => {
        const token = localStorage.getItem("_pointerstoken");
        const userToken = JSON.parse(token);
        return userToken?.refresh_token;
    };

    const saveLatestToken = userToken => {
        if (userToken) localStorage.setItem("_pointerstoken", JSON.stringify(userToken));
        else localStorage.removeItem("_pointerstoken");
    }

    const isAccessTokenExpired = () => {
        try {
            const token = getAccessToken();
            const decoded = jwt_decode(token);
            // if (!decoded) return true;
            // const expirationTime = decoded.exp * 1000;
            let currentTime = new Date();
            const cmsGap = 60 * 1000; // 1 minutes
            if (decoded?.role === 'CMS' && decoded.exp * 1000 < currentTime.getTime()) {
                window.location.replace('/login');
            } else if ((decoded?.role === 'CMS' && decoded.exp * 1000 < (currentTime.getTime() + cmsGap))
                || (decoded?.role === 'WEB' && decoded.exp * 1000 < currentTime.getTime())) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return true;
        }
    }

    const isRefreshTokenExpired = () => {
        try {
            const token = getRefreshToken();
            const decoded = jwt_decode(token);
            if (!decoded) return true;
            const expirationTime = decoded.exp * 1000;
            const currentTime = Date.now();
            return currentTime > expirationTime;
        } catch (err) {
            return true;
        }
    }

    const refreshingToken = async () => {
        try {
            if (isRefreshTokenExpired()) {

                localStorage.removeItem("_pointerstoken");
                
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? window.location.pathname : "/profile");
                window.location.replace("/login");
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/refresh-token`, {}, {
                    headers: {
                        "Authorization": "Bearer " + getRefreshToken(),
                        "Content-Type": "application/json",
                        "X-Channel-ID": "pointers-web",
                        "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().startPad(7, '0')
                    },
                });

                if (response?.data?.jwt) {
                    const data = response?.data;
                    saveLatestToken(data);
                    return data; // Assuming the new JWT token is provided in the response
                } else {
                    return null;
                }
            }
        } catch (error) {
            return null;
        } finally {
            refreshPromise = null; // Reset the promise after completion (success or failure)
        }
    }

    // Axios request interceptor
    axiosJWT.interceptors.request.use(
        async (config) => {
            const userToken = getAccessToken(); // Get your existing JWT token from wherever it's stored

            if (isAccessTokenExpired()) {
                // Check if a refresh is already in progress
                if (!refreshPromise) {
                    // Start a new refresh process if none is ongoing
                    refreshPromise = refreshingToken();
                }

                // Wait for the ongoing refresh process to complete
                const refreshedToken = await refreshPromise;

                // Set the refreshed token in the Authorization header
                config.headers.Authorization = `Bearer ${refreshedToken?.jwt}`;
            } else {
                // Token is still valid, set it in the Authorization header
                config.headers.Authorization = `Bearer ${userToken}`;
            }
            config.headers["X-Channel-ID"] = "pointers-web";
            config.headers["X-Request-ID"] = "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0');

            return config;
        },
        (error) => {
            // Handle request error
            return Promise.reject(error);
        }
    );

    return {
        axiosJWT,
        getAccessToken,
        saveLatestToken,
        isAccessTokenExpired
    }

}