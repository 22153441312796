import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function OurPromises() {
    const headerinfo = {
        title: "Our Promises | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Our Promises | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Our Promises"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">OUR PROMISES</h1>

                    <h4 className=" text-uppercase mt-4">CUSTOMER CARE</h4>
                    <p>Our primary focus is ensuring your satisfaction! We are eager to be part of your enduring love story and guide you through each step in finding the ideal engagement ring. We've crafted these exceptional services to provide you with an unforgettable experience as you embark on this once-in-a-lifetime purchase!</p>

                    <h4 className=" text-uppercase mt-4">CHONEST DIAMOND RECOMMENDATIONS</h4>
                    <p>If you're uncertain about selecting the perfect diamond or ring setting, rest assured, we are here to assist you at every stage of the process in discovering your ideal diamond! Connect with our consultants from the comfort of your home, making the online diamond shopping experience both enjoyable and informative.</p>
                    <h4 className=" text-uppercase mt-4">TRY ON RINGS AT HOME</h4>
                    <p>Experience the latest technology from Pointers - try on rings anytime, anywhere. Now, you can shop as if you were in a physical store without leaving your home. With just a few simple steps, easily visualize engagement ring and wedding band designs, along with diamond carat sizes, on your hand!</p>

                    <h4 className=" text-uppercase mt-4">GUARANTEED BRILLIANCE WITH DUAL CERTIFICATES</h4>
                    <p>Opt for the assurance of two certificates instead of one to ensure you receive the finest diamond. Pointers offer Dual Certificates (GIA & Sarine) to guarantee the exceptional quality and superior brilliance of your diamonds!</p>

                    <h4 className=" text-uppercase mt-4">10X CLEARER, 10X BETTER</h4>
                    <p>Examine diamonds in 10x detail for complete confidence in your choice and its quality. While a diamond certificate provides only a partial view, Pointers' 360-degree technology allows you to inspect your diamond quality from every angle!</p>

                    <h4 className=" text-uppercase mt-4">CUSTOMIZE YOUR UNIQUE ENGAGEMENT RING</h4>
                    <p>Recognizing the limited choices in retail stores, customers sought unique engagement rings that reflected their individuality. Hence, we offer free jewelry customization with our in-house designers, ensuring each piece is meticulously handcrafted by expert jewelers to maintain the highest quality.</p>

                    <h4 className=" text-uppercase mt-4">3D PRINT TECHNOLOGY</h4>
                    <p>Receive Free 3D Printed Rings when you customize jewelry with us! Our goal is to allow customers to try on and visualize their desired ring before finalizing the design in 18K gold. Pointers is the only brand in Malaysia to offer 3D print services to customers.</p>

                    <h4 className=" text-uppercase mt-4">PROPOSE FIRST, DESIGN LATER!</h4>
                    <p>The dilemma of choosing the right ring is a common concern for many. With our free service, you can propose with a temporary ring. Once successful, your fiancée can return to design her ring, from the setting to the type of gold.</p>
                    <h4 className=" text-uppercase mt-4">SURPRISE PROPOSAL RING BOX</h4>
                    <p>We understand the challenge of hiding the ring box during a proposal. To assist you, Pointers has engineered a discreet proposal box that positions the ring perfectly when opened. Say goodbye to embarrassing bulging pockets and hello to Pointers' slim, easy-to-hide box for a memorable marriage proposal!</p>

                    <h4 className=" text-uppercase mt-4">FIND OUT HER RING SIZE EASILY</h4>
                    <p>Determining her ring size is a common worry, but worry not. We can estimate her ring size with her weight and height. Alternatively, bring us one of her rings, and the first resizing is free of charge. We also provide complimentary 3D ring sizers upon request.</p>

                    <h4 className=" text-uppercase mt-4">PROPOSE NOW, PAY LATER!</h4>
                    <p>Make secure and flexible payments with our easy 0% interest payment plans. Split your purchases into up to 12 months for affordable monthly payments on all diamonds, gemstones, and gold jewelry!</p>
                    <h4 className=" text-uppercase mt-4">QUICK, SAFE & SECURE!</h4>
                    <p>At Pointers, the safety of your ring and parcel is our top priority. We use a fully insured and secure delivery service that requires your signature upon arrival. This service is quick, safe, and free with all orders.</p>
                    <h4 className=" text-uppercase mt-4">FREE JEWELRY AFTERCARE</h4>
                    <p>Pointers offers free complimentary cleaning and inspection services for all jewelry purchased, extending the life of your cherished pieces. This service includes polishing, tightening the prongs of the diamond, cleaning, and plating.</p>
                    <h4 className=" text-uppercase mt-4">STRESS-FREE SHOPPING</h4>
                    <p>Every purchase from Pointers includes a Lifetime Warranty. We guarantee all items to be free from manufacturing defects throughout their life. If you believe your product has a manufacturing defect, feel free to return it to us for further inspection.</p>
























                </div>
            </div>
        </>
    )
}

export default OurPromises