import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import "./privacyPolicy.css";
import MetaInformation from '../../components/metainformation/MetaInformation';

function PrivacyPolicy() {
    const headerinfo = {
        title: "Privacy Policy | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
          title: "Privacy Policy | Pointers Jewellers",
          description: "",
          
            image: window.location.origin+"/pointers/pointers_logo_social.png",
          url: window.location.href
        }
      };
    const breadcrumbs = [{
        url: "",
        label: "Privacy Policy"
    }];
    return (
        <>
        <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Privacy Policy</h1>
                    <p>We at Pointers Jewellers (“Pointers,” “we,” “us,” or “our”) have created this privacy policy (this “Privacy Policy”) because we know that you care about how information you provide to us is used and shared. This Privacy Policy relates to the information collection and use practices of Pointers in connection with our Website. By visiting our Website, Visitors and Registered Users are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use.</p>
                    <p>Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.</p>
                    <h4 className=" text-uppercase mt-4">THE INFORMATION WE COLLECT</h4>
                    <p>In the course of operating the Website, we will collect (and/or receive) the following types of information. You authorize us to collect and/or receive such information.</p>
                    <ol>
                        <li><strong>Personal Information</strong><br />
                            <p>When you sign up to become a Registered User, you will be required to provide us with personal information about yourself, such as your name, e-mail address, and Credentials. If you contact us, you will need to provide your name and e-mail address. Registered Users may also provide identifiable information in the course of using the Website. In addition, Registered Users may elect to use Facebook Connect or similar logins to automatically populate certain information on our registration page. If you use such third-party logins, you are authorizing Pointers to collect, store, and use, in accordance with this&nbsp;Privacy Policy, any and all information that you agreed that Facebook, Inc. (“Facebook”) or such other third parties could provide to us through their application programming interface (“API”). Such information may include, without limitation, your first and last name, Facebook username, Facebook profile picture, unique Facebook identifier and access token, and e-mail address. All information we collect and/or receive under this section is collectively called “Personal Information.” We do not collect any personal information from Visitors or Registered Users when they use the Website, unless they provide such information voluntarily.</p>
                        </li>
                        <li><strong>Billing Information</strong><br />
                            <p>When you wish to purchase a product or service, you will be required to provide certain information to our third-party payment vendors pursuant to the terms and conditions of their privacy policies and terms of use. Such information may include a debit card number, credit card number, expiration date, billing address, activation codes, and similar information. Such information is collectively called the “Billing Information.” You authorize our third-party payment vendors to collect, process, and store your Billing Information. Pointers does not store or process your Billing Information.</p>
                        </li>
                        <li><strong>Transaction Information</strong><br />
                            <p>When Registered Users wish to make a purchase through the Website, they must provide us with certain information about the transaction they are seeking to execute. Such information is collectively called the “Transaction Information.”</p>
                        </li>
                        <li><strong>Other Information</strong><br />
                            <p className="mb-0">In addition to the information noted above, we may collect additional information (collectively, the “Other Information”). Such Other Information may include:</p>
                            <ol type="a">
                                <li><strong>From You.</strong> Additional information about yourself that you voluntarily provide to us (e.g., via a survey), such as household income range, gender, product and service preferences, and other information that does not identify you personally.<p></p></li>
                                <li><strong>From Your Activity.</strong> Information that we automatically collect when you use the Website, including, without limitation:<br />
                                    <p>IP addresses, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or device; browser type and language; referring and exit pages and URLs; date and time; amount of time spent on particular pages; what sections of the Website you visit; and similar data; and</p>
                                    <p>Information about your device, including the type of device; universally unique ID (“UUID”); advertising identifier (“IDFA”); MAC address; operating system and version (e.g., iOS, Android or Windows); carrier and country location; hardware and processor information (e.g., storage, chip speed, camera resolution, NFC enabled); network type (WiFi, 3G, 4G, LTE); and similar data.</p>
                                </li>
                                <li><strong>From Cookies.</strong> Information that we collect using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive so that your computer will “remember” information about your visit. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to help us collect Other Information and to enhance your experience using the Website. If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the Website may not function properly.<p></p></li>
                                <li><strong>Third-Party Analytic.</strong> We use third-party analytics services (such as Google Analytics) to evaluate your use of the Website, compile reports on activity, collect demographic data, analyze performance metrics, and collect and evaluate other information relating to the Website and mobile and Internet usage. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the Website, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy.<br />
                                    <p>For more information on Google Analytics, including how to opt out from certain data collection, please visit <a href="https://www.google.com/analytics" target="_blank" rel="noreferrer">https://www.google.com/analytics</a>. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the Website.</p></li>
                                <li><strong>From  Other Sources.</strong> We also may collect or receive information from third parties, such as Facebook and/or other third-party social media sites.<p></p></li>
                            </ol>

                        </li>
                    </ol>
                    <h4 className=" text-uppercase mt-4">INFORMATION COLLECTED BY OR THROUGH THIRD-PARTY ADVERTISING COMPANIES</h4>
                    <p>We may share Other Information about your activity on the Website with third parties for the purpose of tailoring, analyzing, managing, reporting, and optimizing advertising you see on the Website and elsewhere. These third parties may use cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect such Other Information for such purposes. Pixel tags enable us, and these third-party advertisers, to recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in order to learn which advertisement brings a user to a given site.</p>

                    <h4 className=" text-uppercase mt-4">HOW WE USE AND SHARE THE INFORMATION</h4>
                    <p>We use the Personal Information, the Geolocational Information, the Transaction Information, and the Other Information (collectively, the “Information”) to provide our services; to process orders for products and services; to administer our rewards and promotional programs; to maintain and improve our Website and services to you; to solicit your feedback; and to inform you about our products and services and those of our third-party marketing partners.</p>
                    <p className="mb-0"><strong>We may also use and/or share Information as described below.</strong></p>
                    <ul>
                        <li>Pointers will access, use, and share the Information as required to process your orders and provide support to you.</li>
                        <li>In order to provide our services and administer our rewards and promotional programs, we may share the Information with our third-party promotional and marketing partners, including, without limitation, businesses participating in our various programs.</li>
                        <li>With your permission, third-party applications or services may access your Personal Information. We use standard OAuth (open authorization) to enable you to give permission to share your Personal Information with other websites and services, such as Facebook and Twitter (e.g., when you agree to a pop-up requesting you to allow another application to access your account information). We also use OAuth to allow us to share information about you that is stored by us without sharing your security credentials.</li>
                        <li>We may employ other companies and individuals to perform functions on our behalf. Examples may include providing technical assistance, order fulfillment, customer service, and marketing assistance. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law.</li>
                        <li>In an ongoing effort to better understand our Visitors, Registered Users, and our products and services, we may analyze Information in aggregate form in order to operate, maintain, manage, and improve the Website and/or our products and services. This aggregate information does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our products and services to current and prospective business partners and to other third parties for other lawful purposes.</li>
                        <li>We may share some or all of your Information with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us.</li>
                        <li>As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the Information may be part of the transferred assets.</li>
                        <li>To the extent permitted by law, we may also disclose the Information: (i) when required by law, court order, or other government or law enforcement authority or regulatory agency; or (ii) whenever we believe that disclosing such Information is necessary or advisable, for example, to protect the rights, property, or safety of Pointers or others.</li>
                    </ul>

                    <h4 className=" text-uppercase mt-4">INFORMATION YOU SHARE</h4>
                    <p>Please keep in mind that whenever you voluntarily make your Personal Information available to third parties — for example on message boards or web logs; through email; or in comment or chat areas — that information can be seen, collected, heard, and/or used by others besides us. We cannot be responsible for any unauthorized third-party use of such information.</p>

                    <h4 className=" text-uppercase mt-4">HOW WE PROTECT THE INFORMATION</h4>
                    <p>We take commercially reasonable steps to protect the Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the Information that you supply will not be intercepted while being transmitted to and from us over the Internet. In particular, e-mail sent to or from the Website may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>

                    <h4 className=" text-uppercase mt-4">EXTERNAL WEBSITES</h4>
                    <p>The Platform may contain links to third-party websites. We have no control over the privacy practices or the content of any of our business partners, advertisers, sponsors, or other websites to which we provide links. As such, we are not responsible for the content or the privacy policies of those third-party websites. You should check the applicable third-party privacy policy and terms of use when visiting any other websites.</p>

                    <h4 className=" text-uppercase mt-4">CHANGES TO THIS PRIVACY POLICY</h4>
                    <p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. By accessing the Platform and/or using our services after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.</p>

                    <h4 className=" text-uppercase mt-4">HOW TO CONTACT US</h4>
                    <p>If you have questions about this Privacy Policy, please contact us via e-mail at <a href="mailto:general@pointers.email">general@pointers.email</a> with “Privacy Policy” in the subject line.</p>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy