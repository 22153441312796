export const stateslist = [
    {
        value:"JHR",
        label:"Johor"
    },{
        value:"KDH",
        label:"Kedah"
    },{
        value:"KTN",
        label:"Kelantan"
    },{
        value:"LBN",
        label:"Labuan"
    },{
        value:"MLK",
        label:"Malacca (Melaka)"
    },{
        value:"NSN",
        label:"Negeri Sembilan"
    },{
        value:"PHG",
        label:"Pahang"
    },{
        value:"PNG",
        label:"Penang (Pulau Pinang)"
    },{
        value:"PRK",
        label:"Perak"
    },{
        value:"PLS",
        label:"Perlis"
    },{
        value:"SBH",
        label:"Sabah"
    },{
        value:"SWK",
        label:"Sarawak"
    },{
        value:"SGR",
        label:"Selangor"
    },{
        value:"TRG",
        label:"Terengganu"
    },{
        value:"PJY",
        label:"Putrajaya"
    },{
        value:"KUL",
        label:"Kuala Lumpur"
    }
];

export const paymentoptionlist=[
    {
        label:"FPX",
        value:"DD"
    },{
        label:"Credit Card",
        value:"CC"
    }
]