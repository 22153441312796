import { useEffect, useState, useRef } from "react";
import "./pageheader.css";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import classNames from "classnames";
import {
  Apps,
  Close,
  Menu,
  SearchOutlined,
  KeyboardArrowDown,
  MenuOpen,
  PersonOutline,
  ShoppingBasketOutlined,
} from "@material-ui/icons";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import useDiamondSteps from "../../customuse/useDiamondSteps";
import { shapesfilters } from "../../json/diamondfilters";

function PageHeader({ setStep, hasCart, setHasCart }) {

  const shouldRunEffect = useRef(true);
  const { getAccessToken, saveLatestToken, axiosJWT } = useAuthorizationToken();
  const { checkNeedDiamondStep } = useDiamondSteps();
  const displayLimit = 1;
  const [categories, setCategories] = useState();
  const [keyword, setKeyword] = useState();
  const [mobile_menu_open, setMobileMenuOpen] = useState(0);
  const [activeMainCat, setActiveMainCat] = useState(0);
  const [isOpenCatMenu, setIsOpenCatMenu] = useState(-1);
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(-1);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const handleSearchModalClose = () => setShowSearchModal(false);
  const handleSearchModalShow = () => setShowSearchModal(true);
  // const shouldRunEffect = useRef(true);
  const handleLogout = () => {
    saveLatestToken(null);
    window.location.reload();
  };

  useEffect(() => {
    async function fetchCategoriesData() {
      var isMainCategoryHide = [1, 8];
      var curCategories = [];
      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/lov/PRODUCT_CATEGORY?limit=${displayLimit}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Channel-ID": "pointers-web",
            "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
          },
        }
      )
        .then((response) => response.json())
        .then((catdata) => {
          if (catdata?.lov) {
            catdata?.lov
              .filter((item) => {
                return !isMainCategoryHide.includes(parseInt(item.id));
              })
              .forEach(async (category) => {
                curCategories.push(category);
              });
          }
        });
      setCategories(curCategories);
    }

    async function checkHasCart() {
      const res = await axiosJWT
        .get(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`)
        .catch((err) => {
          // handleOnSubmitError(err);
        });
      if (res?.data?.cart) {
        setHasCart(true);
      }
    }

    if (shouldRunEffect.current) {
      if (getAccessToken()) {
        checkHasCart(true);
      }
      fetchCategoriesData();
      shouldRunEffect.current = false;
    }

  }, [getAccessToken, axiosJWT,setHasCart]);

  const handleSearchKeyup = e => {
    if (e.key === 'Enter') {
      // Enter key is pressed, you can perform your validation or any other action here
      handleSearchOnClick(e);
    }
  }

  const handleSearchOnClick = e => {

    var url = `/products?keyword=${keyword}`;
    window.location.replace(url);
  }

  const handleBlockingClick = (e) => {
    if (isOpenCatMenu === 1) setIsOpenCatMenu(0);
    if (isOpenProfileMenu === 1) setIsOpenProfileMenu(0);
  }


  return (
    <>
      <header>
        <div className="header-top_area d-none d-md-block">
          <div className="container">
            <Row>
              <Col lg="4" md="4" sm="4">
                <div className="header-top-left_area mt-4">
                  <ul className="ul-inline">
                    <li><a href="tel:+60356336493">603.5633.6493</a></li>
                    <li><a href="mailto:general@pointers.email">general@pointers.email</a></li>
                  </ul>
                </div>
              </Col>
              <Col lg="4" md="4" sm="4">
                <div className="header-logo text-center">
                  <a href="/">
                    <img
                      src="../../pointers/pointers_logo.png"
                      alt=""
                      className="img-logo"
                    />
                  </a>
                </div>
              </Col>
              <Col lg="4" md="4" sm="4">
                <div className="header-top-right_area">
                  <ul className="ul-inline">
                    <li>
                      <div
                        title="Search" onClick={e => { handleSearchModalShow() }}
                      >
                        <SearchOutlined />
                      </div>
                    </li>
                    <li>
                      <div
                        title="Account"
                        onClick={(e) =>
                          setIsOpenProfileMenu(isOpenProfileMenu !== 1 ? 1 : 0)
                        }
                      >
                        <PersonOutline />
                      </div>
                    </li>
                    <li>
                      <div
                        className="mobile-menu_btn toolbar-btn color--white d-md-none d-block"
                        onClick={(e) => setMobileMenuOpen(!mobile_menu_open)}
                      >
                        <Menu />
                      </div>
                    </li>
                    <li className="cart-relative">
                      <a href="/cart" title="Shopping Cart">
                        <ShoppingBasketOutlined />
                        <div className={
                          classNames({
                            "cart-reddot":true,
                            "active": hasCart
                        })}></div>
                      </a>
                    </li>

                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="header-bottom_area header-sticky stick">
          <div className="container">
            <Row>
              <Col xs="3" className="d-md-none d-block px-0">
                <div className="header-top-right_area header-top-left_area">
                  <ul className="ul-inline">

                    <li>
                      <div
                        title="Our Categories"
                        onClick={(e) =>
                          setIsOpenCatMenu(isOpenCatMenu !== 1 ? 1 : 0)
                        }
                      >
                        <Apps />
                      </div>
                    </li>
                    <li>
                      <div
                        title="Search" onClick={e => { handleSearchModalShow() }}
                      >
                        <SearchOutlined />
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" className="d-md-none d-block">
                <div className="header-logo text-center">
                  <a href="/">
                    <img
                      src="../../pointers/pointers_logo.png"
                      alt=""
                      className="img-logo"
                    />
                  </a>
                </div>
              </Col>
              <Col xs="3" className="d-md-none d-block px-0">
                <div className="header-top-right_area">
                  <ul className="ul-inline">
                    <li>
                      <div
                        title="Account"
                        onClick={(e) =>
                          setIsOpenProfileMenu(isOpenProfileMenu !== 1 ? 1 : 0)
                        }
                      >
                        <PersonOutline />
                      </div>
                    </li>
                    <li>
                      <a href="/cart" title="Shopping Cart">
                        <ShoppingBasketOutlined />
                      </a>
                    </li>

                  </ul>
                </div>
              </Col>
              <Col lg="12" className="d-none d-md-block position-static text-center">
                <div className="main-menu_area">
                  <nav>
                    <ul>
                      {categories
                        ? categories.map((category, index) =>
                          category?.sub?.length > 0 ? (
                            <li
                              className={classNames({
                                "right-menu": true,
                                "is-engagement-ring-cat": checkNeedDiamondStep(category),
                                active: activeMainCat === category.id ? true : false,
                              })}
                              onClick={(e) =>
                                setActiveMainCat(
                                  activeMainCat !== category.id ? category.id : 0
                                )
                              }
                            >
                              {category.name} <KeyboardArrowDown className="icon" />
                              <ul className="cat-mega-menu">
                                <li className="right-menu cat-mega-title">
                                  <ul>
                                    {
                                      checkNeedDiamondStep(category) ? (
                                        <>
                                          <li className="py-3">
                                            <Row className="w-100 mx-0">
                                              <Col md="6" className="mb-2">
                                                <div>DESIGN YOUR OWN ENGAGEMENT RING</div>
                                                <ul>
                                                  <li>
                                                    <a href={"/products/" +
                                                      category.name
                                                        .toLowerCase()
                                                        .replace(
                                                          /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                          ""
                                                        )
                                                        .trim()
                                                        .replace(/ /g, "-")
                                                        .replace(/[^\w-]+/g, "")}>START WITH A SETTING</a>
                                                  </li>
                                                  <li>
                                                    <a href="/choose-diamond" onClick={e => setStep("diamond")}>START WITH A DIAMOND</a>
                                                  </li>
                                                  <li>
                                                    <a href={"/choose-diamond/lab-grown-diamond"} onClick={e => setStep("diamond")}>START WITH A LAB GROWN DIAMOND</a>
                                                  </li>
                                                </ul>

                                                <div className="mt-3">SHOP BY SHAPE</div>
                                                <Row>
                                                  {shapesfilters.map((shapefilter, index) => (
                                                    <Col xs="6">
                                                      <a href={"/choose-diamond/shape/" + shapefilter.label.toLowerCase()}>
                                                        <div className="col-thumbnail">
                                                          <img alt="Round" src={shapefilter.img} className="img-fluid" />
                                                        </div>
                                                        <div className="col-label">{shapefilter.label}</div>
                                                      </a>
                                                    </Col>


                                                  ))}
                                                </Row>
                                              </Col>
                                              <Col md="6" className="mb-2">

                                                <div>ENGAGEMENT RING STYLES</div>
                                                <ul>
                                                  {
                                                    category.sub.map((subcategory, subindex) => (
                                                      <li>
                                                        <a
                                                          href={
                                                            "/products/" +
                                                            category.name
                                                              .toLowerCase()
                                                              .replace(
                                                                /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                                ""
                                                              )
                                                              .trim()
                                                              .replace(/ /g, "-")
                                                              .replace(/[^\w-]+/g, "") +
                                                            "/" +
                                                            subcategory.name
                                                              .toLowerCase()
                                                              .replace(
                                                                /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                                ""
                                                              )
                                                              .trim()
                                                              .replace(/ /g, "-")
                                                              .replace(/[^\w-]+/g, "")
                                                          }
                                                        >
                                                          {subcategory.name.toUpperCase()}
                                                        </a>
                                                      </li>
                                                    ))
                                                  }
                                                </ul>
                                              </Col>
                                            </Row>
                                          </li>

                                          {/* <li>
                                            <a href={"/products/" +
                                              category.name
                                                .toLowerCase()
                                                .replace(
                                                  /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                  ""
                                                )
                                                .trim()
                                                .replace(/ /g, "-")
                                                .replace(/[^\w-]+/g, "")}>START WITH A SETTING</a>
                                          </li>
                                          <li>
                                            <a href="/choose-diamond">START WITH A DIAMOND</a>
                                          </li>
                                          <li>
                                            <a href={"/choose-diamond/lab-grown-diamond"}>START WITH A LAB GROWN DIAMOND</a>
                                          </li> */}
                                        </>
                                      ) : (
                                        category.sub.map((subcategory, subindex) => (
                                          <li>
                                            <a
                                              href={
                                                "/products/" +
                                                category.name
                                                  .toLowerCase()
                                                  .replace(
                                                    /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                    ""
                                                  )
                                                  .trim()
                                                  .replace(/ /g, "-")
                                                  .replace(/[^\w-]+/g, "") +
                                                "/" +
                                                subcategory.name
                                                  .toLowerCase()
                                                  .replace(
                                                    /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                    ""
                                                  )
                                                  .trim()
                                                  .replace(/ /g, "-")
                                                  .replace(/[^\w-]+/g, "")
                                              }
                                            >
                                              {subcategory.name.toUpperCase()}
                                            </a>
                                          </li>
                                        ))
                                      )
                                    }

                                  </ul>
                                </li>
                              </ul>
                            </li>
                          ) : (
                            <li>
                              <a
                                href={
                                  "/products/" +
                                  category.name
                                    .toLowerCase()
                                    .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
                                    .trim()
                                    .replace(/ /g, "-")
                                    .replace(/[^\w-]+/g, "")
                                }
                                className="main-category"
                                onClick={(e) => setActiveMainCat(category.id)}
                              >
                                {category.name.toUpperCase()}
                              </a>
                            </li>
                          )
                        )
                        : ""}
                    </ul>
                  </nav>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={classNames({
            "mobile-menu_wrapper": true,
            open: mobile_menu_open ? true : false,
          })}
        >
          <div className="offcanvas-menu-inner">
            <div className="container">
              <div
                className="btn-close"
                onClick={(e) => setMobileMenuOpen(!mobile_menu_open)}
              >
                <Close />
              </div>
              <div className="offcanvas-inner_search">
                <div className="header-searchbox">
                  <Form.Control type="text" placeholder="Search your ring" />
                  <Button className="btn-header-search" type="button">
                    <SearchOutlined className="icon-header-search" />
                  </Button>
                </div>
              </div>
              <div className="offcanvas-navigation">
                <ul className="mobile-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className={classNames({
          blocking: true,
          active: isOpenCatMenu === 1 || isOpenProfileMenu === 1 ? true : false,
        })}
        onClick={handleBlockingClick}
      ></div>

      <div
        className={classNames({
          "profile-menu": true,
          active: isOpenProfileMenu === 1 ? true : false,
          inactive: isOpenProfileMenu === 0 ? true : false,
        })}
      >
        <div
          className="profile-heading"
          onClick={(e) => setIsOpenProfileMenu(0)}
        >
          <h2 className="profile-toggle">
            <MenuOpen className="icon" />
            <span>Profile Menu</span>
          </h2>
        </div>
        <div className="profile-menu-list">
          <ul>
            {getAccessToken() ? (
              <>
                <li>
                  <a href="/profile" className="main-profile">
                    My Profile
                  </a>
                </li>
                <li>
                  <a href="/addresses" className="main-profile">
                    My Address Book
                  </a>
                </li>
                <li>
                  <a href="/cart" className="main-profile">
                    My Shopping Cart
                  </a>
                </li>
                <li>
                  <a href="/orders" className="main-profile">
                    Order History
                  </a>
                </li>
                <li onClick={handleLogout}>
                  <div className="main-profile">Logout</div>
                </li>
              </>
            ) : (
              <li>
                <a href="/login" className="main-profile">
                  Login
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div
        className={classNames({
          "category-menu": true,
          active: isOpenCatMenu === 1 ? true : false,
          inactive: isOpenCatMenu === 0 ? true : false,
        })}
      >
        <div className="category-heading" onClick={(e) => setIsOpenCatMenu(0)}>
          <h2 className="category-toggle">
            <MenuOpen className="icon" />
            <span>SHOP BY CATEGORIES</span>
          </h2>
        </div>
        <div className="category-menu-list">
          <ul>
            {categories
              ? categories.map((category, index) =>
                category?.sub?.length > 0 ? (
                  <li
                    className={classNames({
                      "right-menu": true,
                      "is-engagement-ring-cat": checkNeedDiamondStep(category),
                      active: activeMainCat === category.id ? true : false,
                    })}
                  >
                    <div
                      className="main-category"
                      onClick={(e) =>
                        setActiveMainCat(
                          activeMainCat !== category.id ? category.id : 0
                        )
                      }
                    >
                      {category.name} <KeyboardArrowDown className="icon" />
                    </div>
                    <ul className="cat-mega-menu">
                      <li className="right-menu cat-mega-title">
                        <ul>
                          {
                            checkNeedDiamondStep(category) ? (
                              <>
                                <li>
                                  <Row>
                                    <Col xs="12" className="mb-2">
                                      <div>DESIGN YOUR OWN ENGAGEMENT RING</div>
                                      <ul>
                                        <li>
                                          <a href={"/products/" +
                                            category.name
                                              .toLowerCase()
                                              .replace(
                                                /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                ""
                                              )
                                              .trim()
                                              .replace(/ /g, "-")
                                              .replace(/[^\w-]+/g, "")}>START WITH A SETTING</a>
                                        </li>
                                        <li>
                                          <a href="/choose-diamond" onClick={e => setStep("diamond")}>START WITH A DIAMOND</a>
                                        </li>
                                        <li>
                                          <a href={"/choose-diamond/lab-grown-diamond"} onClick={e => setStep("diamond")}>START WITH A LAB GROWN DIAMOND</a>
                                        </li>
                                      </ul>
                                      <div className="mt-3">SHOP BY SHAPE</div>
                                      <Row>
                                        {shapesfilters.map((shapefilter, index) => (
                                          <Col xs="6">
                                            <a href={"/choose-diamond/shape/" + shapefilter.label.toLowerCase()}>
                                              <div className="col-thumbnail">
                                                <img alt="Round" src={shapefilter.img} className="img-fluid" />
                                              </div>
                                              <div className="col-label">{shapefilter.label}</div>
                                            </a>
                                          </Col>


                                        ))}
                                      </Row>
                                    </Col>
                                    <Col xs="12" className="mb-2">

                                      <div>ENGAGEMENT RING STYLES</div>
                                      <ul>
                                        {category.sub.map((subcategory, subindex) => (
                                          <li>
                                            <a
                                              href={
                                                "/products/" +
                                                category.name
                                                  .toLowerCase()
                                                  .replace(
                                                    /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                    ""
                                                  )
                                                  .trim()
                                                  .replace(/ /g, "-")
                                                  .replace(/[^\w-]+/g, "") +
                                                "/" +
                                                subcategory.name
                                                  .toLowerCase()
                                                  .replace(
                                                    /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                                    ""
                                                  )
                                                  .trim()
                                                  .replace(/ /g, "-")
                                                  .replace(/[^\w-]+/g, "")
                                              }
                                            >
                                              {subcategory.name}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </Col>
                                  </Row>
                                </li>

                                {/* <li>
                                  <a href={"/products/" +
                                    category.name
                                      .toLowerCase()
                                      .replace(
                                        /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                        ""
                                      )
                                      .trim()
                                      .replace(/ /g, "-")
                                      .replace(/[^\w-]+/g, "")}>START WITH A SETTING</a>
                                </li>
                                <li>
                                  <a href="/choose-diamond">START WITH A DIAMOND</a>
                                </li>
                                <li>
                                  <a href={"/choose-diamond/lab-grown-diamond"}>START WITH A LAB GROWN DIAMOND</a>
                                </li> */}
                              </>
                            ) : (
                              category.sub.map((subcategory, subindex) => (
                                <li>
                                  <a
                                    href={
                                      "/products/" +
                                      category.name
                                        .toLowerCase()
                                        .replace(
                                          /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                          ""
                                        )
                                        .trim()
                                        .replace(/ /g, "-")
                                        .replace(/[^\w-]+/g, "") +
                                      "/" +
                                      subcategory.name
                                        .toLowerCase()
                                        .replace(
                                          /[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g,
                                          ""
                                        )
                                        .trim()
                                        .replace(/ /g, "-")
                                        .replace(/[^\w-]+/g, "")
                                    }
                                  >
                                    {subcategory.name}
                                  </a>
                                </li>
                              ))
                            )
                          }
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li>
                    <a
                      href={
                        "/products/" +
                        category.name
                          .toLowerCase()
                          .replace(/[`~!@#$%^&*()_\-+={};:'"|,.<>?\s]/g, "")
                          .trim()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, "")
                      }
                      className="main-category"
                      onClick={(e) => setActiveMainCat(category.id)}
                    >
                      {category.name}
                    </a>
                  </li>
                )
              )
              : ""}

            <li>
              <a
                href={"/products"}
                className="main-category"
                onClick={(e) => setActiveMainCat(null)}
              >
                View All Products
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showSearchModal} onHide={handleSearchModalClose} size="lg" className="modal-header-search">
        <Modal.Body>
          <div className="header-searchbox">
            <Form.Control type="text" value={keyword} onChange={e => setKeyword(e.target.value)} onKeyUp={handleSearchKeyup} placeholder="Search your ring" />
            <Button className="btn-header-search" type="button" onClick={handleSearchOnClick}>
              <SearchOutlined className="icon-header-search" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PageHeader;
