import React, { useState } from 'react';
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';
import { companyinfo } from '../../json/companyinfo';
import "./contactUs.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { Facebook, Instagram, PhoneOutlined, PrintOutlined, EmailOutlined } from '@material-ui/icons';
import MetaInformation from '../../components/metainformation/MetaInformation';

function ContactUs() {
  const headerinfo = {
    title: "Contact Us | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
      title: "Contact Us | Pointers Jewellers",
      description: "",
      
            image: window.location.origin+"/pointers/pointers_logo_social.png",
      url: window.location.href
    }
  };
  const [contactAlert, setContactAlert] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const enableBtn = 1;
  // const [enableBtn, setEnableBtn] = useState(1);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: ""
  });

  const handleBtnSubmitOnClick = async (e) => {
    const curErrors = { ...errors };
    var haserror = false;
    if (name === "") {
      curErrors.name = "Please enter your name.";
      haserror = true;
    } else {
      curErrors.name = "";
    }

    if (email === "") {
      curErrors.email = "Please enter your email.";
      haserror = true;
    } else {
      curErrors.email = "";
    }
    if (phone === "") {
      curErrors.phone = "Please enter your phone.";
      haserror = true;
    } else {
      curErrors.phone = "";
    }
    if (subject === "") {
      curErrors.subject = "Please enter Subject.";
      haserror = true;
    } else {
      curErrors.subject = "";
    }

    setErrors(curErrors);
    if (enableBtn && !haserror) {
      var formdata = {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message
      };
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/contact/create`, {
        headers: { 
          "Content-Type": "application/json",
          "X-Channel-ID":"pointers-web",
          "X-Request-ID":"POINTERS_WEB"+Date.now()+(Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7,'0')
         },
        method: "POST",
        body: JSON.stringify(formdata)
      }).then(response => response.json()).then(res => {
        if (res) setContactAlert(res);
      });
    }
  };
  return (
    <><MetaInformation headerinfo={headerinfo} /><div className="container-contactus">
      <iframe title="Contact Us" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0786253110177!2d101.58528171530907!3d3.0736722544678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4cf5fdd989c5%3A0x5c45121e111ae437!2sPointers%20Jewellers!5e0!3m2!1sen!2smy!4v1670764263380!5m2!1sen!2smy" width="600" height="450" className="map" loading="lazy"></iframe>
      <div className="container py-5">
        <h3 className=" text-uppercase text-center">General Customer Inquiries</h3>
        <p className=" text-uppercase text-center">FOR ALL INQUIRIES & FEEDBACK, PLEASE FILL OUT THIS FORM</p>
        <Row>
          <Col xs="12" sm="6" className="px-4 mb-4">
            <Form.Control type="text" placeholder="Your Name*" value={name} onChange={e => setName(e.target.value)} />
            {errors.name !== "" ? <div className="">{errors.name}</div> : ""}
            <Form.Control type="email" placeholder="Your Email*" className="mt-3" value={email} onChange={e => setEmail(e.target.value)} />
            {errors.email !== "" ? <div className="">{errors.email}</div> : ""}
            <Form.Control type="phone" placeholder="Your Phone*" className="mt-3" value={phone} onChange={e => setPhone(e.target.value)} />
            {errors.phone !== "" ? <div className="">{errors.phone}</div> : ""}
            <Form.Control type="text" placeholder="Subject*" className="mt-3" value={subject} onChange={e => setSubject(e.target.value)} />
            {errors.subject !== "" ? <div className="">{errors.subject}</div> : ""}
            <Form.Control
              as="textarea"
              rows={5} placeholder="Your Message" className="mt-3 mb-3" value={message} onChange={e => setMessage(e.target.value)} />
            {contactAlert?.code === 200 ? (

              <Form.Group className="my-3">
                <Alert variant="success" className="mb-0" onClose={e => setContactAlert(null)} dismissible>
                  {contactAlert.message}
                </Alert>
              </Form.Group>
            ) : ''}
            {contactAlert?.message && contactAlert?.code !== 200 ? (

              <Form.Group className="my-3">
                <Alert variant="danger" className="mb-0" onClose={e => setContactAlert(null)} dismissible>
                  {contactAlert.message}
                </Alert>
              </Form.Group>
            ) : ''}
            <div className="text-right">
              <Button variant="dark" size="lg" className="btn-submit" disabled={enableBtn ? false : true} onClick={e => handleBtnSubmitOnClick(e)}>Submit</Button>
            </div>
          </Col>
          <Col xs="12" sm="6" className="px-4 mb-4">
            <p className=" text-uppercase mb-0"><strong>COME VISIT US AND TELL US WHAT YOUR REQUEST</strong></p>
            <p className="mb-0"><strong>{companyinfo.name}</strong></p>
            <p dangerouslySetInnerHTML={{ __html: companyinfo.address.join('<br />') }}></p>
            <p dangerouslySetInnerHTML={{ __html: companyinfo.operating_hours.join('<br />') }}></p>
            <p className=" text-uppercase"><strong>GIVE US A CALL TO MAKE AN APPOINTMENT</strong></p>

            <p>
              <div><PhoneOutlined className="contact-icon" /> {companyinfo.tel}</div>
              <div><PrintOutlined className="contact-icon" /> {companyinfo.fax}</div>
              <div><EmailOutlined className="contact-icon" /> <a href={"mailto:" + companyinfo.email}>{companyinfo.email}</a></div>
            </p>
            <p className=" text-uppercase"><strong>CONNECT WITH US</strong></p>
            <div className="contact-socials">
              <ul>
                <li><a href={companyinfo.fb}><Facebook className="contact-icon facebook" /></a></li>
                <li><a href={companyinfo.ig}><Instagram className="contact-icon instagram" /></a></li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div></>
  )
}

export default ContactUs