import React, { useEffect, useRef, useState } from 'react';
import HeaderBreadcrumb from '../../../components/headerbreadcrumb/HeaderBreadcrumb';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import "./addresslist.css";
import CreateAddressButton from '../../../components/createaddressbutton/CreateAddressButton';
import useAuthorizationToken from '../../../auth/useAuthorizationToken';
import MetaInformation from '../../../components/metainformation/MetaInformation';

function AddressesList() {
    const headerinfo = {
        title: "My Address Book | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "My Address Book | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "My Address Book"
    }];
    const { axiosJWT } = useAuthorizationToken();
    const shouldRunEffect = useRef(true);
    const [addresses, setAddresses] = useState([]);
    const [addressAlert, setAddressAlert] = useState();

    const handleOnSubmitError = (err) => {
        if (err.response) {
            setAddressAlert(err.response.data);
        }
    }

    const fetchData = () => {
        async function fetchAddressesData() {
            try {
                const res = await axiosJWT.get(`${process.env.REACT_APP_API_ENDPOINT}/user/address`).catch((err) => {
                    // window.location.replace("/login");
                    handleOnSubmitError(err);
                });
                setAddresses(res?.data?.addresses);
            } catch (err) {
                
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname!=="/login"?window.location.pathname:"/profile");
                window.location.replace("/login");
            }
        }
        fetchAddressesData();
    };

    const handleDeleteOnClick = async (id) => {
        if (window.confirm("Are you sure you want to delete this address?")) {
            const res = await axiosJWT.delete(`${process.env.REACT_APP_API_ENDPOINT}/user/address/${id}`).catch((err) => {
                // window.location.replace("/login");
                handleOnSubmitError(err);
            });
            setAddressAlert(res?.data);
            fetchData();
        }
    };



    useEffect(() => {
        if (shouldRunEffect.current) {
            fetchData();
            shouldRunEffect.current = false;
        }
    });

    return (
        <>
        <MetaInformation headerinfo={headerinfo} />
        <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="addresseslist-content">
                <div className="container pb-5">
                    <h1 className=" text-uppercase">My Address Book</h1>
                    {
                        addressAlert && addressAlert?.code !== 200 ? (
                            <Alert variant="danger" className="my-3" onClose={e => setAddressAlert(null)} dismissible>
                                {addressAlert?.message}
                            </Alert>
                        ) : ""
                    }
                    {
                        addressAlert && addressAlert?.code === 200 ? (
                            <Alert variant="success" className="my-3" onClose={e => setAddressAlert(null)} dismissible>
                                {addressAlert?.message}
                            </Alert>
                        ) : ""
                    }
                    <CreateAddressButton axiosJWT={axiosJWT} fetchData={fetchData} setAddressAlert={setAddressAlert} />


                    <Row>
                        {
                            addresses?.map((address, i) => (
                                <Col sm="6" md="4" className="mb-3">
                                    <Card>
                                        <Card.Body>
                                            <div className="address-content">
                                                <strong>{address.name}</strong>
                                                <div>{address.phone}</div>
                                                <div>{address.address} </div>
                                                <div>{address.postal} {address.city}</div>
                                                <div>{address.state}</div>
                                            </div>
                                            <div className="text-right">
                                                <a href={"/addresses/edit/" + address.id}>
                                                    <Button variant="success" className="btn-white">
                                                        <EditOutlined />
                                                    </Button>
                                                </a>
                                                <Button variant="success" className="btn-white" onClick={e => handleDeleteOnClick(address.id)}>
                                                    <DeleteOutline />
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </>
    )
}

export default AddressesList