import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function PriceMatchGuarantee() {
    const headerinfo = {
        title: "Price Match Guarantee | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Price Match Guarantee | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Price Match Guarantee"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Price Match Guarantee</h1>
                    <p>We are confident that our diamond prices at Pointers are highly competitive. Should you come across a diamond with a comparable price elsewhere, inform us, and not only will we honor that price, but we'll also offer you a lower price.</p>
                    
                    <h4 className=" text-uppercase mt-4">CONTACT POINTERS</h4>
                    <p>Feel free to reach out to us by calling <a href="tel:+60356336493" className="text-underline">03-56336493</a> with details about your diamond quality and pricing requirements. Our diamond experts at Pointers will assist you further. If the competing diamond is priced lower, we'll locate a similar or identical diamond at a reduced cost.</p>

                    <h4 className=" text-uppercase mt-4">COMPARE DIAMOND PRICES</h4>
                    <p>For accuracy, the Pointers Price Match Guarantee is applicable exclusively to diamonds graded by the Gemological Institute of America (GIA). Diamonds will undergo a comparison based on the 4Cs (Cut, Colour, Clarity, and Carat) along with additional factors like fluorescence, polish and symmetry, table, depth percentage, and more.</p>

                    <h4 className=" text-uppercase mt-4">STRESS-FREE BUYING EXPERIENCE</h4>
                    <p>Given that our jewelry consultants operate without commission incentives, there is absolutely no pressure to make a purchase. Our goal is to offer you the most competitive price while ensuring the best experience with Pointers. Our consultants are dedicated to helping you make the right choice, even if that means acquiring your diamond elsewhere.</p>


                </div>
            </div>
        </>
    )
}

export default PriceMatchGuarantee