import React, { useEffect, useRef, useState } from "react";
import RingDiamondStep from "../../components/ringdiamondsteps/RingDiamondStep";
import HeaderBreadcrumb from "../../components/headerbreadcrumb/HeaderBreadcrumb";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import "./wizardCompleted.css";
import { ExpandMoreOutlined, ShoppingCartOutlined, ThreeSixtyOutlined } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import Product360 from "../../components/product360/Product360";
import MetaInformation from "../../components/metainformation/MetaInformation";

import Slider from 'react-slick';

function WizardCompleted({ setHasCart }) {
  let sliderRef = useRef();

  const { axiosJWT, getAccessToken } = useAuthorizationToken();
  const selectedProduct = JSON.parse(
    sessionStorage.getItem("_pointerssetting")
  );
  const selectedDiamond = JSON.parse(
    sessionStorage.getItem("_pointersdiamond")
  );
  const [productinfo, setProductInfo] = useState(null);
  const [selectedPanelSetting, setSelectedPanelSetting] = useState(null);
  const shouldRunEffect = useRef(true);
  const [productAlert, setProductAlert] = useState("");
  const [activeMJFYAccordion, setActiveMJFYAccordion] = useState();
  
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [productSliderIndex, setProductSliderIndex] = useState(0);

  const productslidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    customPaging: function (i) {
      return (
        <div className="slick-bullet"></div>
      );
    },
  };

  const handleProductSliderAfterChange = (index) => {
    setProductSliderIndex(index);
  }

  const handleMouseDown = (event) => {
    const touch = event?.changedTouches ? event?.changedTouches[0] : event;
    setMousePosition({ x: touch.clientX, y: touch.clientY });
  };

  const handleMouseUp = (event) => {
    var show = productSliderIndex;
    const touch = event?.changedTouches ? event?.changedTouches[0] : event;
    const deltaX = touch.clientX - mousePosition.x;
    if (deltaX >= 0) {
      if (show < 0) {
        show = sliderRef.current.props.children.length - 1;
      } else {
        show = show - 1;
      }
    } else {
      if (show === sliderRef.current.props.children.length) {
        show = 0;

      } else {
        show = show + 1;
      }
    }
    sliderRef.current.slickGoTo(show);
    setProductSliderIndex(show);

  };

  const goToLastSlide = () => {
    if (sliderRef.current) {
      const slideCount = sliderRef.current.props.children.length - 1;
      sliderRef.current.slickGoTo(slideCount);
    }
  };

  const handleOnSubmitError = (err) => {
    if (err.response) {
      setProductAlert(err.response.data);
    }

    if (!err?.response) {


      sessionStorage.removeItem("_pointerredirect");
      sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? window.location.pathname : "/profile");
      window.location.replace("/login");
    }
  };

  const handleAddToCartClick = async () => {
    var formdata = {
      product_id: productinfo?.id,
      product_panel_setting_id: selectedProduct?.product_panel_setting_id,
      quantity: selectedProduct?.quantity,
      diamond: selectedDiamond,
      ring_size: selectedProduct?.ring_size,
      engrave: selectedProduct?.engrave,
    };

    const res = await axiosJWT
      .post(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`, formdata)
      .catch((err) => {
        handleOnSubmitError(err);
      });
    if (res?.data) {
      setHasCart(true);
      setProductAlert(res?.data);
      sessionStorage.removeItem("_pointerssetting");
      sessionStorage.removeItem("_pointersdiamond");
      sessionStorage.removeItem("_pointersdiamondfilters");
    }

  };
  useEffect(() => {
    async function fetchProductDetail() {
      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/product/${selectedProduct?.slug}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Channel-ID": "pointers-web",
            "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
          },
        }
      )
        .then((response) => response.json())
        .then((productdata) => {
          setProductInfo(productdata?.product);

          var selectedcolor = selectedProduct?.metal?.color_details?.filter(
            (color) => {
              return selectedProduct?.product_panel_setting_id === color?.product_panel_setting_id;
            }
          );

          // setColor(selectedcolor ? selectedcolor : {});
          setSelectedPanelSetting(selectedcolor[0] ? selectedcolor[0] : []);
        })
        .catch((err) => {
          console.log(err);
          // navigate("/");
        });
    }

    if (shouldRunEffect.current) {
      if (!getAccessToken()) {

        sessionStorage.removeItem("_pointerredirect");
        sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? window.location.pathname : "/profile");
      }
      if (!sessionStorage.getItem("_pointerssetting") ||
        !sessionStorage.getItem("_pointersdiamond")) {
        window.location.replace("/");
      }
      fetchProductDetail();
      shouldRunEffect.current = false;
    }
  });

  return (
    <>
      <MetaInformation headerinfo={{
        title: "Comfirmation | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
          title: "Comfirmation | Pointers Jewellers",
          description: "",

          image: window.location.origin + "/pointers/pointers_logo_social.png",
          url: window.location.href
        }
      }} />
      <HeaderBreadcrumb breadcrumbs={[{
        url: "",
        label: "Comfirmation"
      }]} />
      <RingDiamondStep />
      <div className="wizard-confirmation-content">
        <div className="container">
          <Row>
            <Col lg="8" md="8">

              <Row className="d-block d-md-none d-lg-none">
                <Col xs="12">
                  <Slider ref={sliderRef} {...productslidersettings} afterChange={handleProductSliderAfterChange}>
                    {
                      productinfo?.images?.normal.filter((item) => {
                        return (item.image_name.includes(productinfo?.skuId) &&
                        item.image_name.includes(
                          (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                            ||
                            selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                            'white' :
                            selectedPanelSetting?.color?.toLowerCase()
                        ) &&
                        item.image_name.includes(
                          selectedDiamond?.SHAPE?.toLowerCase()
                        ) &&
                        item.image_name.includes(
                          selectedProduct?.carat?.toLowerCase()
                        ));

                        // return (item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_top_"+carat.name.toLowerCase())
                        // || item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_side_"+carat.name.toLowerCase())
                        // );
                      }).map((image, idx) => (
                        <div className="img-card" onTouchStart={handleMouseDown}
                          onTouchEnd={handleMouseUp}>
                          <img alt={image.image_name} src={image.url} />
                        </div>
                      ))
                    }
                    {
                      (productinfo?.skuId &&
                        selectedPanelSetting?.color &&
                        selectedDiamond?.SHAPE) ? (

                        <div className="img-card">
                          <Product360
                            images={productinfo?.images?.rotate}
                            skuid={productinfo?.skuId}
                            selectedPanelSetting={selectedPanelSetting}
                            shape={selectedDiamond?.SHAPE}
                          />
                          <div className="label-360 text-center">360&deg; Interactive Video – Drag to Rotate</div>
                        </div>

                      ) : ""
                    }

                  </Slider>

                </Col>
                <Col xs="12" className="mb-2 text-center">
                  <Button size="lg" className="btn-red text-uppercase btn-360 py-1" onClick={goToLastSlide}><ThreeSixtyOutlined /> 360&deg;</Button>
                </Col>


              </Row>
              <div className="d-none d-md-block text-center">
                <Row>
                  {productinfo?.images?.normal
                    .filter((item) => {
                      return (
                        item.image_name.includes(productinfo?.skuId) &&
                        item.image_name.includes(
                          (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                            ||
                            selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                            'white' :
                            selectedPanelSetting?.color?.toLowerCase()
                        ) &&
                        item.image_name.includes(
                          selectedDiamond?.SHAPE?.toLowerCase()
                        ) &&
                        item.image_name.includes(
                          selectedProduct?.carat?.toLowerCase()
                        )
                      );
                    })
                    .map((image, idx) => (
                      <Col lg="6" md="6">
                        <div className="img-card">
                          <img alt={image.image_name} src={image.url} />
                        </div>
                      </Col>
                    ))}

                  {productinfo?.skuId &&
                    selectedPanelSetting?.color &&
                    selectedDiamond?.SHAPE ? (
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 6, offset: 3 }}>
                      <div className="img-card">
                        <Product360
                          images={productinfo?.images?.rotate}
                          skuid={productinfo?.skuId}
                          selectedPanelSetting={selectedPanelSetting}
                          shape={selectedDiamond?.SHAPE}
                        />

                        <div className="label-360">
                          360&deg; Interactive Video – Drag to Rotate
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            </Col>
            <Col lg="4" md="4">
              <div className="product-info">
                <h1 className="heading">Your One-of-a-Kind Ring</h1>
                <hr />
              </div>
              <div className="selected-items">
                <div className="wrapper">
                  <div className="col-icon">
                    <img
                      alt={selectedProduct?.name}
                      src={selectedProduct?.images}
                      className="img-info"
                    />
                  </div>
                  <div className="col-item">
                    <strong>{selectedProduct?.name}</strong>

                    <div>
                      RM{" "}
                      {selectedProduct?.price
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="col-icon">
                    <img
                      alt={selectedDiamond?.STOCK_ID}
                      src={selectedDiamond?.IMAGE}
                    />
                  </div>
                  <div className="col-item">
                    <strong>
                      {selectedDiamond?.CARAT} Carat {selectedDiamond?.SHAPE}{" "}
                      Diamond
                    </strong>

                    <div>
                      RM{" "}
                      {parseFloat(selectedDiamond?.MYR_PRICE)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                </div>
              </div>
              <Row>
                <Col xs="12">
                  {
                    getAccessToken() ?
                      (<Button size="lg" className="btn-red text-uppercase w-100" onClick={handleAddToCartClick}><ShoppingCartOutlined /> Add to Cart</Button>) : (
                        <a href="/login">
                          <Button size="lg" className="btn-red text-uppercase w-100"><ShoppingCartOutlined /> Checkout</Button>
                        </a>
                      )
                  }
                </Col>
                {productAlert?.code === 200 ? (
                  <Col xs="12">
                    <Form.Group className="my-3">
                      <Alert
                        variant="success"
                        className="mb-0"
                        onClose={(e) => setProductAlert(null)}
                        dismissible
                      >
                        {productAlert.message}
                      </Alert>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}
                {productAlert?.message && productAlert?.code !== 200 ? (
                  <Col xs="12">
                    <Form.Group className="my-3">
                      <Alert
                        variant="danger"
                        className="mb-0"
                        onClose={(e) => setProductAlert(null)}
                        dismissible
                      >
                        {productAlert.message}
                      </Alert>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}
                <hr className="mt-4" />
                <Col xs="12" className="py-4">
                  <div className="col-icon">
                    <img
                      src="/pointers/icons/icon-speedy-diamond-gray.svg"
                      alt="Delivery Diamond"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-delivery mb-4">
                    Free Shipping, Free 30 Day Returns
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-icon">
                    <img
                      src="/pointers/icons/calendar-gray.svg"
                      alt="Calendar"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-delivery mb-4">
                    Order now and your order shipment date is depending on
                    center diamond.
                  </div>
                  <div className="clearfix"></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="container mb-5">
          <Card className="card-made-for-u">
            <Card.Body>
              <Row>
                <Col xs="12" md="6">
                  <h2>Made Just for You</h2>
                  <p>
                    At our San Francisco design studio, our team designs every
                    ring to delight you, from the first time you see it and
                    every day after. We carefully consider the entire
                    piece—obsessing over comfort, quality, and durability so you
                    can cherish it for a lifetime.
                  </p>
                </Col>
                <Col xs="12" md="6">
                  <Accordion
                    expanded={activeMJFYAccordion === "bcf"}
                    onChange={() =>
                      setActiveMJFYAccordion(
                        activeMJFYAccordion === "bcf" ? "" : "bcf"
                      )
                    }
                    className="accordion-made-for-u"
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <div className="col-img">
                        <img
                          src="/pointers/icons/icon-leaf-white.svg"
                          className="img-fluid"
                          alt="Beyond Conflict Free"
                        />
                      </div>
                      <div className="col-desc">
                        <h3 class="mb-0 mt-0">
                          Beyond Conflict Free<sup>TM</sup>
                        </h3>
                        <small>Setting a higher ethical standard</small>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        At Brilliant Earth we’ve set a new standard in diamond
                        sourcing: Beyond Conflict Free™. We only accept diamonds
                        from specific mine operations and countries that follow
                        internationally recognized labor, trade, and
                        environmental standards. While the Kimberley Process has
                        made advancements in reducing conflict diamonds, it
                        remains flawed and still leaves diamonds tainted by
                        human rights abuses and environmental degradation in the
                        supply chain.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={activeMJFYAccordion === "rpm"}
                    onChange={() =>
                      setActiveMJFYAccordion(
                        activeMJFYAccordion === "rpm" ? "" : "rpm"
                      )
                    }
                    className="accordion-made-for-u"
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <div className="col-img">
                        <img
                          src="/pointers/icons/icon-recycled-white.svg"
                          className="img-fluid"
                          alt="Recycled Precious Metals"
                        />
                      </div>
                      <div className="col-desc">
                        <h3 class="mb-0 mt-0">Recycled Precious Metals</h3>
                        <small>High quality and responsibly sourced</small>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        Our metals are created from existing gold jewelry and
                        excess production metal, sourced from refiners that have
                        been audited by the Responsible Mining Initiative and
                        London Bullion Market Association.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={activeMJFYAccordion === "gb"}
                    onChange={() =>
                      setActiveMJFYAccordion(
                        activeMJFYAccordion === "gb" ? "" : "gb"
                      )
                    }
                    className="accordion-made-for-u"
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <div className="col-img">
                        <img
                          src="/pointers/icons/icon-profits-donated-white.svg"
                          className="img-fluid"
                          alt="Giving Back"
                        />
                      </div>
                      <div className="col-desc">
                        <h3 class="mb-0 mt-0">Giving Back</h3>
                        <small>Support for causes that matter</small>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        Brilliant Earth gives back to help build a brighter
                        future in mining communities and in the communities we
                        operate. We also donate to programs that are dedicated
                        to improving human rights and environmental practices in
                        our industry, including Feeding America, the Rainforest
                        Alliance, and the NAACP Legal Defense and Educational
                        Fund.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

export default WizardCompleted;
