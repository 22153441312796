import React from 'react';

import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import MetaInformation from '../../components/metainformation/MetaInformation';

function ShippingPolicy() {
    const headerinfo = {
        title: "Shipping Policy | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Shipping Policy | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Shipping Policy"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Shipping Policy</h1>
                    <p>Your order will be shipped to you fully insured. We urge all customers to inspect your package for damage or tampering before receiving or signing for receipt. Your order will be shipped to you fully insured. We urge all customers to inspect your package for damage or tampering before receiving or signing for receipt.</p>
                    <h4 className=" text-uppercase mt-4">Free Shipping</h4>
                    <p>Pointers Jewellers orders are shipped with the reliability and accountability of FedEx, DHL, USPS or any courier company we deem worthy for shipping.</p>
                    <h4 className=" text-uppercase mt-4">Gift Packaging</h4>
                    <p>For a special touch, we’ll include a free gift card and enclose your item in our distinctive Pointers Jewellers packaging. From our classic ring presentation boxes to our soft jewellery pouches, every item arrives presentation-ready.</p>
                    <h4 className=" text-uppercase mt-4">Signature Requirements</h4>
                    <p>Due to COVID-19, carrier policies are changing; the courier service companies may not require a signature upon delivery. </p>

                    <p>Please make arrangements for someone to be at the shipping destination.</p>

                    <h4 className=" text-uppercase mt-4">When Will Your Order Be Shipped?</h4>
                    <p className="mb-0">For orders shipping to the U.S., you’ll find an estimated arrival date listed in the product details for each item. This arrival date is calculated based on the approximate number of days required to process, package, and deliver your order.</p>
                    <ul>
                        <li>If you place your order before the cut-off time on a regular business day (Monday through Friday), your order will begin processing that day.</li>
                        <li>If you place an order on a holiday, Saturday or Sunday, or after the cut-off on a regular business day, your order will begin processing the following business day.</li>
                    </ul>
                    <p>For international orders, we will provide you with an estimated ship date. Actual arrival date may vary based on transport times and customs clearance.</p>


                    <h4 className=" text-uppercase mt-4">Shipping To Alternate Addresses</h4>
                    <p>If you will not be home to sign for your order, Pointers Jewellers can ship your order to your place of work, or another more convenient destination. As a security precaution to protect our customers against fraud, when you order merchandise priced $1000 or more to be delivered to a destination other than your billing address, we will confirm that your credit card or financing company has this alternate shipping address on file. To help expedite your order, simply call your issuing bank and provide them with your alternate shipping address.</p>

                    <p>If you cannot be available, or have someone else available to sign for your package, you can have it shipped to a local hold for pick up location so that you can sign for and pick it up at your leisure. To schedule a hold for pick up, contact a diamond and jewellery consultant.</p>


                    <h4 className=" text-uppercase mt-4">Delivery Details</h4>
                    <p>Your order delivery method is determined by the price of your order (before tax). You’ll find your method of free shipping listed on the Shipping Information page within the shopping basket. If available, we provide the option to expedite your order with a faster shipping method for a small fee.</p>


                    <h4 className=" text-uppercase mt-4">Shipping Options</h4>
                    <p className="mb-0">All orders are insured and shipped free via secure carrier, delivery speed is based on the pre-tax value of your order.</p>
                    <ul>
                        <li>Pointers Jewellers will only ship to a street address, not a P.O. Box address.</li>
                        <li>Signature requirements for delivery:
                            <ul>
                                <li>Due to COVID-19, carrier policies are changing; FedEx and UPS may not require a signature upon delivery. For more information, please see the policies for FedEx and UPS. Please make arrangements for someone to be at the shipping destination.</li>
                                <li>Please contact Pointers Jewellers customer care if you will not be at home to keep an eye out for your package. We can change your delivery date, or schedule a pickup at a holding facility near you.</li>
                            </ul>
                        </li>
                    </ul>

                    <h4 className=" text-uppercase mt-4">International Shipping</h4>
                    <p>Pointers Jewellers offers free shipping via trusted carriers into 45 countries and regions. For more information on international shipping, please visit our International Shipping page.</p>

                    <h4 className=" text-uppercase mt-4">Packaging Of Jewellery</h4>
                    <p>Each item will arrive in a soft fabric pouch or a box inside a Pointers Jewellers gift box. View the shipping details tab at the bottom of each product page to see how your order will be presented.</p>


                    <h4 className=" text-uppercase mt-4">Packaging Of Diamonds And Set Rings</h4>
                    <p>Every item we send comes in our signature Pointers Jewellers packaging. Engagement rings arrive in a deluxe ring box within an elegant presentation box ready for your proposal. The presentation box also secures your appraisal certificate and GIA diamond grading report. Loose diamonds are presented in a velvet lined diamond case that securely holds the stone.</p>

                    <p>Read how to protect your jewellery against loss, theft, or damage, with insurance coverage of jewellery and loose diamonds.</p>

                </div>
            </div>
        </>
    )
}

export default ShippingPolicy