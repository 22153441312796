import React, { useState, useEffect, useRef } from 'react';
import './login.css';
import { Col, Row, Button, Form, Card, Alert } from 'react-bootstrap';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import useAuthorizationToken from '../../auth/useAuthorizationToken';
import MetaInformation from '../../components/metainformation/MetaInformation';

function Login() {
    const shouldRunEffect = useRef(true);
    const headerinfo = {
        title: "Customer Login | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Customer Login | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Customer Login"
    }];
    const { saveLatestToken, getAccessToken } = useAuthorizationToken();
    const [loginEmail, setLoginEmail] = useState();
    const [loginPassword, setLoginPassword] = useState();
    const [loginAlert, setLoginAlert] = useState();
    const [registerEmail, setRegisterEmail] = useState();
    const [registerPassword, setRegisterPassword] = useState();
    const [registerAlert, setRegisterAlert] = useState();


    useEffect(() => {
        if (shouldRunEffect.current) {
            if(sessionStorage.getItem("_pointerredirect")==="/login"){
                sessionStorage.removeItem("_pointerredirect");
            }
            if (getAccessToken()) {
                saveLatestToken();
            }
            shouldRunEffect.current = false;
        }
    });

    const handleRegisterOnSubmit = (e) => {
        e.preventDefault();
        const formdata = {
            email: registerEmail,
            password: registerPassword
        };
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Channel-ID": "pointers-web",
                "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
            },
            body: JSON.stringify(formdata)
        }).then(response => response.json()).then(data => {
            var redirectto = sessionStorage.getItem("_pointerredirect");
            if (data?.code === "200") {
                fetch(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-Channel-ID": "pointers-web",
                        "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
                    },
                    body: JSON.stringify(formdata)
                }).then(response => response.json()).then(data => {
                    if (data?.jwt) {
                        saveLatestToken(data);
                        if (redirectto) {
                            alert(redirectto);
                            window.location.replace(redirectto);
                        } else {
                            
                            alert("profile");
                            window.location.replace("/profile");
                        }
                    } else {
                        setLoginAlert(data);
                    }
                });
                console.log(data);
            } else {
                setRegisterAlert(data);
                // setRegisterEmail("");
                // setRegisterPassword("");
            }
        });
    }

    const handleLoginOnSubmit = (e) => {
        e.preventDefault();
        var redirectto = sessionStorage.getItem("_pointerredirect");
        const formdata = {
            email: loginEmail,
            password: loginPassword,
            // type: 'WEB'
        };
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Channel-ID": "pointers-web",
                "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
            },
            body: JSON.stringify(formdata)
        }).then(response => response.json()).then(data => {
            if (data?.jwt) {
                saveLatestToken(data);
                if (redirectto) {
                    window.location.replace(redirectto);
                } else {
                    window.location.replace("/profile");
                }
            } else {
                setLoginAlert(data);
            }
        });
    }
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="login-content">
                <div className="container pt-2">
                    <div className="login">
                        <Row>
                            <Col md="6" className="mb-5">
                                <Card>
                                    <Card.Body>
                                        <h3 className=" text-uppercase mt-3 mx-3">Customer Login</h3>
                                        <Form.Group className="m-3">
                                            {
                                                loginAlert?.code ? (<Alert variant="danger" onClose={e => setLoginAlert(null)} dismissible>
                                                    {loginAlert.message}
                                                </Alert>) : ''
                                            }
                                        </Form.Group>
                                        <Form onSubmit={handleLoginOnSubmit}>

                                            <Form.Group className="m-3">
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email" required={true} onChange={e => setLoginEmail(e.target.value)} />
                                            </Form.Group>

                                            <Form.Group className="m-3">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password" required={true} onChange={e => setLoginPassword(e.target.value)} />
                                            </Form.Group>
                                            <div className="m-3">
                                                <Button variant="primary" size="lg" type="submit" className="btn-red text-uppercase">
                                                    Login
                                                </Button>
                                                {/* <div className="mt-4">
                                                    <a href="/forgot-password">Forgot Password</a>
                                                </div> */}
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md="6" className="mb-5">
                                <Card>
                                    <Card.Body>
                                        <h3 className=" text-uppercase mt-3 mx-3">Register Now</h3>
                                        <Form.Group className="m-3">

                                            {
                                                registerAlert?.code === '200' ? (<Alert variant="success" onClose={e => setRegisterAlert(null)} dismissible>
                                                    {registerAlert.message}, please proceed to login.
                                                </Alert>) : ''
                                            }

                                            {
                                                registerAlert?.code === '500' ? (<Alert variant="danger" onClose={e => setRegisterAlert(null)} dismissible>
                                                    {registerAlert.message}
                                                </Alert>) : ''
                                            }
                                        </Form.Group>

                                        <Form onSubmit={e => handleRegisterOnSubmit(e)}>

                                            <Form.Group className="m-3">
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email" value={registerEmail} required={true} onChange={e => setRegisterEmail(e.target.value)} />
                                            </Form.Group>

                                            <Form.Group className="m-3">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password" value={registerPassword} required={true} onChange={e => setRegisterPassword(e.target.value)} />
                                            </Form.Group>
                                            <div className="m-3">
                                                <p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a href="/privacy-policy" className="text-underline">privacy policy</a>.</p>
                                                <Button variant="primary" size="lg" type="submit" className="btn-red text-uppercase">
                                                    Register
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </Col>

                        </Row>
                    </div>
                </div>
            </div></>
    )
}

export default Login