import React, { useEffect, useRef, useState } from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import './myProfile.css';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import { Col, Row, Form, Button, Alert } from 'react-bootstrap';
import { SaveOutlined } from '@material-ui/icons';
import useAuthorizationToken from '../../auth/useAuthorizationToken';
import MetaInformation from '../../components/metainformation/MetaInformation';

function MyProfile() {
    const headerinfo = {
        title: "My Profile | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "My Profile | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "/",
        label: "My Profile"
    }];
    const shouldRunEffect = useRef(true);
    const { axiosJWT } = useAuthorizationToken();
    const [user, setUser] = useState();
    const [currentPW, setCurrentPW] = useState();
    const [newPW, setNewPW] = useState();
    const [confirmPW, setConfirmPW] = useState();
    const [profileAlert, setProfileAlert] = useState();
    const [pwAlert, setPwAlert] = useState();
    useEffect(() => {
        async function fetchUserData() {

            const res = await axiosJWT.get(`${process.env.REACT_APP_API_ENDPOINT}/user`).catch(err=>{
                
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname!=="/login"?window.location.pathname:"/profile");
                localStorage.removeItem("_pointerstoken");
                window.location.replace("/login");
            });
            if (res?.data?.user) {
                setUser(res?.data?.user);
            }
        }


        if (shouldRunEffect.current) {
            fetchUserData();
            shouldRunEffect.current = false;
        }

    }, [setUser, axiosJWT]);

    const handleInfoOnChange = (key, value) => {
        var curUser = { ...user };
        if (key === 'email') curUser.email = value;
        if (key === 'name') curUser.name = value;
        if (key === 'phone') curUser.phone = value;
        setUser(curUser);
    }

    const handleOnSubmitError = (err) => {
        if (err.response) {
            setProfileAlert(err.response.data);
        }
    }

    const handleProfileOnSubmit = async (e) => {

        e.preventDefault();

        var formdata = {
            type: "WEB"
        };
        var pwcorrect = true;
        if (user.name) formdata.name = user.name;
        if (user.email) formdata.email = user.email;
        if (user.phone) formdata.phone = user.phone;

        if (currentPW || newPW || confirmPW) {

            if (newPW !== confirmPW) {
                pwcorrect = false;
                setProfileAlert({
                    code: '500',
                    message: "New Password and Confirm Password not matched."
                });
            }
            if (!newPW) {
                pwcorrect = false;
                setProfileAlert({
                    code: '500',
                    message: "Please Enter New Password."
                });
            }
            if (!currentPW) {
                pwcorrect = false;
                setProfileAlert({
                    code: '500',
                    message: "Please Enter Current Password."
                });
            }
            if (pwcorrect) {
                formdata.currentPassword = currentPW;
                formdata.password = confirmPW;
            }

        }
        if (pwcorrect) {
            const res = await axiosJWT.put(`${process.env.REACT_APP_API_ENDPOINT}/user/update/${user.id}`, formdata).catch((err) => {
                handleOnSubmitError(err);
            });
            if (res?.data?.code) setProfileAlert(res?.data);

        }
    }

    const handlePasswordOnSubmit = async (e) => {

        e.preventDefault();

        var formdata = {
            type: "WEB"
        };
        var pwcorrect = true;

        if (currentPW || newPW || confirmPW) {

            if (newPW !== confirmPW) {
                pwcorrect = false;
                setPwAlert({
                    code: '500',
                    message: "New Password and Confirm Password not matched."
                });
            }
            if (pwcorrect) {
                formdata.currentPassword = currentPW;
                formdata.password = confirmPW;
            }

        }
        if (pwcorrect) {
            const res = await axiosJWT.put(`${process.env.REACT_APP_API_ENDPOINT}/user/update/${user.id}`, formdata).catch((err) => {
                handleOnSubmitError(err);
            });
            if (res?.data?.code) setPwAlert(res?.data);

        }
    }

    return (
        <>
        
        <MetaInformation headerinfo={headerinfo} />
        <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="myprofile-content">
                <div className="container pb-5">
                    <h1 className=" text-uppercase">My Profile</h1>
                    <Row>

                        <Col sm="6">
                            <Accordion expanded={true} className="mb-5 accordion-profile">
                                <AccordionSummary><strong>My Information</strong></AccordionSummary>
                                <AccordionDetails>
                                    <Form onSubmit={handleProfileOnSubmit}>
                                        <Row className="mt-3 row-profile">
                                            {
                                                profileAlert?.code === '200' ? (

                                                    <Col xs="12">
                                                        <Form.Group className="mx-3">
                                                            <Alert variant="success" className="mb-0" onClose={e => setProfileAlert(null)} dismissible>
                                                                {profileAlert.message}
                                                            </Alert>
                                                        </Form.Group>
                                                    </Col>) : ''
                                            }

                                            {
                                                profileAlert?.code === '500' ? (
                                                    <Col xs="12">
                                                        <Form.Group className="mx-3">
                                                            <Alert variant="danger" className="mb-0" onClose={e => setProfileAlert(null)} dismissible>
                                                                {profileAlert.message}
                                                            </Alert>
                                                        </Form.Group>
                                                    </Col>) : ''
                                            }
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label className="requiredLabel"><strong>Email</strong></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Email"
                                                        value={user?.email}
                                                        required={true}
                                                        onChange={e => handleInfoOnChange('email', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label><strong>Name</strong></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Name"
                                                        value={user?.name}
                                                        onChange={e => handleInfoOnChange('name', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label><strong>Phone</strong></Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        placeholder="Phone"
                                                        value={user?.phone}
                                                        onChange={e => handleInfoOnChange('phone', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div className="text-center mt-3">
                                            <Button variant="primary" type="submit" size="lg" className="btn-red text-uppercase">
                                                <SaveOutlined /> Update
                                            </Button>
                                        </div>
                                    </Form>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                        <Col sm="6">
                            <Accordion expanded={true} className="mb-5 accordion-profile">
                                <AccordionSummary><strong>Change Password</strong></AccordionSummary>
                                <AccordionDetails>
                                    <Form onSubmit={handlePasswordOnSubmit}>
                                        <Row className="mt-3 row-profile">
                                            {
                                                pwAlert?.code === '200' ? (
                                                    <Col xs="12">
                                                        <Form.Group className="mx-3">
                                                            <Alert variant="success" className="mb-0" onClose={e => setPwAlert(null)} dismissible>
                                                                Password updated successfully
                                                            </Alert>
                                                        </Form.Group>
                                                    </Col>) : ''
                                            }

                                            {
                                                pwAlert?.code === '500' ? (

                                                    <Col xs="12">
                                                        <Form.Group className="mx-3">
                                                            <Alert variant="danger" className="mb-0" onClose={e => setPwAlert(null)} dismissible>
                                                                {pwAlert.message}
                                                            </Alert>
                                                        </Form.Group>
                                                    </Col>) : ''
                                            }
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label className="requiredLabel"><strong>Current Password</strong></Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Current Password"
                                                        value={currentPW}
                                                        required={true}
                                                        onChange={e => setCurrentPW(e.target.value)}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label className="requiredLabel"><strong>New Password</strong></Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="New Password"
                                                        value={newPW}
                                                        required={true}
                                                        onChange={e => setNewPW(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="m-3">
                                                    <Form.Label className="requiredLabel"><strong>Confirm Password</strong></Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        value={confirmPW}
                                                        required={true}
                                                        onChange={e => setConfirmPW(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div className="text-center mt-3">
                                            <Button variant="primary" type="submit" size="lg" className="btn-red text-uppercase">
                                                <SaveOutlined /> Update
                                            </Button>
                                        </div>
                                    </Form>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default MyProfile