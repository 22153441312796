import { Slider } from '@material-ui/core';
import React, {  useState } from 'react'
import "./productFingerSlider.css";

function ProductFingerSlider({ image }) {
    const [handBlackStyle, setHandBlackStyle] = useState({ opacity: 0 });
    const handleSliderChange = (event, newValue) => {
        if (typeof newValue === "number") {
            var opacity = newValue / 100;
            setHandBlackStyle({ opacity: opacity });
        }
    };



    return (
        <div className="imageFingerSliderBox">
            <div className="productSliderImages mb-4">
                <img
                    alt="Female Hand Light"
                    className="img-hand-light"
                    src="../../pointers/hand_sample/female_hand_light.jpg"
                />
                <img
                    alt="Female Hand Black"
                    style={handBlackStyle}
                    className="img-hand-black"
                    src="../../pointers/hand_sample/female_hand_black.jpg"
                />
                <img
                    alt="Ring Overlay"
                    className="img-ring-overlay"
                    src={image}
                />
                {/* {
                      prodImageSliderPreviewSelected ? <img
                        alt="Ring Overlay"
                        className="img-ring-overlay"
                        src={prodImageSliderPreviewSelected.url}
                      /> : null
                    } */}
            </div>
            <div className="divSlider">
                <div className="sliderLabel">Lighter</div>
                <div className="slider">
                    <Slider onChange={handleSliderChange} />
                </div>
                <div className="sliderLabel text-right">Darker</div>
            </div>
        </div>
    )
}

export default ProductFingerSlider