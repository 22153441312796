import React, { useState } from 'react';

import { Button, Col, Form, Row, Modal } from 'react-bootstrap';

import { Add, Close } from '@material-ui/icons';
import { stateslist } from '../../json/checkoutinfo';

function CreateAddressButton({ axiosJWT, fetchData, setAddressAlert }) {
    const [address, setAddress] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false);
    const handleAddressModalClose = () => setShowAddressModal(false);
    const handleAddressModalShow = () => setShowAddressModal(true);
    const handleOnSubmitError = (err) => {
        if (err.response) {
            setAddressAlert(err.response.data);
        }
    }

    const handleInfoOnChange = (key, value) => {
        var curAddress = { ...address };
        if (key === 'name') curAddress.name = value;
        if (key === 'phone') curAddress.phone = value;
        if (key === 'address') curAddress.address = value;
        if (key === 'postal') curAddress.postal = value;
        if (key === 'city') curAddress.city = value;
        if (key === 'state') curAddress.state = value;
        if (key === 'is_default') curAddress.is_default = value;
        setAddress(curAddress);
    }

    const handleAddressOnSubmit = async e => {
        e.preventDefault();
        var res = null;
        var curAddress = { ...address };
        if (!curAddress.is_default) {
            curAddress.is_default = false;
        }

        res = await axiosJWT.post(`${process.env.REACT_APP_API_ENDPOINT}/user/address`, curAddress).catch((err) => {
            handleOnSubmitError(err);
        });
        setAddressAlert(res?.data);
        if (res?.data?.code === 200) {
            fetchData();
            setAddress(null);
            handleAddressModalClose();
        }

    };


    return (
        <><Button variant="primary" type="button" size="lg" className="btn-red text-uppercase mb-3 w-100" onClick={handleAddressModalShow}>
            <Add /> Create New Address
        </Button>
            <Modal
                show={showAddressModal}
                onHide={handleAddressModalClose}
                size="lg"
                className="modal-diamond-detail"
            >
                <Modal.Body>
                    <Close
                        className="icon-close"
                        onClick={handleAddressModalClose} />
                    <h1 className=" text-uppercase">Create New Address</h1>

                    <Row>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>Name</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    required={true}
                                    value={address?.name}
                                    onChange={e => handleInfoOnChange('name', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>Phone</strong></Form.Label>
                                <div>

                                    <Form.Control type="text" value="+60" readOnly className="txt-phone-country" />
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        required={true}
                                        value={address?.phone}
                                        className="txt-phone"
                                        onChange={e => handleInfoOnChange('phone', e.target.value)}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>Address</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Address"
                                    required={true}
                                    value={address?.address}
                                    onChange={e => handleInfoOnChange('address', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>Postcode</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Postcode"
                                    required={true}
                                    value={address?.postal}
                                    onChange={e => handleInfoOnChange('postal', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>City</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="City"
                                    required={true}
                                    value={address?.city}
                                    onChange={e => handleInfoOnChange('city', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Label className="requiredLabel"><strong>State</strong></Form.Label>
                                <Form.Select
                                    required={true}
                                    value={address?.state}
                                    onChange={e => handleInfoOnChange('state', e.target.value)}>
                                    <option>-- Please Select State* --</option>
                                    {stateslist.map((state, index) => (
                                        <option value={state.label}>{state.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group className="m-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Set as Default Address"
                                    onChange={e => { handleInfoOnChange('is_default', e.target.checked) }} checked={address?.is_default}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-center mt-3">

                        <Button variant="primary" type="submit" size="lg" className="btn-red text-uppercase" onClick={handleAddressOnSubmit}>

                            <Add /> Create
                        </Button>
                    </div>

                </Modal.Body>
            </Modal></>
    )
}

export default CreateAddressButton