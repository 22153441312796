import React from 'react';
import "./lifetimeManufacturerWarranty.css";
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import MetaInformation from '../../components/metainformation/MetaInformation';

function LifetimeManufacturerWarranty() {
  const headerinfo = {
    title: "Lifetime Manufacturer Warrant | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
      title: "Lifetime Manufacturer Warrant | Pointers Jewellers",
      description: "",
      
            image: window.location.origin+"/pointers/pointers_logo_social.png",
      url: window.location.href
    }
  };
  const breadcrumbs = [{
    url: "",
    label: "Lifetime Manufacturer Warranty"
  }];
  return (
    <>
      <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="page-container mb-5">
        <div className="container">
          <h1 className=" text-uppercase">Lifetime Manufacturer Warranty</h1>
          <p>At Pointers, we hold an unwavering commitment to the exceptional quality of our creations. Thus, we warrant that all items shall remain free from any manufacturing defects for the entirety of their existence. Should you suspect that your cherished piece bears any such defect, we welcome you to return it to us for a comprehensive inspection.
          </p>
          <ul>
            <li>
              Upon scrutiny, should we ascertain that your merchandise has sustained damage attributable to a manufacturing defect, we shall undertake the necessary repairs. Alternatively, if deemed suitable, we may opt to replace the item.
            </li>
            <li>
              In the event that the damage is determined not to be rooted in a manufacturing defect, we shall promptly notify you and discuss the availability of repair services, whether at a cost or otherwise.
            </li>
          </ul>
          <h4 className=" text-uppercase mt-4">Complimentary Refurbishment and Inspection Services for Diamond Rings</h4>
          <p>In our endeavor to prolong the lifespan of your treasured jewelry, every piece purchased from Pointers is entitled to our invaluable refurbishment and meticulous inspection service. This encompasses professional polishing, precise prong tightening, thorough cleaning, and expert plating. This indispensable service is at your disposal once annually, for a remarkable ten years following the initial acquisition of your jewelry.</p>
          <p>For a detailed outline of our policy on Return Shipping Charges, kindly consult <a href="https://google.com" target="_blank" rel="noreferrer">here</a>.</p>
          <h4 className=" text-uppercase mt-4">Pave Warranty</h4>
          <p>For all diamonds and gemstones measuring below 0.05 ct adorning jewelry procured from Pointers, we extend a dedicated three-year warranty. Post this initial period, each diamond or gemstone shall be subject to charges commensurate with its prevailing market value.
          </p>
          <p>Rest assured, should you discover any pave stones absent, simply apprise your consultant, and we shall expedite the requisite assistance accordingly.</p>

          <h4 className=" text-uppercase mt-4">Conditions and Exclusions</h4>

          <p>The preceding Manufacturer Warranty and Complimentary Services are exclusive to the original purchaser of the covered product from Pointers. It is important to note that jewelry wherein the diamond was not procured from Pointers falls beyond the scope of our lifetime warranty.
          </p>
          <p>Any maintenance, repair, sizing, or other services executed by a party other than Pointers will render your Manufacturer Warranty null and void. It is imperative to acknowledge that fine jewelry is not impervious to the effects of regular wear, activities, or unforeseen events. This holds especially true for rings, as hands are frequently subjected to various stresses. Therefore, we do not extend warranties to cover damage arising from regular wear and tear, product loss, stone loss, or theft. Likewise, any damage or loss incurred due to a failure to obtain necessary repairs to maintain the integrity of the product is not covered.</p>

          <p>Kindly bear in mind that minor irregularities and variations in craftsmanship or natural characteristics are integral to the unique character of each piece and should not be perceived as a defect.</p>

          <p className="mb-0">To provide further clarity, we offer some examples of common jewelry issues that do not constitute manufacturing defects:</p>
          <ol>
            <li>Over time, prongs and precious metals may experience natural wear and tear, potentially necessitating maintenance or restoration to ensure their optimal condition.</li>
            <li>Prongs, being delicate elements, can become bent, dented, caught, or even wear out over time. This wear and tear can lead to the risk of a stone becoming dislodged or lost.
            </li>
            <li>Stones may become lost or dislodged due to chipping or breakage resulting from normal wear and other incidental damage.</li>

            <li>Discoloration can occur as a result of exposure to chemicals, cosmetics, chlorinated water, hot tubs, or regular bathing.</li>
            <li>It's not uncommon for lint or debris to accumulate on the prongs of a ring, which can be easily addressed with routine cleaning and maintenance.</li>
          </ol>

        </div>
      </div>
    </>
  )
}

export default LifetimeManufacturerWarranty