import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function InternationalPaymentOptions() {
    const headerinfo = {
        title: "International Payment Options | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "International Payment Options | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "International Payment Options"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">International Payment Options</h1>
                    <p>FIn conducting international transactions, POINTERS offers the following secure payment <strong>methods</strong>:</p>
                    <h4 className=" text-uppercase mt-4">Credit/Debit Card</h4>
                    <p className="mb-0">POINTERS accepts all major Visa and MasterCard, including both Credit and Debit cards. Payments can be conveniently made through our website using the iPay88 platform. </p>
                    <ul>
                        <li>Our system is 3-D Secure enabled, incorporating Verified by Visa and MasterCard Secure, ensuring the safeguarding of your credit card details through industry-leading encryption standards. Please note that POINTERS does not store your credit card information post-order completion, as it is directly submitted to our affiliated banks.</li>
                    </ul>
                    <p>*Please be advised that we regretfully cannot process Credit/Debit Card payments originating from Canada, Brazil, and the USA.</p>

                    
                    <h4 className=" text-uppercase mt-4">Telegraphic Transfer (TT)</h4>
                    <p>For international transactions, POINTERS facilitates payments through Telegraphic Transfer (TT) to our designated Maybank Bank account.</p>
                    <p className="mb-0"><strong>Deposit:</strong></p>
                    <p>Before processing orders, a minimum deposit of 70% is required. Full payment (100%) must be completed before collecting your order or arranging delivery.</p>
                    <p className="mb-0"><strong>Payment Confirmation:</strong></p>
                    <p>You will receive an email notification within 24 hours of confirming your order. Please be mindful of your bank's reconciliation schedule, as this may impact your payment, especially for Online Banking methods. Bank reconciliation times occur approximately between 11:30 p.m. to 1:30 a.m., depending on your bank.</p>






                </div>
            </div>
        </>
    )
}

export default InternationalPaymentOptions