export const diamonddetailresponse = {
  success: true,
  data: [
    {
      message: "Diamond found.",
      DIAMOND_TYPE: "Natural",
      STOCK_ID: 214662,
      SHAPE: "ROUND",
      CARAT: "0.71",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      CERTIFICATE_NO: "214662",
      WIDTH: "5.72",
      LENGTH: "5.71",
      DEPTH: "3.53",
      DEPTH_PER: "61.8",
      TABLE_PER: "57",
      CROWNANGLE: "35",
      CROWNHEIGHT: "15",
      PAVILIONHEIGHT: "43",
      PAVILIONANGLE: "40.8",
      PRICE_PER_CTS: "4039.2",
      TOTAL_PRICE: "2867.83",
      ORIGIN: "NO BROWN",
      TREATMENT: "NO GREEN",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      LOCATION: "INDIA",
      CULET: "",
      GIRDLE: "3.5",
      GIRDLE_CONDITION: "F",
      KEY_TO_SYMBOL: "Feather, Cloud",
      RATIO: "1.00",
      IMAGE: "",
      VIDEO: "https://pro360video.com/video.php?cert=214662",
      HEART_IMAGE: "",
      ARROW_IMAGE: "",
      ASSET_IMAGE: "",
    },
  ],
};
export const diamondslistresponse = {
  success: true,
  message: "DATA FOUND",
  total: 117,
  currentPage: 1,
  nextPageUrl: "",
  perPage: 1000,
  data: [
    {
      STOCK_ID: 214662,
      SHAPE: "ROUND",
      CARAT: "0.71",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.72",
      LENGTH: "5.71",
      DEPTH: "3.53",
      DEPTH_PER: "61.8",
      TABLE_PER: "57",
      PRICE_PER_CTS: "4039.2",
      TOTAL_PRICE: "2867.83",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=214662",
      VIDEO: "",
    },
    {
      STOCK_ID: 209323,
      SHAPE: "ROUND",
      CARAT: "0.71",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "5.68",
      LENGTH: "5.64",
      DEPTH: "3.54",
      DEPTH_PER: "62.5",
      TABLE_PER: "58",
      PRICE_PER_CTS: "3085.5",
      TOTAL_PRICE: "2190.71",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209323",
      VIDEO: "",
    },
    {
      STOCK_ID: 207028,
      SHAPE: "ROUND",
      CARAT: "0.71",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "VG",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.59",
      LENGTH: "5.57",
      DEPTH: "3.59",
      DEPTH_PER: "64.3",
      TABLE_PER: "57",
      PRICE_PER_CTS: "3225.75",
      TOTAL_PRICE: "2290.28",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207028",
      VIDEO: "",
    },
    {
      STOCK_ID: 209619,
      SHAPE: "ROUND",
      CARAT: "0.73",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.75",
      LENGTH: "5.72",
      DEPTH: "3.59",
      DEPTH_PER: "62.6",
      TABLE_PER: "58",
      PRICE_PER_CTS: "3870.9",
      TOTAL_PRICE: "2825.76",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209619",
      VIDEO: "",
    },
    {
      STOCK_ID: 196506,
      SHAPE: "ROUND",
      CARAT: "0.76",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.83",
      LENGTH: "5.8",
      DEPTH: "3.66",
      DEPTH_PER: "63",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4044.7",
      TOTAL_PRICE: "3073.97",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=196506",
      VIDEO: "",
    },
    {
      STOCK_ID: 204143,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "5.89",
      LENGTH: "5.91",
      DEPTH: "3.7",
      DEPTH_PER: "62.7",
      TABLE_PER: "56",
      PRICE_PER_CTS: "2678.4",
      TOTAL_PRICE: "2142.72",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204143",
      VIDEO: "",
    },
    {
      STOCK_ID: 198762,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.78",
      LENGTH: "5.73",
      DEPTH: "3.7",
      DEPTH_PER: "64.3",
      TABLE_PER: "57",
      PRICE_PER_CTS: "3616.25",
      TOTAL_PRICE: "2893",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198762",
      VIDEO: "",
    },
    {
      STOCK_ID: 209150,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.02",
      LENGTH: "5.98",
      DEPTH: "3.62",
      DEPTH_PER: "60.4",
      TABLE_PER: "60",
      PRICE_PER_CTS: "4151.4",
      TOTAL_PRICE: "3321.12",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209150",
      VIDEO: "",
    },
    {
      STOCK_ID: 207774,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.94",
      LENGTH: "5.9",
      DEPTH: "3.68",
      DEPTH_PER: "62.2",
      TABLE_PER: "57",
      PRICE_PER_CTS: "4263.6",
      TOTAL_PRICE: "3410.88",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207774",
      VIDEO: "",
    },
    {
      STOCK_ID: 207771,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "5.93",
      LENGTH: "5.89",
      DEPTH: "3.69",
      DEPTH_PER: "62.5",
      TABLE_PER: "57",
      PRICE_PER_CTS: "3450.15",
      TOTAL_PRICE: "2760.12",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207771",
      VIDEO: "",
    },
    {
      STOCK_ID: 207140,
      SHAPE: "ROUND",
      CARAT: "0.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.96",
      LENGTH: "5.92",
      DEPTH: "3.66",
      DEPTH_PER: "61.6",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4179.45",
      TOTAL_PRICE: "3343.56",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207140",
      VIDEO: "",
    },
    {
      STOCK_ID: 210929,
      SHAPE: "ROUND",
      CARAT: "0.81",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "5.95",
      LENGTH: "5.92",
      DEPTH: "3.69",
      DEPTH_PER: "62.2",
      TABLE_PER: "58",
      PRICE_PER_CTS: "3590.4",
      TOTAL_PRICE: "2908.22",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=210929",
      VIDEO: "",
    },
    {
      STOCK_ID: 210545,
      SHAPE: "ROUND",
      CARAT: "0.81",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "5.85",
      LENGTH: "5.82",
      DEPTH: "3.72",
      DEPTH_PER: "63.7",
      TABLE_PER: "58",
      PRICE_PER_CTS: "3029.4",
      TOTAL_PRICE: "2453.81",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=210545",
      VIDEO: "",
    },
    {
      STOCK_ID: 209028,
      SHAPE: "ROUND",
      CARAT: "0.81",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "5.85",
      LENGTH: "5.84",
      DEPTH: "3.73",
      DEPTH_PER: "63.8",
      TABLE_PER: "58",
      PRICE_PER_CTS: "3812",
      TOTAL_PRICE: "3087.72",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209028",
      VIDEO: "",
    },
    {
      STOCK_ID: 208450,
      SHAPE: "ROUND",
      CARAT: "0.81",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.03",
      LENGTH: "6",
      DEPTH: "3.7",
      DEPTH_PER: "61.4",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4011.15",
      TOTAL_PRICE: "3249.03",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=208450",
      VIDEO: "",
    },
    {
      STOCK_ID: 204248,
      SHAPE: "ROUND",
      CARAT: "0.9",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.15",
      LENGTH: "6.1",
      DEPTH: "3.86",
      DEPTH_PER: "63",
      TABLE_PER: "57",
      PRICE_PER_CTS: "4555.59",
      TOTAL_PRICE: "4100.03",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204248",
      VIDEO: "",
    },
    {
      STOCK_ID: 202632,
      SHAPE: "ROUND",
      CARAT: "0.9",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "5.99",
      LENGTH: "6.04",
      DEPTH: "3.88",
      DEPTH_PER: "64.4",
      TABLE_PER: "56",
      PRICE_PER_CTS: "3483.87",
      TOTAL_PRICE: "3135.48",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202632",
      VIDEO: "",
    },
    {
      STOCK_ID: 198284,
      SHAPE: "ROUND",
      CARAT: "0.9",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.03",
      LENGTH: "6.06",
      DEPTH: "3.89",
      DEPTH_PER: "64.3",
      TABLE_PER: "59",
      PRICE_PER_CTS: "3622.16",
      TOTAL_PRICE: "3259.95",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198284",
      VIDEO: "",
    },
    {
      STOCK_ID: 208599,
      SHAPE: "ROUND",
      CARAT: "0.9",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "VG",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.04",
      LENGTH: "6.01",
      DEPTH: "3.9",
      DEPTH_PER: "64.8",
      TABLE_PER: "60",
      PRICE_PER_CTS: "3355.8",
      TOTAL_PRICE: "3020.22",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=208599",
      VIDEO: "",
    },
    {
      STOCK_ID: 198283,
      SHAPE: "ROUND",
      CARAT: "0.91",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "VG",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.04",
      LENGTH: "6.1",
      DEPTH: "3.83",
      DEPTH_PER: "63.1",
      TABLE_PER: "59",
      PRICE_PER_CTS: "4190.06",
      TOTAL_PRICE: "3812.95",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198283",
      VIDEO: "",
    },
    {
      STOCK_ID: 205731,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "EX",
      SYMMETRY: "GD",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.16",
      LENGTH: "6.12",
      DEPTH: "4.01",
      DEPTH_PER: "65.3",
      TABLE_PER: "55",
      PRICE_PER_CTS: "4702.01",
      TOTAL_PRICE: "4702.01",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205731",
      VIDEO: "",
    },
    {
      STOCK_ID: 205730,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.41",
      LENGTH: "6.38",
      DEPTH: "3.94",
      DEPTH_PER: "61.7",
      TABLE_PER: "60",
      PRICE_PER_CTS: "7526.73",
      TOTAL_PRICE: "7526.73",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205730",
      VIDEO: "",
    },
    {
      STOCK_ID: 204355,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.35",
      LENGTH: "6.37",
      DEPTH: "3.99",
      DEPTH_PER: "62.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "6701.08",
      TOTAL_PRICE: "6701.08",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204355",
      VIDEO: "",
    },
    {
      STOCK_ID: 214880,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.38",
      LENGTH: "6.33",
      DEPTH: "3.94",
      DEPTH_PER: "62",
      TABLE_PER: "59",
      PRICE_PER_CTS: "5728.32",
      TOTAL_PRICE: "5728.32",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=214880",
      VIDEO: "",
    },
    {
      STOCK_ID: 204353,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.43",
      LENGTH: "6.39",
      DEPTH: "3.96",
      DEPTH_PER: "61.7",
      TABLE_PER: "59",
      PRICE_PER_CTS: "7510.94",
      TOTAL_PRICE: "7510.94",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204353",
      VIDEO: "",
    },
    {
      STOCK_ID: 211626,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.24",
      LENGTH: "6.19",
      DEPTH: "3.97",
      DEPTH_PER: "63.9",
      TABLE_PER: "59",
      PRICE_PER_CTS: "5197.92",
      TOTAL_PRICE: "5197.92",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=211626",
      VIDEO: "",
    },
    {
      STOCK_ID: 202500,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.51",
      LENGTH: "6.53",
      DEPTH: "3.89",
      DEPTH_PER: "59.7",
      TABLE_PER: "61",
      PRICE_PER_CTS: "8079",
      TOTAL_PRICE: "8079",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202500",
      VIDEO: "",
    },
    {
      STOCK_ID: 202499,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.33",
      LENGTH: "6.39",
      DEPTH: "3.98",
      DEPTH_PER: "62.6",
      TABLE_PER: "56",
      PRICE_PER_CTS: "8287",
      TOTAL_PRICE: "8287",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202499",
      VIDEO: "",
    },
    {
      STOCK_ID: 210820,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.38",
      LENGTH: "6.34",
      DEPTH: "3.99",
      DEPTH_PER: "62.8",
      TABLE_PER: "59",
      PRICE_PER_CTS: "7717",
      TOTAL_PRICE: "7717",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=210820",
      VIDEO: "",
    },
    {
      STOCK_ID: 202498,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.33",
      LENGTH: "6.38",
      DEPTH: "3.99",
      DEPTH_PER: "62.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "7871",
      TOTAL_PRICE: "7871",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202498",
      VIDEO: "",
    },
    {
      STOCK_ID: 202497,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.37",
      LENGTH: "6.41",
      DEPTH: "3.91",
      DEPTH_PER: "61.2",
      TABLE_PER: "62",
      PRICE_PER_CTS: "6831",
      TOTAL_PRICE: "6831",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202497",
      VIDEO: "",
    },
    {
      STOCK_ID: 199060,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "GD",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.24",
      LENGTH: "6.13",
      DEPTH: "4.03",
      DEPTH_PER: "65.2",
      TABLE_PER: "56",
      PRICE_PER_CTS: "4977.55",
      TOTAL_PRICE: "4977.55",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=199060",
      VIDEO: "",
    },
    {
      STOCK_ID: 210728,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.41",
      LENGTH: "6.38",
      DEPTH: "4.02",
      DEPTH_PER: "62.8",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7613",
      TOTAL_PRICE: "7613",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=210728",
      VIDEO: "",
    },
    {
      STOCK_ID: 202496,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.4",
      LENGTH: "6.43",
      DEPTH: "3.89",
      DEPTH_PER: "60.7",
      TABLE_PER: "62",
      PRICE_PER_CTS: "8183",
      TOTAL_PRICE: "8183",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202496",
      VIDEO: "",
    },
    {
      STOCK_ID: 202495,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.41",
      LENGTH: "6.46",
      DEPTH: "3.95",
      DEPTH_PER: "61.4",
      TABLE_PER: "59",
      PRICE_PER_CTS: "7663",
      TOTAL_PRICE: "7663",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202495",
      VIDEO: "",
    },
    {
      STOCK_ID: 209677,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.36",
      LENGTH: "6.31",
      DEPTH: "4.03",
      DEPTH_PER: "63.6",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7821",
      TOTAL_PRICE: "7821",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209677",
      VIDEO: "",
    },
    {
      STOCK_ID: 202494,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.39",
      LENGTH: "6.4",
      DEPTH: "3.96",
      DEPTH_PER: "61.9",
      TABLE_PER: "55",
      PRICE_PER_CTS: "8183",
      TOTAL_PRICE: "8183",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202494",
      VIDEO: "",
    },
    {
      STOCK_ID: 198328,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.22",
      LENGTH: "6.27",
      DEPTH: "4",
      DEPTH_PER: "64",
      TABLE_PER: "56",
      PRICE_PER_CTS: "5413.34",
      TOTAL_PRICE: "5413.34",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198328",
      VIDEO: "",
    },
    {
      STOCK_ID: 209674,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.47",
      LENGTH: "6.44",
      DEPTH: "3.92",
      DEPTH_PER: "60.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "6677",
      TOTAL_PRICE: "6677",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209674",
      VIDEO: "",
    },
    {
      STOCK_ID: 198327,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.28",
      LENGTH: "6.31",
      DEPTH: "3.98",
      DEPTH_PER: "63.3",
      TABLE_PER: "55",
      PRICE_PER_CTS: "6006.5",
      TOTAL_PRICE: "6006.5",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198327",
      VIDEO: "",
    },
    {
      STOCK_ID: 195585,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "0",
      LENGTH: "6.505",
      DEPTH: "0",
      DEPTH_PER: "59.2",
      TABLE_PER: "61",
      PRICE_PER_CTS: "8151.2",
      TOTAL_PRICE: "8151.2",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "No",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195585",
      VIDEO: "",
    },
    {
      STOCK_ID: 195568,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "0",
      LENGTH: "6.095",
      DEPTH: "0",
      DEPTH_PER: "66.5",
      TABLE_PER: "57",
      PRICE_PER_CTS: "4798.03",
      TOTAL_PRICE: "4798.03",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "No",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195568",
      VIDEO: "",
    },
    {
      STOCK_ID: 207275,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.23",
      LENGTH: "6.2",
      DEPTH: "4",
      DEPTH_PER: "64.3",
      TABLE_PER: "57",
      PRICE_PER_CTS: "5516.16",
      TOTAL_PRICE: "5516.16",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207275",
      VIDEO: "",
    },
    {
      STOCK_ID: 215262,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.37",
      LENGTH: "6.41",
      DEPTH: "3.96",
      DEPTH_PER: "62",
      TABLE_PER: "59",
      PRICE_PER_CTS: "8495",
      TOTAL_PRICE: "8495",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215262",
      VIDEO: "",
    },
    {
      STOCK_ID: 204360,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.42",
      LENGTH: "6.39",
      DEPTH: "3.96",
      DEPTH_PER: "61.9",
      TABLE_PER: "60",
      PRICE_PER_CTS: "5156.75",
      TOTAL_PRICE: "5156.75",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204360",
      VIDEO: "",
    },
    {
      STOCK_ID: 207013,
      SHAPE: "ROUND",
      CARAT: "1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.34",
      LENGTH: "6.3",
      DEPTH: "4.01",
      DEPTH_PER: "63.4",
      TABLE_PER: "55",
      PRICE_PER_CTS: "6677",
      TOTAL_PRICE: "6677",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207013",
      VIDEO: "",
    },
    {
      STOCK_ID: 214881,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "6.41",
      LENGTH: "6.37",
      DEPTH: "3.99",
      DEPTH_PER: "62.5",
      TABLE_PER: "59",
      PRICE_PER_CTS: "6571.76",
      TOTAL_PRICE: "6637.48",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=214881",
      VIDEO: "",
    },
    {
      STOCK_ID: 204354,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.54",
      LENGTH: "6.51",
      DEPTH: "3.87",
      DEPTH_PER: "59.2",
      TABLE_PER: "61",
      PRICE_PER_CTS: "7689.39",
      TOTAL_PRICE: "7766.29",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204354",
      VIDEO: "",
    },
    {
      STOCK_ID: 211509,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.15",
      LENGTH: "6.11",
      DEPTH: "4.07",
      DEPTH_PER: "66.4",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4879.68",
      TOTAL_PRICE: "4928.48",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=211509",
      VIDEO: "",
    },
    {
      STOCK_ID: 209740,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "VG",
      SYMMETRY: "VG",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.12",
      LENGTH: "6.05",
      DEPTH: "4.09",
      DEPTH_PER: "67.2",
      TABLE_PER: "59",
      PRICE_PER_CTS: "5091.84",
      TOTAL_PRICE: "5142.76",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209740",
      VIDEO: "",
    },
    {
      STOCK_ID: 198329,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "VST",
      LAB: "GIA",
      WIDTH: "6.11",
      LENGTH: "6.09",
      DEPTH: "4.05",
      DEPTH_PER: "66.3",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4046.83",
      TOTAL_PRICE: "4087.3",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198329",
      VIDEO: "",
    },
    {
      STOCK_ID: 202348,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.37",
      LENGTH: "6.41",
      DEPTH: "4.01",
      DEPTH_PER: "62.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "8701.26",
      TOTAL_PRICE: "8788.28",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202348",
      VIDEO: "",
    },
    {
      STOCK_ID: 209056,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "GD",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.13",
      LENGTH: "6.09",
      DEPTH: "4.06",
      DEPTH_PER: "66.5",
      TABLE_PER: "58",
      PRICE_PER_CTS: "4773.6",
      TOTAL_PRICE: "4821.34",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=209056",
      VIDEO: "",
    },
    {
      STOCK_ID: 195614,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "0",
      LENGTH: "6.08",
      DEPTH: "0",
      DEPTH_PER: "66.8",
      TABLE_PER: "59",
      PRICE_PER_CTS: "4585.65",
      TOTAL_PRICE: "4631.51",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "No",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195614",
      VIDEO: "",
    },
    {
      STOCK_ID: 215067,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "VG",
      SYMMETRY: "GD",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.14",
      LENGTH: "6.21",
      DEPTH: "4.04",
      DEPTH_PER: "65.4",
      TABLE_PER: "56",
      PRICE_PER_CTS: "5038.8",
      TOTAL_PRICE: "5089.19",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215067",
      VIDEO: "",
    },
    {
      STOCK_ID: 204359,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.41",
      LENGTH: "6.38",
      DEPTH: "3.99",
      DEPTH_PER: "62.5",
      TABLE_PER: "59",
      PRICE_PER_CTS: "5206.16",
      TOTAL_PRICE: "5258.22",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204359",
      VIDEO: "",
    },
    {
      STOCK_ID: 204358,
      SHAPE: "ROUND",
      CARAT: "1.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.48",
      LENGTH: "6.52",
      DEPTH: "3.9",
      DEPTH_PER: "59.9",
      TABLE_PER: "62",
      PRICE_PER_CTS: "6551.43",
      TOTAL_PRICE: "6616.95",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204358",
      VIDEO: "",
    },
    {
      STOCK_ID: 202259,
      SHAPE: "ROUND",
      CARAT: "1.03",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.47",
      LENGTH: "6.49",
      DEPTH: "3.97",
      DEPTH_PER: "61.2",
      TABLE_PER: "61",
      PRICE_PER_CTS: "7865.9",
      TOTAL_PRICE: "8101.88",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202259",
      VIDEO: "",
    },
    {
      STOCK_ID: 202258,
      SHAPE: "ROUND",
      CARAT: "1.03",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.45",
      LENGTH: "6.46",
      DEPTH: "4.01",
      DEPTH_PER: "62.1",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7865.9",
      TOTAL_PRICE: "8101.88",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202258",
      VIDEO: "",
    },
    {
      STOCK_ID: 202235,
      SHAPE: "ROUND",
      CARAT: "1.04",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.58",
      LENGTH: "6.6",
      DEPTH: "3.98",
      DEPTH_PER: "60.3",
      TABLE_PER: "59",
      PRICE_PER_CTS: "8072.27",
      TOTAL_PRICE: "8395.16",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202235",
      VIDEO: "",
    },
    {
      STOCK_ID: 211621,
      SHAPE: "ROUND",
      CARAT: "1.05",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.47",
      LENGTH: "6.43",
      DEPTH: "4.05",
      DEPTH_PER: "62.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "5516.16",
      TOTAL_PRICE: "5791.97",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=211621",
      VIDEO: "",
    },
    {
      STOCK_ID: 202206,
      SHAPE: "ROUND",
      CARAT: "1.05",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.55",
      LENGTH: "6.56",
      DEPTH: "4",
      DEPTH_PER: "61.1",
      TABLE_PER: "58",
      PRICE_PER_CTS: "8278.67",
      TOTAL_PRICE: "8692.6",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202206",
      VIDEO: "",
    },
    {
      STOCK_ID: 215022,
      SHAPE: "ROUND",
      CARAT: "1.06",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.55",
      LENGTH: "6.58",
      DEPTH: "4.01",
      DEPTH_PER: "61.1",
      TABLE_PER: "58",
      PRICE_PER_CTS: "8485.09",
      TOTAL_PRICE: "8994.2",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215022",
      VIDEO: "",
    },
    {
      STOCK_ID: 195586,
      SHAPE: "ROUND",
      CARAT: "1.06",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "0",
      LENGTH: "6.595",
      DEPTH: "0",
      DEPTH_PER: "60.9",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7623.11",
      TOTAL_PRICE: "8080.5",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "No",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195586",
      VIDEO: "",
    },
    {
      STOCK_ID: 195207,
      SHAPE: "ROUND",
      CARAT: "1.06",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.49",
      LENGTH: "6.53",
      DEPTH: "4.07",
      DEPTH_PER: "62.5",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7271.19",
      TOTAL_PRICE: "7707.47",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "NO",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195207",
      VIDEO: "",
    },
    {
      STOCK_ID: 202136,
      SHAPE: "ROUND",
      CARAT: "1.09",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.67",
      LENGTH: "6.71",
      DEPTH: "4.05",
      DEPTH_PER: "60.6",
      TABLE_PER: "59",
      PRICE_PER_CTS: "8168.55",
      TOTAL_PRICE: "8903.72",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=202136",
      VIDEO: "",
    },
    {
      STOCK_ID: 204357,
      SHAPE: "ROUND",
      CARAT: "1.1",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.73",
      LENGTH: "6.7",
      DEPTH: "4.03",
      DEPTH_PER: "60.1",
      TABLE_PER: "59",
      PRICE_PER_CTS: "6217.7",
      TOTAL_PRICE: "6839.47",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204357",
      VIDEO: "",
    },
    {
      STOCK_ID: 196279,
      SHAPE: "ROUND",
      CARAT: "1.11",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.66",
      LENGTH: "6.63",
      DEPTH: "4.07",
      DEPTH_PER: "61.2",
      TABLE_PER: "59",
      PRICE_PER_CTS: "7046.63",
      TOTAL_PRICE: "7821.76",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=196279",
      VIDEO: "",
    },
    {
      STOCK_ID: 204356,
      SHAPE: "ROUND",
      CARAT: "1.15",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.69",
      LENGTH: "6.64",
      DEPTH: "4.18",
      DEPTH_PER: "62.7",
      TABLE_PER: "58",
      PRICE_PER_CTS: "6246.81",
      TOTAL_PRICE: "7183.83",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204356",
      VIDEO: "",
    },
    {
      STOCK_ID: 204361,
      SHAPE: "ROUND",
      CARAT: "1.16",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "VST",
      LAB: "GIA",
      WIDTH: "6.69",
      LENGTH: "6.65",
      DEPTH: "4.16",
      DEPTH_PER: "62.4",
      TABLE_PER: "59",
      PRICE_PER_CTS: "5389.22",
      TOTAL_PRICE: "6251.5",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204361",
      VIDEO: "",
    },
    {
      STOCK_ID: 204840,
      SHAPE: "ROUND",
      CARAT: "1.2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.76",
      LENGTH: "6.7",
      DEPTH: "4.22",
      DEPTH_PER: "62.7",
      TABLE_PER: "57",
      PRICE_PER_CTS: "6809.85",
      TOTAL_PRICE: "8171.82",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204840",
      VIDEO: "",
    },
    {
      STOCK_ID: 195019,
      SHAPE: "ROUND",
      CARAT: "1.2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.74",
      LENGTH: "6.76",
      DEPTH: "4.26",
      DEPTH_PER: "63.1",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7271.9",
      TOTAL_PRICE: "8726.28",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "NO",
      COUNTRY: "USA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195019",
      VIDEO: "",
    },
    {
      STOCK_ID: 215010,
      SHAPE: "ROUND",
      CARAT: "1.21",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "6.89",
      LENGTH: "6.9",
      DEPTH: "4.18",
      DEPTH_PER: "60.6",
      TABLE_PER: "59",
      PRICE_PER_CTS: "8256.63",
      TOTAL_PRICE: "9990.52",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215010",
      VIDEO: "",
    },
    {
      STOCK_ID: 204839,
      SHAPE: "ROUND",
      CARAT: "1.21",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "6.8",
      LENGTH: "6.77",
      DEPTH: "4.26",
      DEPTH_PER: "62.7",
      TABLE_PER: "57",
      PRICE_PER_CTS: "7189.26",
      TOTAL_PRICE: "8699",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204839",
      VIDEO: "",
    },
    {
      STOCK_ID: 199304,
      SHAPE: "ROUND",
      CARAT: "1.3",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.05",
      LENGTH: "7.02",
      DEPTH: "4.28",
      DEPTH_PER: "60.9",
      TABLE_PER: "58",
      PRICE_PER_CTS: "7713.27",
      TOTAL_PRICE: "10027.26",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=199304",
      VIDEO: "",
    },
    {
      STOCK_ID: 201975,
      SHAPE: "ROUND",
      CARAT: "1.3",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "STG",
      LAB: "GIA",
      WIDTH: "6.94",
      LENGTH: "7",
      DEPTH: "4.39",
      DEPTH_PER: "62.9",
      TABLE_PER: "56",
      PRICE_PER_CTS: "6270.61",
      TOTAL_PRICE: "8151.8",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201975",
      VIDEO: "",
    },
    {
      STOCK_ID: 201923,
      SHAPE: "ROUND",
      CARAT: "1.4",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.08",
      LENGTH: "7.13",
      DEPTH: "4.49",
      DEPTH_PER: "63.2",
      TABLE_PER: "58",
      PRICE_PER_CTS: "9173",
      TOTAL_PRICE: "12842.19",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201923",
      VIDEO: "",
    },
    {
      STOCK_ID: 201922,
      SHAPE: "ROUND",
      CARAT: "1.4",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.15",
      LENGTH: "7.19",
      DEPTH: "4.46",
      DEPTH_PER: "62.2",
      TABLE_PER: "55",
      PRICE_PER_CTS: "9173",
      TOTAL_PRICE: "12842.19",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201922",
      VIDEO: "",
    },
    {
      STOCK_ID: 204968,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.26",
      LENGTH: "7.29",
      DEPTH: "4.57",
      DEPTH_PER: "62.8",
      TABLE_PER: "56",
      PRICE_PER_CTS: "11739.24",
      TOTAL_PRICE: "17608.87",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204968",
      VIDEO: "",
    },
    {
      STOCK_ID: 204967,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.47",
      LENGTH: "7.44",
      DEPTH: "4.4",
      DEPTH_PER: "59.1",
      TABLE_PER: "60",
      PRICE_PER_CTS: "10855.52",
      TOTAL_PRICE: "16283.29",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204967",
      VIDEO: "",
    },
    {
      STOCK_ID: 204966,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.29",
      LENGTH: "7.26",
      DEPTH: "4.56",
      DEPTH_PER: "62.6",
      TABLE_PER: "56",
      PRICE_PER_CTS: "14148.07",
      TOTAL_PRICE: "21222.11",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204966",
      VIDEO: "",
    },
    {
      STOCK_ID: 204965,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.29",
      LENGTH: "7.26",
      DEPTH: "4.57",
      DEPTH_PER: "62.8",
      TABLE_PER: "55",
      PRICE_PER_CTS: "14146.07",
      TOTAL_PRICE: "21219.11",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204965",
      VIDEO: "",
    },
    {
      STOCK_ID: 201879,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.28",
      LENGTH: "7.34",
      DEPTH: "4.55",
      DEPTH_PER: "62.3",
      TABLE_PER: "59",
      PRICE_PER_CTS: "10280.66",
      TOTAL_PRICE: "15421",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201879",
      VIDEO: "",
    },
    {
      STOCK_ID: 207024,
      SHAPE: "ROUND",
      CARAT: "1.5",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.17",
      LENGTH: "7.1",
      DEPTH: "4.58",
      DEPTH_PER: "64.2",
      TABLE_PER: "58",
      PRICE_PER_CTS: "7996.73",
      TOTAL_PRICE: "11995.1",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=207024",
      VIDEO: "",
    },
    {
      STOCK_ID: 214281,
      SHAPE: "ROUND",
      CARAT: "1.51",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.18",
      LENGTH: "7.17",
      DEPTH: "4.63",
      DEPTH_PER: "64.5",
      TABLE_PER: "58",
      PRICE_PER_CTS: "8002.78",
      TOTAL_PRICE: "12084.2",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=214281",
      VIDEO: "",
    },
    {
      STOCK_ID: 198545,
      SHAPE: "ROUND",
      CARAT: "1.52",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "GD",
      POLISH: "VG",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.06",
      LENGTH: "7.02",
      DEPTH: "4.69",
      DEPTH_PER: "66.6",
      TABLE_PER: "57",
      PRICE_PER_CTS: "9178.76",
      TOTAL_PRICE: "13951.71",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=198545",
      VIDEO: "",
    },
    {
      STOCK_ID: 205757,
      SHAPE: "ROUND",
      CARAT: "1.52",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "VG",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "7.24",
      LENGTH: "7.15",
      DEPTH: "4.6",
      DEPTH_PER: "63.9",
      TABLE_PER: "58",
      PRICE_PER_CTS: "7883.15",
      TOTAL_PRICE: "11982.38",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205757",
      VIDEO: "",
    },
    {
      STOCK_ID: 215052,
      SHAPE: "ROUND",
      CARAT: "1.55",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "FNT",
      LAB: "GIA",
      WIDTH: "7.39",
      LENGTH: "7.42",
      DEPTH: "4.56",
      DEPTH_PER: "61.6",
      TABLE_PER: "60",
      PRICE_PER_CTS: "8198.65",
      TOTAL_PRICE: "12707.9",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215052",
      VIDEO: "",
    },
    {
      STOCK_ID: 194157,
      SHAPE: "ROUND",
      CARAT: "1.6",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.63",
      LENGTH: "7.59",
      DEPTH: "4.5",
      DEPTH_PER: "59.1",
      TABLE_PER: "60",
      PRICE_PER_CTS: "9578.09",
      TOTAL_PRICE: "15324.94",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "",
      COUNTRY: "USA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=194157",
      VIDEO: "",
    },
    {
      STOCK_ID: 204964,
      SHAPE: "ROUND",
      CARAT: "1.65",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.63",
      LENGTH: "7.57",
      DEPTH: "4.59",
      DEPTH_PER: "60.3",
      TABLE_PER: "59",
      PRICE_PER_CTS: "15428.23",
      TOTAL_PRICE: "25456.57",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=204964",
      VIDEO: "",
    },
    {
      STOCK_ID: 194789,
      SHAPE: "ROUND",
      CARAT: "1.7",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.53",
      LENGTH: "7.59",
      DEPTH: "4.81",
      DEPTH_PER: "63.5",
      TABLE_PER: "56",
      PRICE_PER_CTS: "8234.97",
      TOTAL_PRICE: "13999.45",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=194789",
      VIDEO: "",
    },
    {
      STOCK_ID: 201784,
      SHAPE: "ROUND",
      CARAT: "1.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.77",
      LENGTH: "7.82",
      DEPTH: "4.77",
      DEPTH_PER: "61.2",
      TABLE_PER: "58",
      PRICE_PER_CTS: "11449.22",
      TOTAL_PRICE: "20608.6",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201784",
      VIDEO: "",
    },
    {
      STOCK_ID: 201783,
      SHAPE: "ROUND",
      CARAT: "1.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.72",
      LENGTH: "7.77",
      DEPTH: "4.89",
      DEPTH_PER: "63.1",
      TABLE_PER: "57",
      PRICE_PER_CTS: "11053.22",
      TOTAL_PRICE: "19895.8",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201783",
      VIDEO: "",
    },
    {
      STOCK_ID: 215050,
      SHAPE: "ROUND",
      CARAT: "1.8",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.73",
      LENGTH: "7.76",
      DEPTH: "4.85",
      DEPTH_PER: "62.7",
      TABLE_PER: "58",
      PRICE_PER_CTS: "9705.44",
      TOTAL_PRICE: "17469.8",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=215050",
      VIDEO: "",
    },
    {
      STOCK_ID: 201742,
      SHAPE: "ROUND",
      CARAT: "1.9",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.86",
      LENGTH: "7.91",
      DEPTH: "4.94",
      DEPTH_PER: "62.6",
      TABLE_PER: "56",
      PRICE_PER_CTS: "9332.11",
      TOTAL_PRICE: "17731.01",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201742",
      VIDEO: "",
    },
    {
      STOCK_ID: 205130,
      SHAPE: "ROUND",
      CARAT: "2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.14",
      LENGTH: "8.1",
      DEPTH: "4.92",
      DEPTH_PER: "60.6",
      TABLE_PER: "59",
      PRICE_PER_CTS: "24934.52",
      TOTAL_PRICE: "49869.05",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205130",
      VIDEO: "",
    },
    {
      STOCK_ID: 201716,
      SHAPE: "ROUND",
      CARAT: "2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.02",
      LENGTH: "8.06",
      DEPTH: "4.96",
      DEPTH_PER: "61.6",
      TABLE_PER: "60",
      PRICE_PER_CTS: "14355.62",
      TOTAL_PRICE: "28711.24",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201716",
      VIDEO: "",
    },
    {
      STOCK_ID: 201715,
      SHAPE: "ROUND",
      CARAT: "2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "VG",
      SYMMETRY: "VG",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "7.86",
      LENGTH: "7.91",
      DEPTH: "5.05",
      DEPTH_PER: "64",
      TABLE_PER: "56",
      PRICE_PER_CTS: "9522.5",
      TOTAL_PRICE: "19045",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201715",
      VIDEO: "",
    },
    {
      STOCK_ID: 206525,
      SHAPE: "ROUND",
      CARAT: "2",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "VG",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "7.85",
      LENGTH: "7.8",
      DEPTH: "5.09",
      DEPTH_PER: "65",
      TABLE_PER: "56",
      PRICE_PER_CTS: "10515",
      TOTAL_PRICE: "21030",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=206525",
      VIDEO: "",
    },
    {
      STOCK_ID: 201665,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.09",
      LENGTH: "8.13",
      DEPTH: "4.99",
      DEPTH_PER: "61.5",
      TABLE_PER: "59",
      PRICE_PER_CTS: "14355.5",
      TOTAL_PRICE: "28854.55",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201665",
      VIDEO: "",
    },
    {
      STOCK_ID: 205545,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "8.16",
      LENGTH: "8.11",
      DEPTH: "4.92",
      DEPTH_PER: "60.5",
      TABLE_PER: "58",
      PRICE_PER_CTS: "10081.09",
      TOTAL_PRICE: "20262.99",
      SHADE: "",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205545",
      VIDEO: "",
    },
    {
      STOCK_ID: 205131,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "8.11",
      LENGTH: "8.06",
      DEPTH: "5.02",
      DEPTH_PER: "62.1",
      TABLE_PER: "58",
      PRICE_PER_CTS: "20510.18",
      TOTAL_PRICE: "41225.46",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205131",
      VIDEO: "",
    },
    {
      STOCK_ID: 195275,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "VST",
      LAB: "GIA",
      WIDTH: "8.12",
      LENGTH: "8.18",
      DEPTH: "4.9",
      DEPTH_PER: "60.2",
      TABLE_PER: "60",
      PRICE_PER_CTS: "8774.54",
      TOTAL_PRICE: "17636.82",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "INDIA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195275",
      VIDEO: "",
    },
    {
      STOCK_ID: 201667,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.04",
      LENGTH: "8.1",
      DEPTH: "4.97",
      DEPTH_PER: "61.5",
      TABLE_PER: "60",
      PRICE_PER_CTS: "13797.17",
      TOTAL_PRICE: "27732.31",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201667",
      VIDEO: "",
    },
    {
      STOCK_ID: 201666,
      SHAPE: "ROUND",
      CARAT: "2.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.07",
      LENGTH: "8.11",
      DEPTH: "5.04",
      DEPTH_PER: "62.3",
      TABLE_PER: "57",
      PRICE_PER_CTS: "14169.39",
      TOTAL_PRICE: "28480.47",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201666",
      VIDEO: "",
    },
    {
      STOCK_ID: 201649,
      SHAPE: "ROUND",
      CARAT: "2.02",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.12",
      LENGTH: "8.16",
      DEPTH: "4.99",
      DEPTH_PER: "61.3",
      TABLE_PER: "60",
      PRICE_PER_CTS: "14541.48",
      TOTAL_PRICE: "29373.79",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201649",
      VIDEO: "",
    },
    {
      STOCK_ID: 201634,
      SHAPE: "ROUND",
      CARAT: "2.04",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.23",
      LENGTH: "8.3",
      DEPTH: "4.97",
      DEPTH_PER: "60.1",
      TABLE_PER: "57",
      PRICE_PER_CTS: "13610.69",
      TOTAL_PRICE: "27765.8",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201634",
      VIDEO: "",
    },
    {
      STOCK_ID: 201621,
      SHAPE: "ROUND",
      CARAT: "2.08",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "8.1",
      LENGTH: "8.15",
      DEPTH: "5.1",
      DEPTH_PER: "62.7",
      TABLE_PER: "56",
      PRICE_PER_CTS: "11749.11",
      TOTAL_PRICE: "24438.16",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201621",
      VIDEO: "",
    },
    {
      STOCK_ID: 201562,
      SHAPE: "ROUND",
      CARAT: "2.52",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.85",
      LENGTH: "8.87",
      DEPTH: "5.34",
      DEPTH_PER: "60.3",
      TABLE_PER: "60",
      PRICE_PER_CTS: "13792.1",
      TOTAL_PRICE: "34756.09",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201562",
      VIDEO: "",
    },
    {
      STOCK_ID: 201561,
      SHAPE: "ROUND",
      CARAT: "2.53",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.68",
      LENGTH: "8.7",
      DEPTH: "5.41",
      DEPTH_PER: "62.2",
      TABLE_PER: "57",
      PRICE_PER_CTS: "14164.24",
      TOTAL_PRICE: "35835.52",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201561",
      VIDEO: "",
    },
    {
      STOCK_ID: 201557,
      SHAPE: "ROUND",
      CARAT: "2.57",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "8.8",
      LENGTH: "8.84",
      DEPTH: "5.34",
      DEPTH_PER: "60.6",
      TABLE_PER: "59",
      PRICE_PER_CTS: "15633.24",
      TOTAL_PRICE: "40177.43",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201557",
      VIDEO: "",
    },
    {
      STOCK_ID: 201525,
      SHAPE: "ROUND",
      CARAT: "3.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "9.21",
      LENGTH: "9.28",
      DEPTH: "5.69",
      DEPTH_PER: "61.5",
      TABLE_PER: "59",
      PRICE_PER_CTS: "21126",
      TOTAL_PRICE: "63589.26",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201525",
      VIDEO: "",
    },
    {
      STOCK_ID: 201500,
      SHAPE: "ROUND",
      CARAT: "3.52",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "9.62",
      LENGTH: "9.68",
      DEPTH: "6.05",
      DEPTH_PER: "62.7",
      TABLE_PER: "57",
      PRICE_PER_CTS: "16599",
      TOTAL_PRICE: "58428.48",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201500",
      VIDEO: "",
    },
    {
      STOCK_ID: 201499,
      SHAPE: "ROUND",
      CARAT: "3.82",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "9.91",
      LENGTH: "9.97",
      DEPTH: "6.24",
      DEPTH_PER: "62.7",
      TABLE_PER: "56",
      PRICE_PER_CTS: "23842.2",
      TOTAL_PRICE: "91077.2",
      SHADE: "none",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "OTHER",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=201499",
      VIDEO: "",
    },
    {
      STOCK_ID: 205281,
      SHAPE: "ROUND",
      CARAT: "4.01",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "MED",
      LAB: "GIA",
      WIDTH: "10.13",
      LENGTH: "10.06",
      DEPTH: "6.33",
      DEPTH_PER: "62.7",
      TABLE_PER: "58",
      PRICE_PER_CTS: "64195.86",
      TOTAL_PRICE: "257425.39",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205281",
      VIDEO: "",
    },
    {
      STOCK_ID: 195316,
      SHAPE: "ROUND",
      CARAT: "4.31",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "10.31",
      LENGTH: "10.39",
      DEPTH: "6.45",
      DEPTH_PER: "62.3",
      TABLE_PER: "60",
      PRICE_PER_CTS: "23838.58",
      TOTAL_PRICE: "102744.27",
      SHADE: "",
      MILKY: "NO MILKY",
      EYE_CLEAN: "YES",
      COUNTRY: "USA",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=195316",
      VIDEO: "",
    },
    {
      STOCK_ID: 205280,
      SHAPE: "ROUND",
      CARAT: "5.07",
      COLOR: "D",
      CLARITY: "VS2",
      CUT: "EX",
      POLISH: "EX",
      SYMMETRY: "EX",
      FLUORESCENCE: "NON",
      LAB: "GIA",
      WIDTH: "11.09",
      LENGTH: "11.02",
      DEPTH: "6.76",
      DEPTH_PER: "61.1",
      TABLE_PER: "57",
      PRICE_PER_CTS: "209144",
      TOTAL_PRICE: "1060360.08",
      SHADE: "none",
      MILKY: "",
      EYE_CLEAN: "",
      COUNTRY: "HONGKONG",
      IMAGE: "",
      CERTIFICATE_LINK: "https://www.gia.edu/report-check?reportno=205280",
      VIDEO: "",
    },
  ],
};

export const shapesfilters = [
  {
    label: "Round",
    value: "Round",
    desc: "Maximizes light return from the top of the stone",
    img: "../../shape/round.webp?v=20230609",
  },
  {
    label: "Princess",
    value: "Princess",
    desc: "Maximum brilliance in an exquisite square form",
    img: "../../shape/princess.webp?v=20230609",
  },
  {
    label: "Emerald",
    value: "Emerald",
    desc: "Long lines create an elegant and sophisticated look",
    img: "../../shape/emerald.webp?v=20230609",
  },
  {
    label: "Asscher",
    value: "Asscher",
    desc: "Vintage-style square shape with cropped corners",
    img: "../../shape/asscher.webp?v=20230609",
  },
  {
    label: "Oval",
    value: "Oval",
    desc: "Elongated shape accentuates the length of the finger",
    img: "../../shape/oval.webp?v=20230609",
  },
  {
    label: "Radiant",
    value: "Radiant",
    desc: "Brilliance combined with non-traditional cropped corners",
    img: "../../shape/radiant.webp?v=20230609",
  },
  {
    label: "Pear",
    value: "Pear",
    desc: "Tradition meets brilliance in unique ‘water drop’ shape",
    img: "../../shape/pear.webp?v=20230609",
  },
  {
    label: "Heart",
    value: "Heart",
    desc: "Features a distinctive cleft at the top and superior brilliance",
    img: "../../shape/heart.webp?v=20230609",
  },
  {
    label: "Marquise",
    value: "Marquise",
    desc: "Long, narrow surface makes it appear larger than life",
    img: "../../shape/marquise.webp?v=20230609",
  },
  {
    label: "Cushion",
    value: "Cushion",
    desc: "Antique cut with 58 facets and rounded corners",
    img: "../../shape/cushion.webp?v=20230609",
  },
];

export const colorsfilters = [
  {
    label: "D",
    value: "D",
  },
  {
    label: "E",
    value: "E",
  },
  {
    label: "F",
    value: "F",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "H",
    value: "H",
  },
  {
    label: "I",
    value: "I",
  },
  {
    label: "J",
    value: "J",
  },
  {
    label: "K",
    value: "K",
  },
  {
    label: "L",
    value: "L",
  },
  {
    label: "M",
    value: "M",
  },
  {
    label: "N",
    value: "N",
  },
  {
    label: "O-P",
    value: "OP",
  },
  {
    label: "Q-R",
    value: "QR",
  },
  {
    label: "S-T",
    value: "ST",
  },
  {
    label: "U-V",
    value: "UV",
  },
  {
    label: "W-X",
    value: "WX",
  },
  {
    label: "Y-Z",
    value: "YZ",
  },
];

export const clarityfilters = [
  {
    label: "FL",
    value: "FL",
  },
  {
    label: "IF",
    value: "IF",
  },
  {
    label: "VVS1",
    value: "VVS1",
  },
  {
    label: "VVS2",
    value: "VVS2",
  },
  {
    label: "VS1",
    value: "VS1",
  },
  {
    label: "VS2",
    value: "VS2",
  },
  {
    label: "SI1",
    value: "SI1",
  },
  {
    label: "SI2",
    value: "SI2",
  },
];

export const idealcutfilters = [
  {
    label: "3EX",
    value: "3EX",
    preselect: {
      cut: ["ID", "EX"],
      polish:["EX"],
      symmetry:["EX"],
      fluorescence:[]
    }
  },{
    label: "3EX NON",
    value: "3EX NON",
    preselect: {
      cut: ["ID", "EX"],
      polish:["EX"],
      symmetry:["EX"],
      fluorescence:["NON"]
    }
  }
];

export const cutsfilters = [
  {
    label: "Ideal",
    value: "ID",
  },
  {
    label: "Excellent",
    value: "EX",
  },
  {
    label: "Vr Good",
    value: "VG",
  },
  {
    label: "Good",
    value: "GD",
  },
  {
    label: "Fair",
    value: "FR",
  },
  {
    label: "Poor",
    value: "PR",
  },
];

export const polishfilters = [
  {
    label: "Excellent",
    value: "EX",
  },
  {
    label: "Vr Good",
    value: "VG",
  },
  {
    label: "Good",
    value: "GD",
  },
  {
    label: "Fair",
    value: "FR",
  },
  {
    label: "Poor",
    value: "PR",
  },
];

export const symmetryfilters = [
  {
    label: "Excellent",
    value: "EX",
  },
  {
    label: "Vr Good",
    value: "VG",
  },
  {
    label: "Good",
    value: "GD",
  },
  {
    label: "Fair",
    value: "FR",
  },
  {
    label: "Poor",
    value: "PR",
  },
];

export const fluorescencefilters = [
  {
    label: "None",
    value: "NON",
  },
  {
    label: "Faint",
    value: "FNT",
  },
  {
    label: "Medium",
    value: "MED",
  },
  {
    label: "Strong",
    value: "STG",
  },
  {
    label: "Vr Strong",
    value: "VSTGR",
  },
];

export const diamondtypefilters = [
  {
    label: "Natural Diamond",
    url: "natural-diamond",
    value: "naturaldiamond",
    desc:"Use our diamond search feature to find GIA-graded, conflict-free loose diamonds of the highest quality. Browse thousands of options and use the filters to narrow down the selection by carat, cut, colour, clarity, shape and price. Still not sure which gemstone to invest on? Our diamond buying guide will help you choose the best match."
  },
  {
    label: "Lab Diamond",
    url: "lab-grown-diamond",
    value: "labgrowndiamond",
    desc:"Every occasion deserves its tribute. Whether you’re looking for the perfect accessory, or a showstopping center stone, our lab grown diamonds are a high-quality, affordable alternative."
  },
];

export const diamondlabfilters=[
  {
    label: "GIA",
    value: "GIA",
  },{
    label: "IGI",
    value: "IGI",
  },{
    label: "HRD",
    value: "HRD",
  },{
    label: "AGS",
    value: "AGS",
  },{
    label: "GCAL",
    value: "GCAL",
  }
];