import React, { useEffect, useState } from 'react';
import "./product360.css";
import classNames from "classnames";

function Product360({ images, skuid, selectedPanelSetting, shape }) {
    const [showIdx, setShowIdx] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const getImageIndex = (image) => {
        //"PO1D232_yellow_oval_360_10.png"
        var strremovearr = [
            skuid,
            selectedPanelSetting?.color?.toLowerCase(),
            shape?.toLowerCase(),
            "360"
        ];
        var strremove = strremovearr.join("_") + "_";
        var str = image?.image_name?.replace(strremove, "");
        str = str?.split(".")[0];
        return parseInt(str);
    }

    var selectedImages = images?.filter((item) => {
        var color = null;
        if (selectedPanelSetting?.color?.toLowerCase() === 'platinum' || selectedPanelSetting?.color?.toLowerCase() === 'silver') {
            color = 'white';
        } else {
            color = selectedPanelSetting?.color?.toLowerCase();
        }
        return item.image_name.includes(skuid)
            && item.image_name.includes(color)
            && (!shape||item.image_name.includes(shape?.toLowerCase()));
    }).sort((a, b) => getImageIndex(a) < getImageIndex(b) ? 1 : -1);

    useEffect(() => {
        // console.log(selectedPanelSetting);
        // console.log(shape);
        // Define the interval function
        const intervalId = setInterval(() => {
            if (!isMouseDown) {
                var show = showIdx;
                if (show === selectedImages.length - 1) setShowIdx(1);
                else setShowIdx(show + 1);
            }
            // setShowIdx(show + 1);
        }, 100); // Set the interval to 1000 milliseconds (1 second)
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [showIdx, selectedImages, isMouseDown]); // Empty dependency array ensures the effect runs only once after the initial render

    const handleMouseMove = (event) => {
        if (isMouseDown) {
            const touch = event?.touches ? event?.touches[0] : event;
            const deltaX = touch.clientX - mousePosition.x;
            const threshold = 10; // Adjust this value as needed to control sensitivity

            if (Math.abs(deltaX) > threshold) {
                var show = showIdx;

                if (deltaX < 0) {
                    if (show <= 0) setShowIdx(selectedImages.length - 1);
                    else setShowIdx(show - 1);
                } else {
                    if (show === selectedImages.length - 1) setShowIdx(0);
                    else setShowIdx(show + 1);
                }

                // Update the mouse position
                setMousePosition({ x: touch.clientX, y: touch.clientY });
            }
        }
    };

    const handleMouseDown = (event) => {
        setIsMouseDown(true);
        const touch = event?.touches ? event?.touches[0] : event;

        setMousePosition({ x: touch.clientX, y: touch.clientY });
    };

    const handleMouseUp = () => {
        // Remove event listener for mouse move when mouse is released
        setIsMouseDown(false);
    };

    return (
        <div className="image360box"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
        >
            {
                selectedImages?.map((image, idx) => (
                    <img alt={image.image_name} src={image.url} className={
                        classNames({
                            "img-fluid": true,
                            "active": idx === showIdx
                        })
                    } />
                ))
            }
        </div>

    )
}

export default Product360