export const companyinfo = {
    name:"POINTERS JEWELLERS (M) SDN. BHD.",
    address:[
        "C3, JALAN SS 15/4D,",
        "SS 15, 47500",
        "SUBANG JAYA, SELANGOR, MALAYSIA."
    ],
    tel:"+603 5633 6493",
    fax:"+603 5635 9791",
    email:"general@pointers.email",
    operating_hours:[
        "Monday- Saturday: 10:15am-6:30pm",
        "Sunday: 10:30am-6pm"
    ],
    fb:"https://www.facebook.com/pointersjewellers/",
    ig:"https://www.instagram.com/explore/locations/847156528729706/pointers-jewellers"
};