import React from 'react';
import "./afterSalesServices.css";
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import MetaInformation from '../../components/metainformation/MetaInformation';

function AfterSalesServices() {
    const headerinfo = {
        title: "After Sales Services | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "After Sales Services | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "After Sales Services"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">After Sales Services</h1>
                    <h3 className=" text-uppercase">
                        Polish
                    </h3>
                    <p>Giving back a jewel’s sparking appearance if it has become dull or has superficial scratches.</p>
                    <p className="mb-0"><strong>Performed in our Pointers Jewellers by a Pointers specialist, this service includes the following operations:</strong></p>
                    <ul>
                        <li>Jewellery is diagnosed.</li>
                        <li>Jewellery is polished, its sparkle is enhanced using brushes saturated with three different abrasive pastes, until a dazzling surface is obtained.</li>
                        <li>Jewellery is cleaned, it is immersed in an ultrasonic tank filled with lukewarm soapy water.</li>
                    </ul>
                    <h3 className=" text-uppercase mt-4">
                        Resize
                    </h3>
                    <p>Increase or reduce the size of bracelets, necklaces or rings.</p>
                    <p className="mb-0"><strong>Performed in our Pointers Jewellers by a Pointers specialist, this service includes the following operations:</strong></p>
                    <ul>
                        <li>Jewellery is diagnosed.</li>
                        <li>The adjustment of size.</li>
                        <li>Jewellery is polished, its sparkle is enhanced using brushes saturated with three different abrasive pastes, until a dazzling surface is obtained.</li>
                        <li>Jewellery is cleaned, it is immersed in an ultrasonic tank filled with lukewarm soapy water.</li>
                    </ul>

                    <h3 className=" text-uppercase mt-4">
                        ENGRAVE
                    </h3>
                    <p>Engrave your jewellery with name, phrase, date, symbol or any design on your own creation.</p>
                    <p className="mb-0"><strong>Performed in our Pointers Jewellers by a Pointers specialist, this service includes the following operations:</strong></p>
                    <ul>
                        <li>Jewellery is diagnosed.</li>
                        <li>Engraving on jewellery is performed using laser.</li>
                        <li>Jewellery is cleaned, it is immersed in an ultrasonic tank filled with lukewarm soapy water.</li>
                        <li>*An engraving can be removed or replaced by another.</li>
                    </ul>

                    <h3 className=" text-uppercase mt-4">
                        Transform
                    </h3>
                    <p>You can match multiple pieces of Pointers stones and transform them into a new piece of jewellery of your own design. For example, a ring. </p>
                    <p>Performed in our Pointers Jewellers by a Pointers specialist, please contact our General Line (+603 5633 6493) for assistance.</p>
                    <p>* Deep scratches cannot be removed.</p>
                </div>
            </div>
        </>
    )
}

export default AfterSalesServices