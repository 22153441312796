import React, { useCallback, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import classNames from "classnames";
import "./cardProduct.css";
import Slider from 'react-slick';


function CardProduct({ item }) {
    const [color, setColor] = useState();
    const [imageTop, setImageTop] = useState();
    const [imageSide, setImageSide] = useState();

    const productslidersettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: "",
        prevArrow: "",
        autoplay: false,
        customPaging: function (i) {
            return (
                <div className="slick-bullet"></div>
            );
        },
    };

    const handleColorOnClick = useCallback((clickcolor) => {
        setColor(clickcolor);
        var curImageTop = item?.images?.filter((image) => {
            return image.includes(clickcolor) && image.includes("_top");
        });
        var curImageSide = item?.images?.filter((image) => {
            return image.includes(clickcolor) && image.includes("_side");
        });

        setImageTop(curImageTop[0]);
        setImageSide(curImageSide[0]);
    }, [setColor, setImageTop, setImageSide, item]);

    useEffect(() => {
        var curColor = null;
        if(item?.colors?.filter((item_color)=>{
            return item_color.toLowerCase()==="white";
        })){
            curColor = "white";
        }else if(item?.colors?.filter((item_color)=>{
            return item_color.toLowerCase()==="rose";
        })){
            curColor = "rose";
        }else if(item?.colors?.filter((item_color)=>{
            return item_color.toLowerCase()==="yellow";
        })){
            curColor = "yellow";
        }else if(item?.colors?.filter((item_color)=>{
            return item_color.toLowerCase()==="silver";
        })){
            curColor = "silver";
        }else if(item?.colors?.filter((item_color)=>{
            return item_color.toLowerCase()==="platinum";
        })){
            curColor = "platinum";
        }

        // if (item.variant?.gold.filter((gold) => {
        //     return gold?.color_details?.filter((color) => {
        //         return color?.color?.toLowerCase() === "white";
        //     })?.length > 0 ? true : false;
        // })) {
        //     curColor = "white";
        // } else if (item.variant?.gold.filter((gold) => {
        //     return gold?.color_details?.filter((color) => {
        //         return color?.color?.toLowerCase() === "rose";
        //     })?.length > 0 ? true : false;
        // })) {
        //     curColor = "rose";
        // } else if (item.variant?.gold.filter((gold) => {
        //     return gold?.color_details?.filter((color) => {
        //         return color?.color?.toLowerCase() === "yellow";
        //     })?.length > 0 ? true : false;
        // })) {
        //     curColor = "yellow";
        // } else if (item.variant?.silver.length > 0) {
        //     curColor = "silver";
        // } else if (item.variant?.platinum.length > 0) {
        //     curColor = "platinum";
        // }
        handleColorOnClick(curColor);
    }, [item, handleColorOnClick]);

    // const renderDisplayPrice = (item) => {
    //     var min_price = 9999999999999999999;
    //     var flag = 0;
    //     var sorted_arr = item?.variant?.gold.sort((a, b) => a.price - b.price);
    //     if (sorted_arr[0]?.price && sorted_arr[0]?.price <= min_price) {
    //         min_price = sorted_arr[0]?.price;
    //         flag = 1;
    //     }

    //     sorted_arr = item?.variant?.silver.sort((a, b) => a.price - b.price);
    //     if (sorted_arr[0]?.price && sorted_arr[0]?.price <= min_price) {
    //         min_price = sorted_arr[0]?.price;
    //         flag = 1;
    //     }
    //     sorted_arr = item?.variant?.platinum.sort((a, b) => a.price - b.price);
    //     if (sorted_arr[0]?.price && sorted_arr[0]?.price <= min_price) {
    //         min_price = sorted_arr[0]?.price;
    //         flag = 1;
    //     }

    //     return flag ? "RM" + min_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "";
    // };

    const renderDisplayPrice = () => {
        return item?.price ? "From RM" + parseFloat(item?.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : "";
    }

    return (
        <Card className="card-product">
            {
                item?.classification?(<div className="classification-badge">{item?.classification}</div>):""
            }
            
            <a href={"/product/" + item.slugId}>
                <div className="img-product">
                    <Slider  {...productslidersettings}>
                        <Card.Img variant="top" src={imageTop} />
                        <Card.Img variant="top" src={imageSide} />
                    </Slider>
                </div>
            </a>
            <Card.Body className="text-center desc">
                <ul className="list-metal-circles">
                    {
                        item?.colors?.map((item_color)=>(
                            <li className={item_color?.toLowerCase() +" " + classNames({
                                active: color === item_color?.toLowerCase(),
                            })} onClick={e => handleColorOnClick(item_color?.toLowerCase())}></li>
                        ))
                    }
                    {/* {
                        item.variant?.gold.filter((gold) => {
                            return gold?.color?.toLowerCase() === "white"
                        }) ? <li className={"white " + classNames({
                            active: color === "white",
                        })} onClick={e => handleColorOnClick("white")}></li> : ""
                    }
                    {
                        item.variant?.gold.filter((gold) => {
                            return gold?.color?.toLowerCase() === "rose"
                        }) ? <li className={"rose " + classNames({
                            active: color === "rose",
                        })} onClick={e => handleColorOnClick("rose")}></li> : ""
                    }
                    {
                        item.variant?.gold.filter((gold) => {
                            return gold?.color?.toLowerCase() === "yellow"
                        }) ? <li className={"yellow " + classNames({
                            active: color === "yellow",
                        })} onClick={e => handleColorOnClick("yellow")}></li> : ""
                    } */}
                    {/* {
                        item.variant?.silver.length > 0 ? <li className={"silver " + classNames({
                            active: color === "silver",
                        })} onClick={e => handleColorOnClick("silver")}></li> : ""
                    }
                    {
                        item.variant?.platinum.length > 0 ? <li className={"platinum " + classNames({
                            active: color === "platinum",
                        })} onClick={e => handleColorOnClick("platinum")}></li> : ""
                    } */}
                </ul>

                <a href={"/product/" + item.slugId}>
                    <span className="title1">{item.name}</span>
                    <div className="text-price">{renderDisplayPrice(item)}</div>
                </a>
            </Card.Body>
        </Card >
    )
}

export default CardProduct