import React from 'react';
import { Helmet } from 'react-helmet';

function MetaInformation({ headerinfo }) {
    // var headerinfo = {
    //     title: "Page Title",
    //     description: "Meta Description",
    //     keyword: "Meta Keyword",
    //     author: "",
    //     og: {
    //         title: "Page Title",
    //         description: "Meta Description",
    //         
            // image: window.location.origin+"/pointers/pointers_logo_social.png",
    //         url: window.location.href
    //     }
    // };
    return (
        <Helmet>
            <title>{headerinfo?.title}</title>
            {headerinfo?.description ? <meta name="description" content={headerinfo?.description} /> : ""}
            {headerinfo?.keyword ? <meta name="keyword" content={headerinfo?.keyword} /> : ""}
            {headerinfo?.author ? <meta name="author" content={headerinfo?.author} /> : ""}
            {headerinfo && Object.keys(headerinfo?.og).map((key, index) => (
                headerinfo?.og[key] ? (<meta property={"og:" + key} content={headerinfo?.og[key]} />) : ""
            ))}
        </Helmet>
    )
}

export default MetaInformation