import React from 'react';
import "./tnc.css";
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import MetaInformation from '../../components/metainformation/MetaInformation';

function Tnc() {
    const headerinfo = {
        title: "Terms & Conditions | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Terms & Conditions | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Terms & Conditions"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Terms & Conditions</h1>
                    <h4 className=" text-uppercase">General</h4>
                    <p>These Terms and Conditions shall govern all transactions with Pointers Jewellers (M) Sdn Bhd, its subsidiaries and affiliates [hereinafter referred to as “Seller”] that take place in its physical store(s) [hereinafter referred to as “Store”] or on this website [hereinafter referred to as “Site”].</p>
                    <p>These Terms and Conditions shall constitute the entire agreement between the Seller and you [hereinafter referred to as “Buyer”] for the sale and supply of the goods by the Seller which includes but not limited to adornments and/or jewellery related accessories that consist and/or contain precious materials including but not limited to silver, gold, platinum, gemstones and pearls [hereinafter referred to as “Product”].</p>
                    <h4 className=" text-uppercase mt-4">Placing an Order</h4>
                    <p>An order with the Seller includes any orders communicated verbally in the Store or placed via telephone conversations that include any messages sent via Whatsapp wherein the same will entail the sending of picture/pictures of the desired Product along with written text expressly stating the Buyer’s intention to purchase the said Product [hereinafter referred to as “Order”].</p>
                    <p>By placing an Order with the Seller, the Buyer hereby acknowledges that they will be bound by these Terms and Conditions appearing hereunder wherein the same constitutes a binding contract between the Seller and Buyer pursuant to Section 5 of the Sales of Goods Act 1957 [hereinafter referred to as “Act”] unless the said Act is amended otherwise.</p>
                    <p>It is hereby expressly agreed that any Order does not in any way guarantee availability of the Product wherein the Seller reserves their right to decline the said Order where the Seller does not have the Product in stock, even if the Buyer has already placed the said Order. The Seller reserves their right to reject or refuse any order in their sole discretion</p>
                    <p>Whilst making the Order, the Seller will issue the Buyer an Order Note stating the estimated price of the Product to the Buyer [hereinafter referred to as “Purchase Price”] and the minimum deposit to be paid [hereinafter referred to as “Deposit”]. It is hereby agreed that the said Order will only be binding upon the Seller upon payment of the said Deposit.</p>
                    <p>For the avoidance of doubt, the Purchase Price stated in the Seller’s Order Note is subject to variation and revision at any time wherein such variation and revision will be communicated to the Buyer.</p>
                    <p>Thereafter, only upon confirmation by the Buyer, the Purchase Price will be invoiced to the Buyer will be based upon the prevailing prices and in such currencies as agreed in writing between the Parties.</p>
                    <h4 className=" text-uppercase mt-4">Collection</h4>
                    <p>It shall be strictly the Buyer’s responsibility to ensure that the Buyer or a trustworthy person authorised by the Buyer to collect the Product [hereinafter referred to as “Authorised Person”] wherein the Seller shall be entitled to assume that any person taking delivery of the Product at the address specified by the Buyer is an Authorised Person.</p>
                    <p>The Buyer or Authorised Person must sign for receipt of the Product and such act, without reservation, shall amount to prima facie evidence that the Product have been received in good condition and accepted by the Buyer pursuant to Section 42 of the Act unless the said Act is amended otherwise.</p>
                    <p>Once the Order is made and where the Buyer or  Authorised Person wrongfully neglects or refuses to accept and pay for the goods, the Seller reserves their right and remedies to pursue an action against the Buyer pursuant to Section 56 of the Sales of Goods Act 1957 unless the said Act is amended otherwise.</p>
                    <h4 className=" text-uppercase mt-4">Ownership/Passing of Risk</h4>
                    <p>The Buyer must inspect the Product upon delivery. Any claims that the Product is/the Products are not as ordered or is/are faulty must be made to the Seller in writing within five (5) working days of delivery and must be supported by a copy of the orginal invoice.</p>
                    <p>In the event that a claim is not made within the aforementioned time frame, the Product is deemed accepted by the Buyer and cannot be returned and/or refunded  later. Please refer to the Seller’s Return &amp; Refund Policy appearing hereunder.</p>
                    <p>It is hereby expressly agreed that the ownership and risk in the property in the Product shall pass to the Buyer or its Authorised Person upon collection of the Product pursuant to Section 19(1) and 26 of the Act unless the said Act is amended otherwise.</p>
                    <p>Ownership shall not pass to the Buyer or its Authorised Person unless and until such time the Seller has received the full the Purchase Price in respect of those Product wherein the Seller reserves their right to hold on to or otherwise retain the Product in the event any payment or other sums remains due and owing by the Buyer.</p>
                    <p>Any property of the Buyer under the Seller’s custody or control will be entirely at the Buyer’s risk as regards any loss or damage caused to the property for any reason or by any person.</p>
                    <h4 className=" text-uppercase mt-4">Warranties</h4>
                    <p>SELLER MAKES NO WARRANTY (IMPLIED OR EXPRESS) WHATSOEVER WITH RESPECT TO THE PRODUCT, INCLUDING ANY WARRANTY OF MERCHANTABILITY, WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, OR WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY, WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE. SELLER FURTHER MAKES NO WARRANTY (IMPLIED OR EXPRESS) WHATSOEVER WITH RESPECT TO THE ACCURACY, TIMELINESS, PERFORMANCE, COMPLETENESS OR SUTIABILITY OF THE INFORMATION AND MATERIALS FOUND OR OFFERED ON THIS WEBSITE FOR ANY PARTICULAR PURPOSE.</p>
                    <h4 className=" text-uppercase mt-4">Limitation of Liability</h4>
                    <p>Except as expressly provided to the contrary in writing by the Seller, any description of Product provided on or in connection therewith by the Seller are provided on an “as is” basis without warranties of any kind, either express or implied.</p>
                    <p>The Seller shall not be liable to any party for any direct, indirect, special, or consequential damages or any damages whatsoever resulting from loss of profits, loss of business, depletion of goodwill or other similar losses, or any other special, indirect consequential or pure economic loss, cost damages, charges or expenses or fall in the value of any precious metal or currency which arise out of, or in connection with the Product or of any use of the Product therefrom.</p>
                    <h4 className=" text-uppercase mt-4" id="indemnity">Indemnity</h4>
                    <p>The Buyer hereby agrees to indemnify, defend and hold harmless the Seller from and against any and all claims actions or demands, liabilities, losses, expenses, damages and costs, including without limitation reasonable legal and accounting fees, in any way arising from, related to or in connection with the Product or of any use of the Product therefrom which shall include but is not limited to any third-party claims.</p>
                    <h4 className=" text-uppercase mt-4" id="force_majeure">Force Majeure</h4>
                    <p>Should any Force Majeure event occurs which includes but is not limited to such circumstances beyond the control of the Seller including but not limited to strikes, pandemic, lockouts, rebellions, fire, act of God, shortage of materials or Government decrees or proclamations, the Seller shall have the right but no obligation to deliver the Product while such circumstances continue wherein the Seller may, at its option, cancel, rescind or terminate all or any part of the transaction of the Product or keep the transaction of the Product in abeyance until such circumstances have ceased.</p>
                    <h4 className=" text-uppercase mt-4" id="return_refund">Return &amp; Refund Policy</h4>
                    <p>The Seller maintains refund policy solely at its exclusive discretion which shall be subject to reappraisal and the actual amount refunded. In the event that the Product is/Products are specifically modified to suit the Buyer, such Product is/Products are not returnable and/or exchangeable after alteration.</p>
                    <p>Address :&nbsp;C3, Jalan SS 15/4D, 47500 Subang Jaya, Selangor Darul Ehsan, Malaysia</p>
                    <h4 className=" text-uppercase mt-4">Site Content</h4>
                    <p>All images on this Site remain the property of the Seller; expressed permission is required for any person(s) wishing to use them externally for whatever purpose.</p>

                    <p>The Seller shall make every attempt to ensure that their online catalogue and/or listing of any Product on the Site or on such other media are accurate and complete as possible.</p>

                    <p>As imaging of each computer monitor varies from one another, the Product depicted therein may vary in colour and/or appear larger or smaller than their actual size from their photographs. Therefore, to avoid any misrepresentations, the Buyers are encouraged and welcomed to attend the Store to enquire further or view the Product in greater detail.</p>

                    <p>On the Site, the Seller may provide the measurement of Product based on manufacturing specifications. However, slight tolerances may be accounted for based on finishing during the manufacturing.</p>

                    <h4 className=" text-uppercase mt-4">Intellectual Property</h4>
                    <p>The Site contains material, such as software, text, graphics, images, designs, sound recordings, audio/visual works, and other material provided by or on behalf of the Seller [collectively referred to as the “Content”].</p>

                    <p>The Content may be owned by the Seller or authorised third parties. Any unauthorized use of the Content may violate copyright, trademark, and other laws. The use or posting of the Content on any other website or in a networked computer environment for any purpose is expressly prohibited.</p>

                    <p>The trademarks, service marks, and logos of the Seller used and displayed on the Site are registered and unregistered trademarks or service marks of Pointers. Nothing on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the aforementioned marks without our prior written permission specific for each such use.</p>

                    <p>No Content shall be retransmitted or reproduced in any other form whether electronically or printed without the Seller’s express, written consent for each and every instance.</p>

                    <h4 className=" text-uppercase mt-4">Personal Data</h4>
                    <p>The Buyer acknowledges that the Seller may collect, use and retain the Buyer’s personal data pursuant to the Personal Data Protection Act 2010. In such instance, the Buyer agrees that the Seller may process or use the personal data pursuant to its Privacy Policy for Usage of Personal Data. For further information with regards to personal data, kindly refer to <a href={`${process.env.REACT_APP_URL}/privacy-policy`} className="text-underline">{process.env.REACT_APP_URL}/privacy-policy</a>.</p>
                    <h4 className=" text-uppercase mt-4">Anti Money Laundering &amp; Terrorism</h4>
                    <p>The Seller is aware and acknowledges that in conformance with the requirements of Anti-Money Laundering and Anti-Terrorism Financing Act 2001 for dealers in precious metals and precious stones wherein the Seller shall report any transactions that are suspicious and involving proceeds of any unlawful activity to the Financial Intelligence Unit in Bank Negara Malaysia without alerting the Buyer on the matter. </p>
                    <h4 className=" text-uppercase mt-4">Miscellaneous</h4>
                    <p>These Terms and Conditions constitute the entire understanding of the Seller and Buyer relating to the Product and the transaction thereof. Unless otherwise expressly agreed in writing, these Terms and Conditions shall prevail over any other terms of the transaction, whether oral or written (including but not limited to any invoice, purchase order or delivery order), to the extent of any inconsistency or conflict between these Terms and Conditions and such other terms.</p>

                    <p>No other Terms and Conditions will apply. These Terms and Conditions cannot be varied unless Seller hereby agrees to vary it in writing.</p>

                    <p>If any one or more of the provisions or part thereof contained in these Terms and Conditions is or becomes invalid or unenforceable due to any reason, this shall not in any way affect or impair the validity or enforceability of the remaining provisions hereof.</p>

                    <p>The headings in these terms and conditions are for convenience only and shall not affect their interpretation.</p>

                    <p>Any reference to any provision of a statute shall be construed as a reference to that provision as amended, re-enacted or extended at the relevant time.</p>

                    <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia and shall be subject to the exclusive jurisdiction of Malaysia.</p>

                </div>
            </div>
        </>
    )
}

export default Tnc