import React, { useCallback, useEffect, useRef, useState } from "react";
import "./pointersCheckout.css";
import HeaderBreadcrumb from "../../components/headerbreadcrumb/HeaderBreadcrumb";
import { Card, Col, Row, Button, Form, Alert } from "react-bootstrap";
import { cutsfilters } from "../../json/diamondfilters";
import { paymentoptionlist } from "../../json/checkoutinfo";
import jwt_decode from "jwt-decode";
import CreateAddressButton from "../../components/createaddressbutton/CreateAddressButton";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import MetaInformation from "../../components/metainformation/MetaInformation";
// import { eghlrequestinfo } from '../../json/dummydata';

function PointersCheckout() {
  const headerinfo = {
    title: "Checkout | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
      title: "Checkout | Pointers Jewellers",
      description: "",
      
            image: window.location.origin+"/pointers/pointers_logo_social.png",
      url: window.location.href
    }
  };
  const breadcrumbs = [{
    url: "/",
    label: "Checkout"
  }];
  // const [isDiffAddress, setIsDiffAddress] = useState(false);\
  const { axiosJWT, getAccessToken } = useAuthorizationToken();

  const shouldRunEffect = useRef(true);
  // const shouldRunEffect = useRef(true);
  const [payCounter, isPayCounter] = useState(false);
  const [addresses, setAddresses] = useState();
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("DD");
  const [addressAlert, setAddressAlert] = useState();
  const [carts, setCarts] = useState();
  const [cartOthers, setCartOthers] = useState();
  const [cartAlert, setCartAlert] = useState(null);
  const [subtotal, setSubtotal] = useState(0.0);
  // const [sst, setSst] = useState(0.00);
  const [total, setTotal] = useState(0.0);
  const [eghlRequest, setEghlRequest] = useState();
  const [allAvailable, isAllAvailable] = useState(true);
  const [remark, setRemark] = useState();

  const handleOnSubmitError = (err) => {
    if (err.response) {
      // if (err.response.data?.code === '401') {

      // } else {
      setCartAlert(err.response.data);
      // }
    }
  };

  const handleTotalCalculation = (curCart) => {
    var curSubtotal = 0.0;
    curCart.forEach((cart, i) => {
      curSubtotal += cart.price * cart.quantity;
      if (parseFloat(cart?.diamond?.MYR_PRICE) > 0.0) {
        curSubtotal += parseFloat(cart?.diamond?.MYR_PRICE);
      }
    });
    setSubtotal(curSubtotal);
    // var curSst = curSubtotal ? (curSubtotal * 0.06) : 0.00;
    // setSst(curSst);
    // var curTotal = curSubtotal + curSst;
    var curTotal = curSubtotal;
    setTotal(curTotal);
  };

  const fetchAddresses = useCallback(() => {
    async function fetchAddressesData() {
      try {
        const res = await axiosJWT
          .get(`${process.env.REACT_APP_API_ENDPOINT}/user/address`)
          .catch((err) => {
            // window.location.replace("/login");
            handleOnSubmitError(err);
          });
        setAddresses(res?.data?.addresses);
        if (selectedAddress === 0) {
          var default_address = res?.data?.addresses.filter((address) => {
            return address.is_default === 1;
          });
          setSelectedAddress(default_address[0]?.id ? default_address[0]?.id : res?.data?.addresses[0]?.id);
        }

      } catch (err) {
        console.log(err);
        // window.location.replace("/login");
      }
    }
    fetchAddressesData();

  }, [axiosJWT, selectedAddress]);

  const fetchCarts = useCallback(() => {
    async function fetchCartsData() {
      const res = await axiosJWT
        .get(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.cart) {
        setCarts(res?.data?.cart);
        handleTotalCalculation(res?.data?.cart);

        var unavailablecart = res?.data?.cart.filter((cart) => {
          return cart?.is_available === false;
        });
        isAllAvailable(unavailablecart?.length > 0 ? false : true);
      } else {
        setCarts(null);
        setCartAlert(res?.data);
        // window.location.replace("/login");
      }
    }
    fetchCartsData();
  }, [axiosJWT]);

  useEffect(() => {
    var accesstoken = getAccessToken();
    if (!accesstoken) {
      window.location.replace("/login");
    } else {

      var decodeToken = jwt_decode(accesstoken);
      if (decodeToken?.role === "CMS") {
        isPayCounter(true);
      }
      if (shouldRunEffect.current) {
        fetchAddresses();
        var selectedOther = JSON.parse(
          sessionStorage.getItem("_pointersothers")
        );
        if (selectedOther?.product_panel_setting_id) {
          setCartOthers(selectedOther);
          var curSubtotal = selectedOther?.price * selectedOther?.quantity;

          // var curSst = curSubtotal ? (curSubtotal * 0.06) : 0.00;
          // setSst(curSst);
          // var curTotal = curSubtotal + curSst;
          var curTotal = curSubtotal;
          setSubtotal(curSubtotal);
          setTotal(curTotal);
        } else {
          fetchCarts();
        }
        shouldRunEffect.current = false;
      }
    }
  }, [getAccessToken, fetchAddresses, fetchCarts]);

  const handlePaymentOptionOnChange = (e) => {
    setSelectedPaymentOption(e.target.value);
  };

  const handleProceedOnClick = async (e, is_pay_counter) => {

    var formdata = {
      address_id: selectedAddress,
      total_amount: total,
      user_cart_id: [],
      product_panel_setting_id: "",
      pay_counter: is_pay_counter,
      payment_method: selectedPaymentOption,
      remark:remark
    };
    if (cartOthers?.product_panel_setting_id) {
      formdata.product_panel_setting_id = cartOthers?.product_panel_setting_id
    } else {
      carts.forEach((cart) => {
        formdata.user_cart_id.push(cart.id);
      });
    }

    if (!is_pay_counter && !parseInt(selectedAddress)) {
      setAddressAlert({
        code: 500,
        message: "Warning: Please select your shipping address!",
      });
    } else {
      setAddressAlert(null);
      const res = await axiosJWT
        .post(`${process.env.REACT_APP_API_ENDPOINT}/order/create`, formdata)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.code === 200) {
        sessionStorage.removeItem("_pointersothers");
        if (res?.data?.order?.HashValue) {
          setEghlRequest(res?.data?.order);
          const form = document.getElementById("ptFrmeGHL");
          if (form) {
            form.submit();
          }
        } else if (is_pay_counter && res?.data?.order?.OrderNumber) {
          window.location.replace("/orders/" + res?.data?.order?.OrderNumber);
        }

        // navigate("/checkout/success");
        // setCarts(res?.data?.cart);
        // handleTotalCalculation(res?.data?.cart);
      }
    }
  };

  return (
    <>
      <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="pointers-checkout-content">
        <div className="container">
          <h1 className=" text-uppercase">Checkout</h1>
          {addressAlert ? (
            <Col xs="12">
              <Alert
                variant={addressAlert?.code === 200 ? "success" : "danger"}
                className="mb-3"
                onClose={(e) => setAddressAlert(null)}
                dismissible
              >
                {addressAlert?.message ? addressAlert?.message : addressAlert}
              </Alert>
            </Col>
          ) : (
            ""
          )}

          {(cartOthers) || (carts?.length > 0) ? (
            <>
              <p>Fill in the information below and confirm the order below.</p>
              <Row>
                <Col md="6" className="mb-5">
                  <h4 className=" text-uppercase">Shipping Address</h4>
                  {/* <a href="/addresses/create" target="_blank">
                    <Button
                      variant="dark"
                      size="lg"
                      className="btn-red btn-100 text-uppercase mt-3"
                    >
                      Create New Address
                    </Button>
                  </a> */}

                  <CreateAddressButton axiosJWT={axiosJWT} fetchData={fetchAddresses} setAddressAlert={setAddressAlert} />
                  <div className="text-center mt-3">OR</div>
                  <p className="text-center mt-3 mb-1">
                    Select the shipping address from Address Book:
                  </p>
                  <Form.Select
                    className="mt-1"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                  >
                    <option value="0">-- Please Select Address --</option>
                    {addresses?.map((address, index) => (
                      <option value={address.id}>
                        {address.address},&nbsp;
                        {address.postal},&nbsp;
                        {address.city},&nbsp;
                        {address.state},&nbsp;
                        {address.country}
                      </option>
                    ))}
                  </Form.Select>

                  {addresses
                    ?.filter((address) => {
                      return parseInt(address.id) === parseInt(selectedAddress);
                    })
                    .map((address, index) => (
                      <>
                        <h4 className=" text-uppercase mt-4">
                          Your Selected Shipping Address
                        </h4>
                        <Card>
                          <Card.Body>
                            <div className="address-content">
                              <strong>{address.name}</strong>
                              <div>{address.phone}</div>
                              <div>{address.address} </div>
                              <div>
                                {address.postal} {address.city}
                              </div>
                              <div>{address.state}</div>
                            </div>
                          </Card.Body>
                        </Card>
                      </>
                    ))}
                  <h4 className=" text-uppercase mt-4">
                    Order Remark
                  </h4>
                  <Form.Control as="textarea" rows={5} value={remark} onChange={e=>setRemark(e.target.value)} />

                </Col>
                <Col md="6" className="mb-5">
                  <h4 className=" text-uppercase">Your Order</h4>
                  {
                    cartOthers?.product_panel_setting_id ? (
                      <Card className="card-order-item mb-3">
                        <Card.Body>
                          <Row>
                            <Col md="3">
                              <a href={"/product/" + cartOthers.slug}>
                                <img
                                  alt="Product 1"
                                  src={cartOthers.images}
                                  className="img-fluid"
                                />
                              </a>
                            </Col>
                            <Col sm="6">
                              <a href={"/product/" + cartOthers?.slug}>
                                <strong className="">{cartOthers?.name}</strong>
                              </a>
                              <br />
                              <small>
                                <strong>
                                  Unit Price: RM
                                  {cartOthers?.price?.toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                              </small>
                              <br />
                              <small>Qty: {cartOthers.quantity}</small>
                              <br />
                              {
                                cartOthers?.weight ? (<><small>Weight: {cartOthers?.weight}</small><br /></>) : ""
                              }

                            </Col>
                            <Col md="3" className="text-right text-price">
                              <small>
                                <strong>Total:</strong>
                              </small>
                              <br />
                              <h5>
                                RM
                                {(cartOthers.price * cartOthers.quantity)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </h5>
                              <br />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ) : ""
                  }
                  {carts?.map((cart, index) => (
                    <Card className="card-order-item mb-3">
                      <Card.Body>
                        <Row>
                          <Col md="3">
                            <a href={"/product/" + cart.slug_id}>
                              <img
                                alt="Product 1"
                                src={cart.thumbnail}
                                className="img-fluid"
                              />
                            </a>
                          </Col>
                          <Col sm="6">
                            <a href={"/product/" + cart?.slug_id}>
                              <strong className="">{cart?.product_name}</strong>
                            </a>
                            <br />
                            <small>
                              <strong>
                                Unit Price: RM
                                {cart?.price?.toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </strong>
                            </small>
                            <br />
                            <small>Qty: {cart.quantity}</small>
                            <br />
                            {
                              cart?.name ? (<><small>Color: {cart?.name}</small><br /></>) : ""
                            }
                            {cart?.ring_size ? (
                              <>
                                <small>Size: {cart?.ring_size}</small>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                            {cart?.engrave_text ? (
                              <>
                                <small>Engrave Text: <font className={"engrave-" + (cart?.engrave_font ? cart?.engrave_font?.toLowerCase() : "normal")}>{cart?.engrave_text}</font></small>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                            {cart?.engrave_font ? (
                              <>
                                <small>Engrave Style: {cart?.engrave_font}</small>
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md="3" className="text-right text-price">
                            <small>
                              <strong>Total:</strong>
                            </small>
                            <br />
                            <h5>
                              RM
                              {(cart.price * cart.quantity)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h5>
                            <br />
                          </Col>
                        </Row>
                        {
                          !cart?.is_available ? (
                            <Row>
                              <Col md="3"></Col>
                              <Col xs="9">
                                <Alert
                                  variant="danger"
                                  className="mb-3"
                                >
                                  This item temporarily unavailable. Please remove it from <a href="/cart" className="text-underline">shopping cart</a> before complete the order.
                                </Alert>
                              </Col>
                            </Row>
                          ) : ""
                        }
                        {cart?.diamond?.CERTIFICATE_NO ? (
                          <>
                            <Row className="mt-3">
                              <Col md="3"></Col>
                              <Col sm="6">
                                <strong className="">
                                  {cart?.diamond?.CARAT} CARAT{" "}
                                  {cart?.diamond?.SHAPE} DIAMOND
                                </strong>
                                <br />
                                <small>
                                  {cart?.diamond?.LAB} Cert: <a href={cart?.diamond?.CERTIFICATE_LINK} target="_blank" rel="noreferrer">{cart?.diamond?.CERTIFICATE_NO}</a>
                                </small>
                                <br />
                                <small>
                                  {
                                    cutsfilters.filter((cutsfilter) => {
                                      return (
                                        cutsfilter.value === cart?.diamond?.CUT
                                      );
                                    })[0]?.label
                                  }{" "}
                                  Cut, {cart?.diamond?.COLOR} Color,
                                  {cart?.diamond?.CLARITY} Clarity
                                </small>
                                <br />
                              </Col>
                              <Col sm="3" className="text-right text-price">
                                <small>
                                  <strong>Total:</strong>
                                </small>
                                <br />
                                <h5>
                                  RM
                                  {parseFloat(cart?.diamond?.MYR_PRICE)
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </h5>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      </Card.Body>
                    </Card>
                  ))}

                  <Card className="card-amount mb-5">
                    <Card.Body>
                      <Row>
                        <Col>
                          <strong>Subtotal: </strong>
                        </Col>
                        <Col className="col-total-amount">
                          RM
                          {subtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Col>
                      </Row>
                      {/* <Row>
                                                <Col><strong>SST (6%): </strong></Col>
                                                <Col className="col-total-amount">RM{sst.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Col>
                                            </Row> */}
                      <hr />
                      <Row className="row-grandtotal">
                        <Col className="col-total-amount">
                          <h3>
                            RM
                            {total
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h3>
                        </Col>
                      </Row>
                      <hr />

                      <>
                        <strong>Payment Option:</strong>
                        <div key="inline-payment" className="mb-3">
                          {paymentoptionlist?.map((option, idx) => (
                            <Form.Check
                              inline
                              label={option?.label}
                              type="radio"
                              value={option?.value}
                              checked={
                                selectedPaymentOption === option?.value
                                  ? true
                                  : false
                              }
                              onChange={handlePaymentOptionOnChange}
                            />
                          ))}
                        </div>
                      </>

                      {
                        allAvailable ? (<Button
                          variant="dark"
                          size="lg"
                          className="btn-red btn-100 text-uppercase"
                          onClick={e => handleProceedOnClick(e, false)}
                        >Proceed Payment
                        </Button>) : ""
                      }

                      {
                        allAvailable && payCounter ? (
                          <Button
                            variant="dark"
                            size="lg"
                            className="btn-red btn-100 text-uppercase mt-5"
                            onClick={e => handleProceedOnClick(e, true)}
                          >Checkout at Store
                          </Button>
                        ) : ""
                      }
                      {cartAlert ? (
                        <Alert
                          variant="danger"
                          className="mt-3 mb-3"
                          onClose={(e) => setCartAlert(null)}
                          dismissible
                        >
                          {cartAlert?.message ? cartAlert?.message : cartAlert}
                        </Alert>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="clearfix"></div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {eghlRequest ? (
        <>
          <Form
            action={`${process.env.REACT_APP_EGHL_ENDPOINT}`}
            method="POST"
            id="ptFrmeGHL"
          >
            {Object.keys(eghlRequest).map((key, index) => (
              <input type="hidden" name={key} value={eghlRequest[key]} />
            ))}
            {/* <Button type="submit">Submit</Button> */}
          </Form>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default PointersCheckout;
