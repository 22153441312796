export default function useDiamondSteps() {
  let categoriesincludediamond = [7];
  const checkNeedDiamondStep = (category) => {
    return categoriesincludediamond.includes(category?.id);
  };

  return {
    checkNeedDiamondStep,
  };
}
