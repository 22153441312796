import React, { useCallback, useEffect, useRef, useState } from "react";
import "./pointersCart.css";
import HeaderBreadcrumb from "../../components/headerbreadcrumb/HeaderBreadcrumb";
import { Card, Col, Row, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CartItem from "../../components/cartitem/CartItem";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import MetaInformation from "../../components/metainformation/MetaInformation";

function PointersCart({setHasCart}) {
  const headerinfo = {
    title: "Shopping Cart | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
        title: "Shopping Cart | Pointers Jewellers",
        description: "",
        
            image: window.location.origin+"/pointers/pointers_logo_social.png",
        url: window.location.href
    }
};
  const breadcrumbs = [{
    url: "/",
    label: "Shopping Cart"
  }];
  const { axiosJWT, getAccessToken } = useAuthorizationToken();
  const navigate = useNavigate();
  const shouldRunEffect = useRef(true);
  const [carts, setCarts] = useState();
  const [cartAlert, setCartAlert] = useState(null);
  const [subtotal, setSubtotal] = useState(0.0);
  const [allAvailable, isAllAvailable] = useState(true);
  // const [sst, setSst] = useState(0.00);
  const [total, setTotal] = useState(0.0);

  const handleOnSubmitError = (err) => {
    if (err.response) {
      setCartAlert(err.response.data);
    }
  };

  const handleTotalCalculation = (curCart) => {
    var curSubtotal = 0.0;
    curCart.forEach((cart, i) => {
      curSubtotal += cart.price * cart.quantity;
      if (parseFloat(cart?.diamond?.MYR_PRICE) > 0.0) {
        curSubtotal += parseFloat(cart?.diamond?.MYR_PRICE);
      }
    });
    setSubtotal(curSubtotal);
    // var curSst = curSubtotal ? (curSubtotal * 0.06) : 0.00;
    // setSst(curSst);
    // var curTotal = curSubtotal + curSst;
    var curTotal = curSubtotal;
    setTotal(curTotal);
  };

  const fetchData = useCallback(() => {
    async function fetchCartDetail() {
      const res = await axiosJWT
        .get(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.cart) {
        setCarts(res?.data?.cart);
        handleTotalCalculation(res?.data?.cart);
        var unavailablecart = res?.data?.cart.filter((cart) => {
          return cart?.is_available === false;
        });
        isAllAvailable(unavailablecart?.length > 0 ? false : true);

      } else {
        setCarts(null);
        setCartAlert(res?.data);
        // window.location.replace("/login");
      }
    }
    fetchCartDetail();
  }, [axiosJWT]);

  useEffect(() => {
    if (!getAccessToken()) {

      
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname!=="/login"?window.location.pathname:"/profile");
      window.location.replace("/login");
    } else {
      if (shouldRunEffect.current) {
        fetchData();
        shouldRunEffect.current = false;
      }
    }
  }, [fetchData, navigate, getAccessToken]);

  const handleCartOnDelete = async (e, id) => {
    if (
      window.confirm(
        "Are you sure you want to delet this item from shopping cart?"
      )
    ) {
      const res = await axiosJWT
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/user/cart/${id}`)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.code === 200) {
        // fetchData();
        window.location.reload();
        // setCarts(res?.data?.cart);
        // handleTotalCalculation(res?.data?.cart);
      }
    }
  };

  return (
    <>
    <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="pointers-cart-content">
        <div className="container">
          <h1 className=" text-uppercase">Shopping Cart</h1>
          <Row className="mb-5">
            {cartAlert ? (
              <Col xs="12">
                <Alert
                  variant="danger"
                  className="mb-3"
                  onClose={(e) => setCartAlert(null)}
                  dismissible
                >
                  {cartAlert?.message}
                </Alert>
              </Col>
            ) : (
              ""
            )}
            {carts?.length > 0 ? (
              <>
                <Col md="8">
                  {carts?.map((cart, index) => (
                    <CartItem
                      cart={cart}
                      key={index}
                      handleCartOnDelete={handleCartOnDelete}
                    />
                  ))}
                </Col>
                <Col md="4">
                  <Card className="card-amount btn-100">
                    <Card.Body>
                      <Row>
                        <Col>
                          <strong>Subtotal: </strong>
                        </Col>
                        <Col className="col-total-amount">
                          RM
                          {subtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Col>
                      </Row>
                      {/* <Row>
                                                    <Col><strong>SST (6%): </strong></Col>
                                                    <Col className="col-total-amount">RM{sst.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Col>
                                                </Row> */}
                      <hr />
                      <Row className="row-grandtotal">
                        <Col className="col-total-amount">
                          <h3>
                            RM
                            {total
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h3>
                        </Col>
                      </Row>
                      <hr />
                      {
                        allAvailable ? (
                          <><a href="/checkout">
                            <Button
                              variant="dark"
                              size="lg"
                              className="btn-red btn-100 text-uppercase"
                            >
                              Checkout
                            </Button>
                          </a></>
                        ) : ""
                      }

                    </Card.Body>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default PointersCart;
