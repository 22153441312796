import React from 'react';
import './pagefooter.css';
import { Row, Col } from 'react-bootstrap';
import { companyinfo } from '../../json/companyinfo';
import { footerlinks } from '../../json/footerlink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function PageFooter() {
    library.add(faFacebookF);
    library.add(faInstagram);
    return (
        <footer>
            <div className="footer-top_area">
                <div className="container">
                    <Row>
                        <Col sm="12" md="6" lg="3">
                            <div className="footer-info">
                                <div className="footer-logo">
                                    <a href="/">
                                        <img
                                            src="../../pointers/pointers_logo.png"
                                            alt="" className="img-logo"
                                        />
                                    </a>
                                </div>
                                <p className="footer-title">ABOUT POINTERS</p>
                                <ul>
                                    {
                                        footerlinks.aboutpointers.map((link, idx) => (
                                            <li>
                                                <a href={link.url}>{link.label}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                                {/* <p className="footer-title">{companyinfo.name}</p>

                                <p className="footer-address" dangerouslySetInnerHTML={{ __html: companyinfo.address.join('<br />') }}></p>

                                <div>
                                    <Phone className="footer-icon" /> {companyinfo.tel}
                                </div>
                                <div>
                                    <Print className="footer-icon" /> {companyinfo.fax}
                                </div>
                                <div>
                                    <Email className="footer-icon" /> {companyinfo.email}
                                </div> */}
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="3" className="footer-sm-mt">
                            <div className="footer-info">
                                <p className="footer-title">WHY POINTERS</p>
                                <ul>
                                    {
                                        footerlinks.whypointers.map((link, idx) => (
                                            <li>
                                                <a href={link.url}>{link.label}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="3" className="footer-sm-mt">
                            <div className="footer-info">
                                <p className="footer-title">POLICIES</p>
                                <ul>
                                    {
                                        footerlinks.policies.map((link, idx) => (
                                            <li>
                                                <a href={link.url}>{link.label}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="3" className="footer-sm-mt">
                            <div className="footer-info">
                                <p className="footer-title">STAY CONNECTED</p>
                                <div className="footer-socials">
                                    <ul>
                                        <li><a href={companyinfo.fb} rel="noreferrer" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook-f']} className="footer-icon" /></a></li>
                                        <li><a href={companyinfo.ig} rel="noreferrer" target="_blank"><FontAwesomeIcon icon={['fab', 'instagram']} className="footer-icon" /></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-info footer-sm-mt mt-4">
                                <p className="footer-title">GRADED BY</p>
                                <div className="footer-socials">
                                    <ul>
                                        {
                                            footerlinks.gradedby.map((link, idx) => (
                                                <li><img src={link.img} alt={link.name} className="img-graded" /></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer-bottom_area">
                <div className="container">
                    <div className="footer-bottom_nav">
                        <Row>
                            {/* <Col xs="12">
                                <ul>
                                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/shipping-policy">Shipping Policy</a></li>
                                </ul>
                            </Col> */}
                            <Col xs="12" className="pt-2">
                                COPYRIGHT &copy; {new Date().getFullYear()} POINTERS JEWELLERS (M) SDN. BHD. | ALL RIGHTS RESERVED
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default PageFooter