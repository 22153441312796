export const footerlinks = {
    "aboutpointers": [
        {
            "label": "Home",
            "url": "/"
        }, {
            "label": "About Us",
            "url": "/about-us"
        }, {
            "label": "Pointers Education",
            "url": "/pointers-education"
        }, {
            "label": "Contact Us",
            "url": "/contact-us"
        }, {
            "label": "Our Promises",
            "url": "/our-promises"
        }, {
            "label": "Pointers FAQ",
            "url": "/pointers-faq"
        }, {
            "label": "Sitemap",
            "url": "/sitemap"
        },
    ], "whypointers": [
        {
            "label": "Lifetime Manufacturer Warranty",
            "url": "/lifetime-manufacturer-warranty"
        }, {
            "label": "Free Worldwide Shipping",
            "url": "/free-worldwide-shipping"
        }, {
            "label": "Free Resizing",
            "url": "/free-resizing"
        }, {
            "label": "Price Match Guarantee",
            "url": "/price-match-guarantee"
        }, {
            "label": "Packaging",
            "url": "/packaging"
        }, {
            "label": "360° Video Technology",
            "url": "/360-video-technology"
        }, {
            "label": "3D Printing",
            "url": "/3d-printing"
        },
    ], "policies": [
        {
            "label": "Privacy Policy",
            "url": "/privacy-policy"
        },
        {
            "label": "Terms & Conditions",
            "url": "/terms-conditions"
        },
        {
            "label": "Return Policy",
            "url": "/return-policy"
        },
        {
            "label": "Payment Method (Malaysia)",
            "url": "/malaysian-payment-options"
        },
        {
            "label": "Payment Method (International)",
            "url": "/international-payment-options"
        },
    ], "gradedby": [
        {
            img: "../../gradedby/gemological-institute-of-america-lab-logo.jpg?v=20230609",
            name: "GIA"
        },
        {
            img: "../../gradedby/hrd-antwerp-institute-of-gemmology-lab-logo.jpg?v=20230609",
            name: "HRD Antwerp"
        },
        {
            img: "../../gradedby/ags-logo.png?v=20240611",
            name: "AGS"
        },
        {
            img: "../../gradedby/gem-certification-and-assurance-lab-logo.jpg?v=20230609",
            name: "GCAL"
        },
        {
            img: "../../gradedby/igi-logo.png?v=20230609",
            name: "IGI"
        },
    ]
}