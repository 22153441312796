import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-svg-core/styles.css';

import "./App.css";
// import logo from "./logo.svg";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import PageHeader from "./components/pageheader/PageHeader";
import PageFooter from "./components/pagefooter/PageFooter";
import RingDetail from "./pages/product/productdetail/RingDetail";
import DiamondWizard from "./pages/diamondwizard/DiamondWizard";
import OurStory from "./pages/ourstory/OurStory";
import AfterSalesServices from "./pages/aftersalesservices/AfterSalesServices";
import Tnc from "./pages/tnc/Tnc";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import ShippingPolicy from "./pages/shippingpolicy/ShippingPolicy";
import ContactUs from "./pages/contactus/ContactUs";
import PointersCart from "./pages/pointerscart/PointersCart";
import PointersCheckout from "./pages/pointerscheckout/PointersCheckout";
import Login from "./pages/login/Login";
import MyProfile from "./pages/myprofile/MyProfile";
// import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import OrderHistoryList from "./pages/orderhistory/list/OrderHistoryList";
import OrderHistoryDetail from "./pages/orderhistory/detail/OrderHistoryDetail";
import ProductList from "./pages/product/productlist/ProductList";
import AddressesList from "./pages/addressbook/list/AddressesList";
import AddressForm from "./pages/addressbook/form/AddressForm";
import WizardCompleted from "./pages/wizardcompleted/WizardCompleted";
import LifetimeManufacturerWarranty from "./pages/lifetimemanufacturerwarranty/LifetimeManufacturerWarranty";
import FreeWorldwideShipping from "./pages/freeworldwideshipping/FreeWorldwideShipping";
import { useState,useEffect } from "react";
import ReturnPolicy from "./pages/returnpolicy/ReturnPolicy";
import PriceMatchGuarantee from "./pages/pricematchguarantee/PriceMatchGuarantee";
import PointersFAQ from "./pages/pointersfaq/PointersFAQ";
import PointersEducation from "./pages/pointerseducation/PointersEducation";
import PointersPackaging from "./pages/packaging/PointersPackaging";
import OurPromises from "./pages/ourpromises/OurPromises";
import MalaysianPaymentOptions from "./pages/malaysianpaymentoptions/MalaysianPaymentOptions";
import InternationalPaymentOptions from "./pages/internationalpaymentoptions/InternationalPaymentOptions";
import FreeResizing from "./pages/freeresizing/FreeResizing";
import Video360Technology from "./pages/video360technology/Video360Technology";
import Printing3D from "./pages/printing3d/Printing3D";
import FastClick from 'fastclick';
// import { useEffect, useState } from "react";

function App() {
  // const [categories, setCategories] = useState();

  const [loading, setLoading] = useState(false);
  const [hasCart, setHasCart] = useState(false);
  const setStep=(step)=>{
    sessionStorage.setItem("_pointersstep", step);
  }

  useEffect(() => {
    console.log("fastclick");
    FastClick.attach(document.body);
  }, []);

  return (

    <Router>
      <PageHeader setStep={setStep} hasCart={hasCart} setHasCart={setHasCart}/>
      <Routes>
        <Route
          path="/"
          element={<Homepage setStep={setStep} />}
        ></Route>
        <Route
          path="/lifetime-manufacturer-warranty"
          element={<LifetimeManufacturerWarranty />}
        ></Route>
        <Route
          path="/free-worldwide-shipping"
          element={<FreeWorldwideShipping />}
        ></Route>
        <Route
          path="/about-us"
          element={<OurStory />}
        ></Route>
        <Route
          path="/after-sales-services"
          element={<AfterSalesServices />}
        ></Route>
        <Route
          path="/price-match-guarantee"
          element={<PriceMatchGuarantee />}
        ></Route>
        <Route
          path="/terms-conditions"
          element={<Tnc />}
        ></Route>
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        ></Route>
        <Route
          path="/return-policy"
          element={<ReturnPolicy />}
        ></Route>
        <Route
          path="/shipping-policy"
          element={<ShippingPolicy />}
        ></Route>
        <Route
          path="/contact-us"
          element={<ContactUs />}
        ></Route>
        <Route
          path="/pointers-faq"
          element={<PointersFAQ />}
        ></Route>
        <Route
          path="/pointers-education"
          element={<PointersEducation />}
        ></Route>
        <Route
          path="/packaging"
          element={<PointersPackaging />}
        ></Route>
        <Route
          path="/our-promises"
          element={<OurPromises />}
        ></Route>
        <Route
          path="/malaysian-payment-options"
          element={<MalaysianPaymentOptions />}
        ></Route>
        <Route
          path="/international-payment-options"
          element={<InternationalPaymentOptions />}
        ></Route>
        <Route
          path="/free-resizing"
          element={<FreeResizing />}
        ></Route>
        <Route
          path="/360-video-technology"
          element={<Video360Technology />}
        ></Route>
        <Route
          path="/3d-printing"
          element={<Printing3D />}
        ></Route>
        {/* <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route> */}

        <Route
          path="/login"
          element={<Login />}
        ></Route>
        <Route
          path="/addresses"
          element={<AddressesList />}
        ></Route>
        {/* <Route
            path="/addresses/create"
            element={<AddressForm />}
          ></Route> */}
        <Route
          path="/addresses/edit/:updateid"
          element={<AddressForm />}
        ></Route>
        <Route
          path="/profile"
          element={<MyProfile />}
        ></Route>
        <Route
          path="/cart"
          element={<PointersCart setHasCart={setHasCart} />}
        ></Route>
        <Route
          path="/checkout"
          element={<PointersCheckout />}
        ></Route>
        <Route
          path="/orders"
          element={<OrderHistoryList />}
        ></Route>
        <Route
          path="/orders/:trackingid"
          element={<OrderHistoryDetail />}
        ></Route>
        <Route
          path="/products/:categorySlug/:subcategorySlug"
          element={<ProductList setLoading={setLoading} />}
        ></Route>
        <Route
          path="/products"
          element={<ProductList setLoading={setLoading} />}
        ></Route>
        <Route
          path="/products/:categorySlug"
          element={<ProductList setLoading={setLoading} />}
        ></Route>
        <Route
          path="/product/:productSlug"
          element={<RingDetail setHasCart={setHasCart} />}
        ></Route>
        <Route
          path="/choose-diamond/:type"
          element={<DiamondWizard setLoading={setLoading} />}
        ></Route>
        <Route
          path="/choose-diamond/shape/:shape"
          element={<DiamondWizard setLoading={setLoading} />}
        ></Route>
        <Route
          path="/choose-diamond"
          element={<DiamondWizard setLoading={setLoading} />}
        ></Route>
        <Route
          path="/wizard-completed"
          element={<WizardCompleted setHasCart={setHasCart} />}
        ></Route>
      </Routes>
      {
        loading ? (
          <div className="loading-container" >
            <div className="loading-spinner"></div>
          </div>
        ) : ""
      }
      <PageFooter />

    </Router>
  );
}

export default App;
