import React, { useState, useEffect, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ringDetail.css";
import HeaderBreadcrumb from "../../../components/headerbreadcrumb/HeaderBreadcrumb";
import { Col, Row, Form, Button, Alert, Modal } from 'react-bootstrap';
import { variantoptions } from "../../../json/dummydata";
import { Favorite, Close, EmailOutlined, PhoneOutlined, ShoppingCartOutlined, ThreeSixtyOutlined, InfoOutlined } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { shapesfilters } from '../../../json/diamondfilters';
import classNames from "classnames";
import {
    Slider as InputSlider
} from "@material-ui/core";
import RingDiamondStep from '../../../components/ringdiamondsteps/RingDiamondStep';

import Slider from 'react-slick';
import { companyinfo } from '../../../json/companyinfo';
import useDiamondSteps from '../../../customuse/useDiamondSteps';
import useAuthorizationToken from '../../../auth/useAuthorizationToken';
import Product360 from '../../../components/product360/Product360';
import MetaInformation from '../../../components/metainformation/MetaInformation';
import ProductFingerSlider from '../../../components/productFingerSlider/ProductFingerSlider';

function RingDetail({ setHasCart }) {

    const { axiosJWT, getAccessToken } = useAuthorizationToken();
    const { checkNeedDiamondStep } = useDiamondSteps();
    let params = useParams();
    let sliderRef = useRef();
    const shouldRunEffect = useRef(true);
    const isMainCatOthers = [8];
    // const shouldRunEffect = useRef(true);
    const productSlug = params?.productSlug;
    const [productAlert, setProductAlert] = useState("");
    const [drophintAlert, setDrophintAlert] = useState("");

    const [carat, setCarat] = useState(null);
    const [shape, setShape] = useState(null);
    const [size, setSize] = useState(null);
    const [selectedSize, setSelectedSize] = useState();
    const [selectedMetal, setSelectedMetal] = useState();
    const [selectedFontstyle, setSelectedFontstyle] = useState();
    const [engraving, setEngraving] = useState();
    const [price, setPrice] = useState(0.00);
    const quantity = 1;
    const maxEngravingLength = 12;
    const [selectedPanelSetting, setSelectedPanelSetting] = useState(null);
    const [metalLabel, serMetalLabel] = useState(null);
    const [productinfo, setProductInfo] = useState(null);
    // const [activeMJFYAccordion, setActiveMJFYAccordion] = useState();
    const [shipmentDate, setShipmentDate] = useState();
    const [showDropHintModal, setShowDropHintModal] = useState(false);
    const [drophintRecipientEmail, setDrophintRecipientEmail] = useState();
    const [drophintRecipientPhone, setDrophintRecipientPhone] = useState();
    const [drophintEmail, setDrophintEmail] = useState();
    const [drophintPhone, setDrophintPhone] = useState();
    const [drophintNote, setDrophintNote] = useState();

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [productSliderIndex, setProductSliderIndex] = useState(0);

    const handleDropHintModalClose = () => setShowDropHintModal(false);
    const handleDropHintModalShow = () => setShowDropHintModal(true);
    const [descSeeMore, setDescSeeMore] = useState(false);


    const shapeslidersettings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };

    const productslidersettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        customPaging: function (i) {
            return (
                <div className="slick-bullet"></div>
            );
        },
    };

    const handleProductSliderAfterChange = (index) => {
        setProductSliderIndex(index);
    }

    const handleMouseDown = (event) => {
        const touch = event?.changedTouches ? event?.changedTouches[0] : event;
        setMousePosition({ x: touch.clientX, y: touch.clientY });
    };

    const handleMouseUp = (event) => {
        var show = productSliderIndex;
        const touch = event?.changedTouches ? event?.changedTouches[0] : event;
        const deltaX = touch.clientX - mousePosition.x;
        if (deltaX >= 0) {
            if (show < 0) {
                show = sliderRef.current.props.children.length - 1;
            } else {
                show = show - 1;
            }
        } else {
            if (show === sliderRef.current.props.children.length) {
                show = 0;

            } else {
                show = show + 1;
            }
        }
        sliderRef.current.slickGoTo(show);
        setProductSliderIndex(show);

    };

    const goToLastSlide = () => {
        if (sliderRef.current) {
            const slideCount = sliderRef.current.props.children.length - 1;
            sliderRef.current.slickGoTo(slideCount);
        }
    };

    // function SliderNextArrow(props) {
    //     const { className, style, onClick } = props;
    //     return (

    //         <KeyboardArrowRight className={className + " arrow"}
    //             style={{ ...style }}
    //             onClick={onClick} />
    //     );
    // }

    // function SliderPrevArrow(props) {
    //     const { className, style, onClick } = props;
    //     return (
    //         <KeyboardArrowLeft
    //             className={className + " arrow"}
    //             style={{ ...style }}
    //             onClick={onClick}
    //         />
    //     );
    // }

    const handleOnSubmitError = (err) => {
        if (err.response) {
            setProductAlert(err.response.data);
        }

        if (!err?.response) {
            window.location.replace("/login");
        }
    }
    // const [nav1, setNav1] = useState(null);
    // const [nav2, setNav2] = useState(null);

    const isProductOthers = () => {
        return isMainCatOthers.includes(productinfo?.mainCategory?.id);
    }

    const handlePriceCalculate = (curColor, curSize, curQuantity) => {
        var curPrice = (parseFloat(curColor?.price ? curColor?.price : 0.00) + parseFloat(curSize?.price ? curSize?.price : 0.00)) * curQuantity;
        setPrice(curPrice);
    }


    useEffect(() => {
        function presetSaveOptions() {
            var saveoptions = JSON.parse(sessionStorage.getItem("_pointerssaveoptions"));
            setSelectedMetal(saveoptions?.selectedMetal);
            setSelectedPanelSetting(saveoptions?.selectedPanelSetting);
            handleMetalLabelChange(saveoptions?.selectedMetal);
            setSize(parseInt(saveoptions?.size));
            setCarat(saveoptions?.carat);
            handleShapeOnChange(saveoptions?.shape);
            setEngraving(saveoptions?.engraving);
            setSelectedFontstyle(saveoptions?.selectedFontstyle);
            sessionStorage.removeItem("_pointerssaveoptions")
        }

        async function fetchProductDetail() {


            await fetch(`${process.env.REACT_APP_API_ENDPOINT}/product/${productSlug}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "X-Channel-ID": "pointers-web",
                    "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
                }
            }).then(response => response.json()).then(productdata => {
                setProductInfo(productdata?.product);

                /* START: Metal Management */
                var selectedmetal = productdata?.product?.variant?.gold[0];
                var selectedcolor = null;
                if (selectedmetal?.length === 0) {
                    selectedmetal = productdata?.product?.variant?.silver[0];
                }
                if (selectedmetal?.length === 0) {
                    selectedmetal = productdata?.product?.variant?.platinum[0];
                }


                /* START: Color Management */
                if (selectedmetal?.color_details?.length >= 0) {
                    selectedcolor = selectedmetal.color_details[0];
                }

                if (!selectedcolor) {
                    selectedcolor = productdata?.product?.variant?.others[0];
                }

                /* END: Color Management */


                setSelectedMetal(selectedmetal);
                setSelectedPanelSetting(selectedcolor);
                handleMetalLabelChange(selectedmetal);

                /* END: Metal Management */


                var selectedSize = null;

                if (productdata?.product?.diamondRing?.length > 0) {
                    selectedSize = productdata?.product?.diamondRing[0];



                    setSelectedSize(selectedSize ? selectedSize : {});

                    setSize(variantoptions.size[0]);
                }

                if (checkNeedDiamondStep(productdata?.product?.mainCategory)) {
                    setShape(shapesfilters[0]);
                    setCarat(variantoptions.carat[0]);
                }

                if (sessionStorage.getItem("_pointerssaveoptions")) {
                    console.log("TEST");
                    presetSaveOptions();
                }

                handlePriceCalculate(selectedcolor, selectedSize, 1);

            }).catch(err => {
                console.log(err);
                // navigate("/");
            });
        }

        if (shouldRunEffect.current) {
            if (!getAccessToken()) {

                sessionStorage.removeItem("_pointerredirect");
                sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? window.location.pathname : "/profile");
            }

            sessionStorage.setItem("_pointerssetting", JSON.stringify({}));
            const currentDate = new Date();

            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            // Calculate the date that is 30 days later
            const futureDate = new Date();
            futureDate.setDate(currentDate.getDate() + 30);
            const formattedFutureDate = `${daysOfWeek[futureDate.getDay()]}, ${monthsOfYear[futureDate.getMonth()]} ${futureDate.getDate()}`;
            setShipmentDate(formattedFutureDate);

            fetchProductDetail();
            shouldRunEffect.current = false;
        }


    }, [productSlug, getAccessToken, checkNeedDiamondStep]);

    const handleMetalOnChange = (option) => {
        setSelectedMetal(option);
        handleMetalLabelChange(option);

        var selectedcolor = option?.color_details.filter((color) => {
            return color.color_id === selectedPanelSetting.color_id;
        });
        if (selectedcolor.length > 0) selectedcolor = selectedcolor[0];
        else selectedcolor = option?.color_details[0];
        // else selectedcolor.option?.color_details[0];
        handleColorOnChange(selectedcolor);
        // setColor(option);
    }

    const handleColorOnChange = (option) => {

        // setColor(option);
        setSelectedPanelSetting(option);
        handlePriceCalculate(option, selectedSize, quantity);
    }

    const handleMetalLabelChange = (option) => {
        serMetalLabel(option?.name);
    };

    const handleCaratOnChange = (e, option) => {
        console.log("handleCaratOnChange");
        var curCarat = variantoptions.carat.filter((carat) => {
            return carat.value === option;
        });
        console.log(curCarat[0]);
        setCarat(curCarat[0]);

    }

    const handleShapeOnChange = (option) => {
        setShape(option);
    }

    const handleSelectedSizeOnChange = (e) => {
        var selectedsize = productinfo?.diamondRing?.filter((item) => {
            return parseInt(item.min_size) <= parseInt(e.target.value) && parseInt(item.max_size) >= parseInt(e.target.value);
        });
        setSelectedSize(selectedsize ? selectedsize[0] : {});

        setSize(e.target.value);
        console.log("Size: " + e.target.value);
        handlePriceCalculate(selectedPanelSetting, selectedsize ? selectedsize[0] : {}, quantity);
    }

    const handleSelectedFontstyleOnChange = (fontstyle) => {
        setSelectedFontstyle(fontstyle);
    }

    const handleEngravingOnChange = (e) => {
        if (e.target.value.length <= maxEngravingLength) {

            setEngraving(e.target.value);
        }
    }

    const handleOthersWeightChange = (e) => {
        var selected = productinfo?.variant?.others?.filter((item) => {
            return parseInt(item?.product_panel_setting_id) === parseInt(e.target.value);
        });
        setSelectedPanelSetting(selected?.length > 0 ? selected[0] : null);
        setPrice(selected?.length > 0 ? selected[0].price : null);

    }

    const handleDropHintRecipientEmailOnChange = e => {
        setDrophintRecipientEmail(e.target.value);
    }
    const handleDropHintRecipientPhoneOnChange = e => {
        setDrophintRecipientPhone(e.target.value);
    }

    const handleDropHintEmailOnChange = e => {
        setDrophintEmail(e.target.value);
    }

    const handleDropHintPhoneOnChange = e => {
        setDrophintPhone(e.target.value);
    }
    const handleDropHintNoteOnChange = e => {
        setDrophintNote(e.target.value);
    }


    const handleDropHintOnSubmit = async e => {
        var formdata = {
            recipient_email: drophintRecipientEmail,
            recipient_phone: drophintRecipientPhone,
            sender_email: drophintEmail,
            sender_phone: drophintPhone,
            note: drophintNote,
        }

        await fetch(`${process.env.REACT_APP_API_ENDPOINT}/hint/create`, {
            headers: {
                'Content-Type': 'application/json',
                "X-Channel-ID": "pointers-web",
                "X-Request-ID": "POINTERS_WEB" + Date.now() + (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7, '0')
            },
            method: "POST",
            body: JSON.stringify(formdata)
        }).then(response => response.json()).then(res => {
            if (res) {
                setDrophintAlert(res);
                if (res?.code === 200) {
                    setDrophintRecipientEmail("");
                    setDrophintRecipientPhone("");
                    setDrophintEmail("");
                    setDrophintPhone("");
                    setDrophintNote("");
                }
            }
        });

    }

    const validateProductToCart = () => {
        if (!engraving && !selectedFontstyle) {
            return true;
        } else if (!engraving || !selectedFontstyle) {
            setProductAlert({
                code: 500,
                message: "Please complete your Inscription."
            })
            return false;
        } else {
            return true;
        }
    };

    const handleOthersCheckoutClick = () => {
        var imgs = productinfo?.images?.normal;
        var selectedOther = {
            product_id: productinfo?.id,
            slug: productinfo?.slugId,
            images: imgs.length > 0 ? imgs[0]?.url : null,
            name: productinfo?.name,
            price: price,
            quantity: quantity,
            weight: selectedPanelSetting?.weight,
            product_panel_setting_id: selectedPanelSetting.product_panel_setting_id
        };

        sessionStorage.setItem("_pointersothers", JSON.stringify(selectedOther));
        window.location.replace("/checkout");

    }

    const handleAddToCartClick = async () => {
        if (selectedPanelSetting && validateProductToCart()) {


            var imgs = productinfo?.images?.normal.filter((item) => {
                return item.image_name.includes(productinfo?.skuId)
                    && item.image_name.includes(selectedPanelSetting?.color?.toLowerCase())
                    && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                    && (!carat || item.image_name.includes(carat.name.toLowerCase()))
                    && item.image_name.includes("_top_");
            });

            var selectedRing = {
                product_id: productinfo?.id,
                slug: productinfo?.slugId,
                images: imgs.length > 0 ? imgs[0]?.url : null,
                name: productinfo?.name,
                price: price,
                quantity: quantity,
                metal: selectedMetal,
                product_panel_setting_id: selectedPanelSetting.product_panel_setting_id,
                ring_size: size ? size : null,
                carat: carat?carat?.name.toLowerCase():0,
                engrave: {
                    text: engraving ? engraving : null,
                    font: selectedFontstyle?.label ? selectedFontstyle?.label : null,
                }
            };


            if (checkNeedDiamondStep(productinfo?.mainCategory)) {
                sessionStorage.setItem("_pointerssetting", JSON.stringify(selectedRing));

                var diamond = sessionStorage.getItem("_pointersdiamond") ? JSON.parse(sessionStorage.getItem("_pointersdiamond")) : null;
                if (!diamond?.STOCK_ID) {
                    window.location.replace('/choose-diamond');
                } else {

                    window.location.replace("/wizard-completed");
                }
            } else {
                var formdata = {
                    product_id: productinfo?.id,
                    product_panel_setting_id: selectedRing?.product_panel_setting_id,
                    quantity: selectedRing?.quantity,
                    diamond: {},
                    ring_size: selectedRing?.ring_size,
                    engrave: selectedRing?.engrave,
                };
                const res = await axiosJWT.post(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`, formdata).catch((err) => {
                    handleOnSubmitError(err);

                });
                if (res?.data) {
                    setHasCart(true);
                    setProductAlert(res?.data);

                }
            }
        }
    }

    const handleSaveOptionsBeforeToLogin = () => {
        var diamond = sessionStorage.getItem("_pointersdiamond") ? JSON.parse(sessionStorage.getItem("_pointersdiamond")) : null;
        if (!diamond?.STOCK_ID) {
            handleAddToCartClick();
        } else {
            var saveoptions = {
                shape: shape,
                selectedMetal: selectedMetal,
                selectedPanelSetting: selectedPanelSetting,
                size: size,
                carat: carat,
                engraving: engraving,
                selectedFontstyle: selectedFontstyle
            };
            sessionStorage.setItem("_pointerssaveoptions", JSON.stringify(saveoptions));
            window.location.replace("/login");

        }
    }

    return (
        <>
            <MetaInformation headerinfo={{
                title: productinfo?.name + " | Pointers Jewellers",
                description: "",
                keyword: "",
                author: "",
                og: {
                    title: productinfo?.name + " | Pointers Jewellers",
                    description: "",

                    image: window.location.origin + "/pointers/pointers_logo_social.png",
                    url: window.location.href
                }
            }} />
            <HeaderBreadcrumb breadcrumbs={[{
                url: "",
                label: productinfo?.name
            }]} />
            {
                checkNeedDiamondStep(productinfo?.mainCategory) ? <RingDiamondStep currrent_step={1} /> : ""
            }
            <div className="product-content">
                <div className="container">
                    <Row>
                        <Col lg="8" md="8" className="position-relative">
                            {
                                productinfo?.classification?.name ? (
                                    <div className="classification-badge">{productinfo?.classification?.name}</div>) : ""
                            }
                            <Row className="d-block d-md-none d-lg-none">
                                <Col xs="12">
                                    <Slider ref={sliderRef} {...productslidersettings} afterChange={handleProductSliderAfterChange}>
                                        {
                                            isProductOthers() ? (
                                                productinfo?.images?.normal?.map((image) => (
                                                    <div className="img-card">
                                                        <img alt={image.image_name} src={image.url} />
                                                    </div>
                                                ))
                                            ) : ""
                                        }
                                        {
                                            !isProductOthers() ? (productinfo?.images?.normal.filter((item) => {
                                                return item.image_name.includes(productinfo?.skuId)
                                                    && item.image_name.includes(
                                                        (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                                                            ||
                                                            selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                                                            'white' :
                                                            selectedPanelSetting?.color?.toLowerCase()
                                                    )
                                                    && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                                                    && (!carat || item.image_name.includes(carat?.name.toLowerCase()));

                                                // return (item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_top_"+carat.name.toLowerCase())
                                                // || item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_side_"+carat.name.toLowerCase())
                                                // );
                                            }).map((image, idx) => (
                                                <div className="img-card" onTouchStart={handleMouseDown}
                                                    onTouchEnd={handleMouseUp}>
                                                    <img alt={image.image_name} src={image.url} />
                                                </div>
                                            ))) : ""
                                        }
                                        {
                                            (!isProductOthers() && productinfo?.images?.rotate?.length > 0 && productinfo?.skuId && selectedPanelSetting?.color && (!shape || shape?.value)) ? (

                                                <div className="img-card">
                                                    <Product360
                                                        images={productinfo?.images?.rotate}
                                                        skuid={productinfo?.skuId}
                                                        selectedPanelSetting={selectedPanelSetting}
                                                        shape={shape?.value} />
                                                    <div className="label-360 text-center">360&deg; Interactive Video – Drag to Rotate</div>
                                                </div>

                                            ) : ""
                                        }
                                        {
                                            (!isProductOthers() && productinfo?.images?.slider?.length > 0 && productinfo?.skuId && selectedPanelSetting?.color && (!shape || shape?.value) && (!carat || carat?.name)) ? (
                                                productinfo?.images?.slider.filter((item) => {
                                                    return item.image_name.includes(productinfo?.skuId)
                                                        && item.image_name.includes(
                                                            (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                                                                ||
                                                                selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                                                                'white' :
                                                                selectedPanelSetting?.color?.toLowerCase()
                                                        )
                                                        && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                                                        && (!carat || item.image_name.includes(carat?.name.toLowerCase()));

                                                    // return (item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_top_"+carat.name.toLowerCase())
                                                    // || item.image_name.includes(productinfo.skuId+"_" + color.name.toLowerCase() + "_" + shape.name.toLowerCase() + "_side_"+carat.name.toLowerCase())
                                                    // );
                                                }).map((image, idx) => (
                                                    <div className="img-card">
                                                        <ProductFingerSlider
                                                            image={image?.url} />
                                                    </div>
                                                )

                                                )) : ""
                                        }
                                    </Slider>

                                </Col>
                                {
                                    !isProductOthers() ? (
                                        <Col xs="12" className="mb-2 text-center">
                                            <Button size="lg" className="btn-red text-uppercase btn-360 py-1" onClick={goToLastSlide}><ThreeSixtyOutlined /> 360&deg;</Button>
                                        </Col>
                                    ) : ""
                                }

                            </Row>

                            <div className="d-none d-md-block text-center">
                                <Row>
                                    {
                                        isProductOthers() ? (
                                            productinfo?.images?.normal?.map((image) => (
                                                <Col xs="12">
                                                    <div className="img-card">
                                                        <img alt={image.image_name} src={image.url} />
                                                    </div>
                                                </Col>
                                            ))
                                        ) : ""
                                    }

                                    {
                                        !isProductOthers() ? (productinfo?.images?.normal.filter((item) => {
                                            return item.image_name.includes(productinfo?.skuId)
                                                && item.image_name.includes(
                                                    (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                                                        ||
                                                        selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                                                        'white' :
                                                        selectedPanelSetting?.color?.toLowerCase()
                                                )
                                                && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                                                && (!carat || item.image_name.includes(carat?.name.toLowerCase()));

                                        }).map((image, idx) => (

                                            <Col lg="6" md="6">
                                                <div className="img-card">
                                                    <img alt={image.image_name} src={image.url} />
                                                </div>
                                            </Col>
                                        ))) : ""
                                    }
                                    {
                                        (!isProductOthers() && productinfo?.images?.rotate?.length > 0 && productinfo?.skuId && selectedPanelSetting?.color && (!shape || shape?.value)) ? (
                                            <Col lg="6" md="6">
                                                <div className="img-card">
                                                    <Product360
                                                        images={productinfo?.images?.rotate}
                                                        skuid={productinfo?.skuId}
                                                        selectedPanelSetting={selectedPanelSetting}
                                                        shape={shape?.value} />
                                                    <div className="label-360 text-center">360&deg; Interactive Video – Drag to Rotate</div>
                                                </div>
                                            </Col>
                                        ) : ""
                                    }
                                    {
                                        (!isProductOthers() && productinfo?.images?.slider?.length > 0 && productinfo?.skuId && selectedPanelSetting?.color && shape?.value) ? (

                                            (productinfo?.images?.slider.filter((item) => {
                                                return item.image_name.includes(productinfo?.skuId)
                                                    && item.image_name.includes(
                                                        (selectedPanelSetting?.color?.toLowerCase() === 'platinum'
                                                            ||
                                                            selectedPanelSetting?.color?.toLowerCase() === 'silver') ?
                                                            'white' :
                                                            selectedPanelSetting?.color?.toLowerCase()
                                                    )
                                                    && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                                                    && (!carat || item.image_name.includes(carat?.name.toLowerCase()));

                                            }).map((image, idx) => (

                                                <Col lg="6" md="6">
                                                    <div className="img-card">
                                                        <ProductFingerSlider
                                                            image={image?.url} />
                                                    </div>
                                                </Col>
                                            ))
                                            )) : ""
                                    }

                                </Row>
                            </div>
                        </Col>
                        <Col lg="4" md="4">
                            {!isProductOthers() ? (
                                <div className="variant">
                                    {
                                        checkNeedDiamondStep(productinfo?.mainCategory) ? (

                                            <><p><strong>View with Diamond Shape: </strong>{shape?.label}</p><div className="mb-2">
                                                <div className="d-block d-md-none d-lg-none">
                                                    <Slider {...shapeslidersettings}>
                                                        {shapesfilters.map((option, index) => (
                                                            <div className="opt-shape" onClick={e => handleShapeOnChange(option)}>
                                                                <img src={option.img} alt={option.label} className="img-fluid" />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>

                                                <div className="d-none d-md-block">
                                                    {shapesfilters.map((option, index) => (
                                                        <div className="opt-shape" onClick={e => handleShapeOnChange(option)}>
                                                            <img src={option.img} alt={option.label} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="clearfix"></div>
                                            </div><p><strong>View with Carat Size: </strong>{carat?.label} Carat</p><div className="mb-2">
                                                    <InputSlider
                                                        aria-label="Restricted values"
                                                        step={null}
                                                        marks={variantoptions.carat}
                                                        className="w-100"
                                                        min={0.28}
                                                        max={2.00}
                                                        value={carat?.value}
                                                        valueLabelDisplay="auto"
                                                        onChange={handleCaratOnChange}
                                                        valueLabelFormat={carat?.label} />
                                                    <div className="clearfix"></div>
                                                </div></>
                                        ) : ""
                                    }

                                    {
                                        selectedPanelSetting?.color ? (<p><strong>Color: </strong>{selectedPanelSetting?.color}</p>) : ""
                                    }

                                    <div className="mb-2">
                                        <ul className="list-metal-circles">
                                            {selectedMetal?.color_details?.map((option, index) => (
                                                <li className={option?.color?.toLowerCase() + " " + classNames({
                                                    active: selectedPanelSetting?.color_id === option.color_id,
                                                })} onClick={e => handleColorOnChange(option)}>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <p><strong>Metal: </strong>{metalLabel}</p>
                                    <div className="mb-2">
                                        <ul className="list-metal-circles">

                                            {productinfo?.variant?.gold.map((option, index) => (
                                                <li className={"gold " + classNames({
                                                    active: selectedMetal?.sub_panel_setting_id === option.sub_panel_setting_id,
                                                })} onClick={e => handleMetalOnChange(option)}>
                                                    <img src={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} alt={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} className="w-100" />
                                                </li>
                                            ))}

                                            {productinfo?.variant?.silver.map((option, index) => (
                                                <li className={"silver " + classNames({
                                                    active: selectedMetal?.sub_panel_setting_id === option.sub_panel_setting_id,
                                                })} onClick={e => handleMetalOnChange(option)}>
                                                    <img src={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} alt={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} className="w-100" />
                                                </li>
                                            ))}

                                            {productinfo?.variant?.platinum.map((option, index) => (
                                                <li className={"platinum " + classNames({
                                                    active: selectedMetal?.sub_panel_setting_id === option.sub_panel_setting_id,
                                                })} onClick={e => handleMetalOnChange(option)}>
                                                    <img src={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} alt={"/metalcircle/" + option?.name.replace(" ", "-") + ".png"} className="w-100" />
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            ) : ""}


                            <div className="product-info">
                                <h1 className="heading">
                                    {productinfo?.name}
                                </h1>
                                <p className="sku">SKU: {productinfo?.skuId}</p>
                                <div className="text-price mb-3">RM{price?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    {
                                        checkNeedDiamondStep(productinfo?.mainCategory) ? (
                                            <small className="text-setting-only"> (Setting Only)</small>
                                        ) : ""
                                    }
                                </div>

                                <div className="d-product-desc" dangerouslySetInnerHTML={{ __html: productinfo?.description }}></div>
                                <div className={
                                    classNames({
                                        "m-product-desc": true,
                                        "active": descSeeMore
                                    })
                                }>
                                    <div className="product-desc" dangerouslySetInnerHTML={{ __html: productinfo?.description }}></div>
                                    <p className="desc-see-more" onClick={e => setDescSeeMore(true)}>See More</p>
                                    <p className="desc-see-less" onClick={e => setDescSeeMore(false)}>See Less</p>
                                </div>
                            </div>
                            <div className="variants">
                                <Row>
                                    {
                                        isProductOthers() ? (
                                            <>
                                                {
                                                    productinfo?.variant?.others?.length > 0 ? (
                                                        <Col xs="12">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Select Weight:</Form.Label>

                                                                <Form.Select value={selectedPanelSetting?.product_panel_setting_id} className="form-control"
                                                                    onChange={handleOthersWeightChange}>

                                                                    {productinfo?.variant?.others?.map((option, index) => (
                                                                        <option value={option?.product_panel_setting_id}>{option?.weight}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    ) : ""
                                                }
                                            </>
                                        ) : (
                                            <>

                                                <Col xs="12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label><a href="/pdf/Pointers-Ring-Size-Guide.pdf" target="_blank">Select Ring Size: <InfoOutlined /> </a></Form.Label>

                                                        <Form.Select className="form-control" value={size} onChange={handleSelectedSizeOnChange}>
                                                            {variantoptions.size.map((option, index) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>



                                                <Col xs="12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Add an Inscription</Form.Label>
                                                        <Form.Control type="text" value={engraving} onChange={handleEngravingOnChange} className={
                                                            "engrave-" + (selectedFontstyle?.label ? selectedFontstyle?.label?.toLowerCase() : "normal")
                                                        } />
                                                        <small>{maxEngravingLength} characters only</small>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs="12">
                                                    {variantoptions.fontstyle.map((option, index) => (
                                                        <div className={classNames({
                                                            "opt-fontstyle": true,
                                                            active: selectedFontstyle?.id === option.id
                                                        })}
                                                            onClick={e => handleSelectedFontstyleOnChange(option)}>
                                                            <img src={option.img} alt={option.label} className="img-fluid" />
                                                            <div className="text-center">
                                                                {option.label}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Col>
                                            </>
                                        )
                                    }



                                    {/* <Col xs="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control value={quantity} onChange={handleQuantityOnChange}
                                                type="number" />
                                        </Form.Group>
                                    </Col> */}
                                    <Col xs="12">
                                        {/* <a href="/choose-diamond"> */}

                                        {
                                            getAccessToken() ?
                                                (
                                                    <>
                                                        {
                                                            isProductOthers() ? (
                                                                <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleOthersCheckoutClick}>
                                                                    <ShoppingCartOutlined /> Checkout
                                                                </Button>
                                                            ) : (
                                                                <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleAddToCartClick}>
                                                                    <ShoppingCartOutlined /> Add to Cart
                                                                </Button>
                                                            )
                                                        }

                                                    </>
                                                ) : (
                                                    <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleSaveOptionsBeforeToLogin}><ShoppingCartOutlined />
                                                        {
                                                            sessionStorage.getItem("_pointersdiamond") ? "Checkout" : "Choose Diamond"
                                                        }
                                                    </Button>
                                                )
                                        }


                                        {/* </a> */}
                                    </Col>
                                    {
                                        productAlert?.code === 200 ? (

                                            <Col xs="12">
                                                <Form.Group className="my-3">
                                                    <Alert variant="success" className="mb-0" onClose={e => setProductAlert(null)} dismissible>
                                                        {productAlert.message}
                                                    </Alert>
                                                </Form.Group>
                                            </Col>) : ''
                                    }
                                    {
                                        productAlert?.message && productAlert?.code !== 200 ? (
                                            <Col xs="12">
                                                <Form.Group className="my-3">
                                                    <Alert variant="danger" className="mb-0" onClose={e => setProductAlert(null)} dismissible>
                                                        {productAlert.message}
                                                    </Alert>
                                                </Form.Group>
                                            </Col>) : ''
                                    }

                                    {
                                        !isProductOthers() ? (
                                            <><Col xs="12">
                                                <div className="card-contacts mt-4">
                                                    <Row>
                                                        <Col xs="4" className="text-center py-4" onClick={handleDropHintModalShow}>
                                                            <div className="icon-drophint">

                                                                <EmailOutlined />
                                                                <Favorite className="text-red drophint-love" />
                                                            </div>
                                                            <div>Drop Hint</div>
                                                        </Col>
                                                        <Col xs="4" className="text-center py-4">
                                                            <a href={"mailto:" + companyinfo.email}>
                                                                <EmailOutlined />
                                                                <div>Email</div>
                                                            </a>
                                                        </Col>
                                                        <Col xs="4" className="text-center py-4">
                                                            <a href={"tel:" + companyinfo.tel}>
                                                                <PhoneOutlined />
                                                                <div>Call</div>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col><hr className="mt-4" /><Col xs="12" className="py-4">
                                                    <div className="col-icon">
                                                        <img src="/pointers/icons/icon-speedy-diamond-gray.svg" alt="Delivery Diamond" className="img-fluid" />
                                                    </div>
                                                    <div className="col-delivery mb-4">
                                                        Free Shipping, Free 30 Day Returns
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="col-icon">
                                                        <img src="/pointers/icons/calendar-gray.svg" alt="Calendar" className="img-fluid" />
                                                    </div>
                                                    <div className="col-delivery mb-4">
                                                        Order Now and Your Order Ships by <strong className="text-red">{shipmentDate}</strong>, depending on center diamond.
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </Col></>
                                        ) : <Col xs="12" className="mb-5"></Col>
                                    }

                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    !isProductOthers() ? (
                        <div className="container mb-5 mt-3">
                            <div className="title-pack text-center mb-4">
                                <h4>Embark on an Extraordinary Journey to Find Her Dream Ring!</h4>
                                <div className="sub-title mt-0 px-md-5">
                                    <p className="mb-0 px-md-5">Experience our exclusive 1-on-1 Diamond & Gemstone Buying Adventure.</p>
                                    <p className="px-md-5">Revel in our stellar 5-star reputation, boasting over 1000 glowing reviews. Whether you're in our Pointers Experience Store or connecting online, our seasoned Curators are here to help you make this moment unforgettable.</p>
                                </div>
                            </div>
                            <Row>
                                <Col xs="12" sm="4" className="text-center">
                                    <div className="bg-needhelp-1" style={{
                                        backgroundImage: `url("../../needhelps/1-on-1-diamond-education.png")`
                                    }}></div>
                                    <strong>Illuminate the Brilliance</strong>
                                    <p>Explore the world of diamonds in a calm, stress-free atmosphere. Receive personalized recommendations to choose a ring that will truly captivate her.</p>
                                </Col>
                                <Col xs="12" sm="4" className="text-center">
                                    <div className="bg-needhelp-1" style={{
                                        backgroundImage: `url("../../needhelps/get-her-dream-ring.png")`
                                    }}></div>
                                    <strong>The Dream Ring, Her Reality</strong>
                                    <p>Witness the magic as we unveil the ring destined to capture her heart. Expert size estimation and our exclusive 'Propose with POINTERS Service' offer you added assurance.</p>
                                </Col>

                                <Col xs="12" sm="4" className="text-center">
                                    <div className="bg-needhelp-2" style={{
                                        backgroundImage: `url("../../needhelps/offline-or-virtual-consultation.png")`
                                    }}></div>
                                    <strong>Bridging the Gap, Expertise Unwavering</strong>
                                    <p>No matter the distance, our Curators are here to guide you through virtual consultations, ensuring your choice is as perfect as she is.</p>
                                </Col>
                            </Row>

                        </div>
                    ) : ""
                }

            </div>

            <Modal show={showDropHintModal} onHide={handleDropHintModalClose} size="xs" className="modal-drophint">

                <Modal.Body>
                    <Close className="icon-close" onClick={e => { handleDropHintModalClose() }} />

                    <h3>Drop a Hint</h3>
                    <div>Love this design? Share a little hint.</div>

                    <hr />
                    <div className="col-img">
                        {
                            productinfo?.images?.normal.filter((item) => {
                                return item.image_name.includes(productinfo?.skuId)
                                    && item.image_name.includes(selectedPanelSetting?.color?.toLowerCase())
                                    && (!shape || item.image_name.includes(shape?.value?.toLowerCase()))
                                    && (!carat || item.image_name.includes(carat?.name.toLowerCase()))
                                    && item.image_name.includes("top");

                            }).sort((a, b) => a.image_name < b.name ? 1 : -1).map((image, idx) => (
                                <div className="img-card">
                                    <img alt={image.image_name} src={image.url} />
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-prod-desc">
                        <strong>{productinfo?.name}</strong>
                        <div><small>{metalLabel}</small></div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="form-drophint">
                        {
                            drophintAlert?.code === 200 ? (

                                <Form.Group className="my-3">
                                    <Alert variant="success" className="mb-0" onClose={e => setDrophintAlert(null)} dismissible>
                                        {drophintAlert.message}
                                    </Alert>
                                </Form.Group>
                            ) : ''
                        }
                        {
                            drophintAlert?.message && drophintAlert?.code !== 200 ? (

                                <Form.Group className="my-3">
                                    <Alert variant="danger" className="mb-0" onClose={e => setDrophintAlert(null)} dismissible>
                                        {drophintAlert.message}
                                    </Alert>
                                </Form.Group>
                            ) : ''
                        }
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Recipient's Email" value={drophintRecipientEmail} onChange={handleDropHintRecipientEmailOnChange} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Recipient Phone" value={drophintRecipientPhone} onChange={handleDropHintRecipientPhoneOnChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Your Email" value={drophintEmail} onChange={handleDropHintEmailOnChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Your Phone" value={drophintPhone} onChange={handleDropHintPhoneOnChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control as="textarea" rows={3} placeholder="Add a Note" value={drophintNote} onChange={handleDropHintNoteOnChange} />
                        </Form.Group>

                        <Form.Group>
                            <Button size="lg" className="btn-red text-uppercase w-100" onClick={handleDropHintOnSubmit}>Send</Button>
                        </Form.Group>
                    </div>
                    <p className="text-privacy">By submitting this form, you agree that Brilliant Earth may use your email address in accordance with our Privacy Policy. <a href="/privacy-policy" className="text-underline" target="_blank">View privacy policy</a></p>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default RingDetail