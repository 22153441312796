import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function PointersEducation() {
    const headerinfo = {
        title: "Pointers Education | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Pointers Education | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Pointers Education"
    }];

    const handleOnMessage = (event) => {
        var toolFrame = document.getElementById("iframe_anatomy");
        var c = toolFrame.contentWindow || toolFrame.contentDocument;
        if (event.source !== c || typeof event.data !== 'string') {
            return;
        }
        var toolFrameData = JSON.parse(event.data);
        toolFrame.height = '';
        toolFrame.height = toolFrameData.height;

        toolFrame = document.getElementById("iframe_cut");
        c = toolFrame.contentWindow || toolFrame.contentDocument;
        if (event.source !== c || typeof event.data !== 'string') {
            return;
        }
        toolFrameData = JSON.parse(event.data);
        toolFrame.height = '';
        toolFrame.height = toolFrameData.height;

        toolFrame = document.getElementById("iframe_color");
        c = toolFrame.contentWindow || toolFrame.contentDocument;
        if (event.source !== c || typeof event.data !== 'string') {
            return;
        }
        toolFrameData = JSON.parse(event.data);
        toolFrame.height = '';
        toolFrame.height = toolFrameData.height;

        toolFrame = document.getElementById("iframe_clarity");
        c = toolFrame.contentWindow || toolFrame.contentDocument;
        if (event.source !== c || typeof event.data !== 'string') {
            return;
        }
        toolFrameData = JSON.parse(event.data);
        toolFrame.height = '';
        toolFrame.height = toolFrameData.height;


        toolFrame = document.getElementById("iframe_carat_weight");
        c = toolFrame.contentWindow || toolFrame.contentDocument;
        if (event.source !== c || typeof event.data !== 'string') {
            return;
        }
        toolFrameData = JSON.parse(event.data);
        toolFrame.height = '';
        toolFrame.height = toolFrameData.height;

    }

    if (window.attachEvent) {
        window.attachEvent('onmessage', handleOnMessage);
    } else if (window.addEventListener) {
        window.addEventListener('message', handleOnMessage, false);
    }

    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">Pointers Education</h1>
                    <h4 className=" text-uppercase my-4">Understanding the 4Cs of Diamond Quality</h4>
                    <iframe title="Anatomy" id="iframe_anatomy" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" src="https://4cs.gia.edu/interactive-4cs//standalone/anatomy.html?&amp;embedURL=localhost&amp;embedPath=/pointers-education" style={{ "width": "1px", "min-width": "100%" }} height="523.484"></iframe>
                    <iframe title="Color" id="iframe_color" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" src="https://4cs.gia.edu/interactive-4cs//standalone/color.html?&amp;embedURL=localhost&amp;embedPath=/pointers-education" style={{ "width": "1px", "min-width": "100%" }} height="473.984"></iframe>
                    <iframe title="Clarity" id="iframe_clarity" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" src="https://4cs.gia.edu/interactive-4cs//standalone/clarity.html?&amp;embedURL=localhost&amp;embedPath=/pointers-education" style={{ "width": "1px", "min-width": "100%" }} height="562.75"></iframe>
                    <iframe title="Cut" id="iframe_cut" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" src="https://4cs.gia.edu/interactive-4cs//standalone/cut.html?&amp;embedURL=localhost&amp;embedPath=/pointers-education" style={{ "width": "1px", "min-width": "100%" }} height="711.078"></iframe>
                    <iframe title="Carat Weight" id="iframe_carat_weight" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0" src="https://4cs.gia.edu/interactive-4cs//standalone/carat-weight.html?&amp;embedURL=localhost&amp;embedPath=/pointers-education" style={{ "width": "1px", "min-width": "100%" }} height="451.188"></iframe>

                    {/* <p>GIA created the 4Cs (Color, Clarity, Cut and Carat Weight) as a universal method for establishing how to check diamond quality anywhere in the world. The 4Cs means two very important things: diamond quality can be communicated in a universal language, and diamond-buying consumers can know exactly what they are about to purchase.</p>
                    <p>Click on the “Understanding the 4Cs of Diamond Quality” image to see a larger view. Bookmark and share this important tool for learning how to check the quality of a diamond!</p>
                    <img src="/pointers/4Cs-Infographic.jpg" className="img-fluid w-100" alt="Understanding the 4Cs of Diamond Quality" />
                    
                    <h4 className=" text-uppercase mt-4">Carat Weight (Carat)</h4>
                    <p className="mb-0">Carat is the unit of measurement for a diamond's weight. One carat is equal to 200 milligrams. Smaller diamonds may be measured in points, where 100 points make up one carat (e.g., a 0.75-carat diamond is equivalent to 75 points).</p>
                    <ul>
                        <li>Grading Levels:<br />
                            <ul>
                                <li>Carat Scale: Diamonds are typically categorized into different weight ranges. Common categories include:
                                    <ul>
                                        <li>Melee (tiny diamonds, usually less than 0.18 carats)</li>
                                        <li>Small (0.18 - 0.49 carats)</li>
                                        <li>Medium (0.50 - 0.99 carats)</li>
                                        <li>Large (1.00 - 1.99 carats)</li>
                                        <li>Very Large (2.00 carats and above)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Impact on Value: Larger diamonds within each weight category generally command higher prices, but other factors like cut, color, and clarity also influence a diamond's overall value.</li>
                    </ul>

                    <h4 className=" text-uppercase mt-4">Cut</h4>
                    <p className="mb-0">The cut of a diamond refers to how well it has been shaped, faceted, and finished. It is not about the diamond's shape but rather the quality of its cut, including proportions, symmetry, and polish.</p>
                    <ul>
                        <li>Grading Levels:
                            <ul>
                                <li>Cut Grading Scale: GIA uses the following cut grades:
                                    <ul>
                                        <li>Excellent</li>
                                        <li>Very Good</li>
                                        <li>Good</li>
                                        <li>Fair</li>
                                        <li>Poor</li>

                                    </ul>
                                </li>
                                <li>Components of Cut Quality:
                                    <ul>
                                        <li>Proportions: Evaluates the relationships between table size, crown angle, pavilion depth, and other factors.</li>
                                        <li>Symmetry: Assesses the alignment and evenness of facets.</li>
                                        <li>Polish: Examines the smoothness of the diamond's surface.</li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Impact on Value: Well-cut diamonds with higher grades tend to exhibit more brilliance and are often more valuable.</li>
                    </ul>

                    <h4 className=" text-uppercase mt-4">Color</h4>
                    <p className="mb-0">Diamond color is graded on a scale from D (colorless) to Z (light yellow or brown). The GIA uses controlled lighting and viewing conditions to assess the absence of color.</p>
                    <ul>
                        <li>Grading Levels:<br />
                            <ul>
                                <li>Color Grading Scale: GIA grades diamonds on a scale from D (colorless) to Z (light yellow or brown).
                                    <ul>
                                        <li>D-F: Colorless</li>
                                        <li>G-J: Near Colorless</li>
                                        <li>K-M: Faint Yellow</li>
                                        <li>N-R: Very Light Yellow</li>
                                        <li>S-Z: Light Yellow</li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Impact on Value: Diamonds with less color (D to F) are rarer and generally more valuable, but preferences for color may vary among individuals.</li>
                    </ul>


                    <h4 className=" text-uppercase mt-4">Clarity</h4>
                    <p>Clarity refers to the presence of internal (inclusions) and external (blemishes) flaws in a diamond. The GIA uses a scale from Flawless to Included to grade clarity.</p>
                    <ul>
                        <li>Grading Levels:<br />
                            <ul>
                                <li>Clarity Grading Scale: GIA uses the following clarity grades:
                                    <ul>
                                        <li>Flawless (FL)</li>
                                        <li>Internally Flawless (IF)</li>
                                        <li>Very, Very Slightly Included (VVS1, VVS2)</li>
                                        <li>Very Slightly Included (VS1, VS2)</li>
                                        <li>Slightly Included (SI1, SI2)</li>
                                        <li>Included (I1, I2, I3)</li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Impact on Value: Diamonds with higher clarity grades (FL to VS) are rarer and more valuable. However, inclusions may not always be visible to the naked eye, and their impact on value depends on their nature and visibility.</li>
                    </ul>
                    <p className="mt-4">By understanding these grading levels, consumers can make informed decisions based on their preferences and budget, ensuring they select a diamond that aligns with their desired balance of the 4Cs.</p> */}

                </div>
            </div>
        </>
    )
}

export default PointersEducation