import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';
import { Table } from 'react-bootstrap';

function MalaysianPaymentOptions() {
    const headerinfo = {
        title: "Malaysian Payment Options | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Malaysian Payment Options | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Malaysian Payment Options"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Malaysian Payment Options</h1>
                    <p>For transactions within Malaysia, we accept various payment methods.</p>
                    <h4 className=" text-uppercase mt-4">Credit/Debit Card</h4>
                    <ul>
                        <li>We welcome all Visa and MasterCard, including both Credit and Debit cards. Payments can be made through our website using iPay88.</li>
                        <li>3-D Secure (Verified by Visa and MasterCard Secure) is enabled, ensuring the protection of your credit card information through industry-leading encryption standards. We do not retain your credit card details after the order is completed; they are directly submitted to our banks.</li>

                    </ul>
                    <h4 className=" text-uppercase mt-4">Online Banking</h4>
                    <p className="mb-0">Online banking is accessible on our website through iPay88, supporting platforms such as:</p>
                    <ul>
                        <li>Maybank2u, </li>
                        <li>CIMB Clicks, </li>
                        <li>RHB Now, </li>
                        <li>AmOnline, </li>
                        <li>Hong Leong Bank, </li>
                        <li>PBe Bank, and more.</li>

                    </ul>

                    <h4 className=" text-uppercase mt-4">Bank Transfer</h4>
                    <p>Payments via bank transfer can be made to our Mayabank Bank account.</p>

                    <h4 className=" text-uppercase mt-4">Cash</h4>
                    <p className="mb-0">Cash payments are accepted at our POINTERS Showroom in Malaysia at the specified addresses:</p>
                    <div>Pointers Jewellers Sdn Bhd in Selangor</div>
                    <div>C3, Jalan SS15/4D</div>
                    <div>47500, Subang Jaya,</div>
                    <div>Selangor Darul Ehsan, Malaysia.</div>

                    <h4 className=" text-uppercase mt-4">Interest-free Instalment</h4>
                    <p className="mb-0">Explore the Benefits of Interest-Free Credit at POINTERS. Offline installment plans are exclusively offered for Credit Card transactions at our POINTERS Showroom in Malaysia. Meanwhile, online installment options are accessible for Maybank Credit Cardholders.</p>

                    <div className="table-delivery">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Country/Region</th>
                                    <th>Delivery Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Bank</td>
                                    <td>Installment Plan</td>
                                </tr>
                                <tr>
                                    <td>Maybank (Online & Offline)</td>
                                    <td>
                                        <ul>
                                            <li>6 Months, 0% Interest</li>
                                            <li>12 Months, 0% Interest</li>

                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Public Bank (Offline only)</td>
                                    <td>
                                        <ul>
                                            <li>6 Months, 0% Interest</li>
                                            <li>12 Months, 0% Interest</li>

                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>OCBC Bank (Offline only)</td>
                                    <td>
                                        <ul>
                                            <li>6 Months, 0% Interest</li>
                                            <li>12 Months, 0% Interest</li>

                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>HSBC Bank (Offline only)</td>
                                    <td>
                                        <ul>
                                            <li>6 Months, 0% Interest</li>
                                            <li>12 Months, 0% Interest</li>

                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ambank (Coming Soon)</td>
                                    <td>
                                        <ul>
                                            <li>6 Months, 0% Interest</li>
                                            <li>12 Months, 0% Interest</li>

                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <p className="mb-0"><strong>Deposit:</strong></p>
                    <p>Before processing orders, a minimum deposit of 70% is required. Full payment (100%) must be completed before collecting your order or arranging delivery.</p>
                    <p className="mb-0"><strong>Payment Confirmation:</strong></p>
                    <p>You will receive an email notification within 24 hours of confirming your order. Please be mindful of your bank's reconciliation schedule, as this may impact your payment, especially for Online Banking methods. Bank reconciliation times occur approximately between 11:30 p.m. to 1:30 a.m., depending on your bank.</p>



























                </div>
            </div>
        </>
    )
}

export default MalaysianPaymentOptions