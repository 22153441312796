import React, { useEffect, useRef, useState } from "react";
import { cutsfilters } from "../../json/diamondfilters";
import { DeleteOutline } from "@material-ui/icons";
import { Card, Col, Row, Form, Alert, Button } from "react-bootstrap";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import classNames from "classnames";
import { variantoptions } from "../../json/dummydata";

function CartItem({ cart, handleCartOnDelete }) {
  const { axiosJWT } = useAuthorizationToken();
  const shouldRunEffect = useRef(true);
  const [cartitem, setCartItem] = useState(cart);
  // const [shape, setShape] = useState();
  // const [image, setImage] = useState();
  const [cartAlert, setCartAlert] = useState();
  const maxEngravingLength = 12;
  const handleOnSubmitError = (err) => {
    if (err.response) {
      setCartAlert(err.response.data);
    }
  };
  // const initializeData = useCallback(async () => {
  //   var curShape = cartitem?.diamond?.SHAPE;
  //   var curImage = null;
  //   // if (curShape) {
  //   //   setShape(curShape);
  //   // }
  //   await fetch(
  //     `${process.env.REACT_APP_API_ENDPOINT}/product/${cartitem?.slug_id}`,
  //     {
  //       headers: { 
  //         "Content-Type": "application/json",
  //         "X-Channel-ID":"pointers-web",
  //         "X-Request-ID":"POINTERS_WEB"+Date.now()+(Math.floor(Math.random() * (9999999 - 1 + 1)) + 1).toString().padStart(7,'0')
  //        },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((productdata) => {
  //       if (productdata?.code === 200) {
  //         curImage = productdata?.product?.images?.normal?.filter((item) => {
  //           var checkshape = curShape?.toLowerCase() ? item.image_name.includes(curShape?.toLowerCase()) : true;
  //           return (
  //             checkshape &&
  //             item.image_name.includes(cartitem?.color?.toLowerCase()) &&
  //             item.image_name.includes("_top_")
  //           );
  //         });

  //         if (curImage.length > 0) {
  //           setImage(curImage[0]?.url);
  //         } else {
  //           setImage(cartitem?.thumbnail);
  //         }
  //       }
  //     });
  // }, [cartitem]);
  useEffect(() => {
    if (shouldRunEffect.current) {
      // initializeData();
      if (!cartitem?.is_available) {
        setCartAlert({
          code: 500,
          message: "This item temporarily unavailable. Please remove it before proceed to checkout."
        });
      }
      shouldRunEffect.current = false;
    }
  }, [cartitem]);


  const handleEngravingOnChange = (e) => {
    if (e.target.value.length <= maxEngravingLength) {
      var curCart = { ...cartitem };
      curCart.engrave_text = e.target.value;
      setCartItem(curCart);
    }
  }

  const handleSelectedFontstyleOnChange = (fontstyle) => {
    var curCart = { ...cartitem };
    curCart.engrave_font = fontstyle?.label;
    setCartItem(curCart);
  }

  const validateProductToCart = () => {
    if (!cartitem?.engrave_text && !cartitem?.engrave_font) {
      return true;
    } else if (!cartitem?.engrave_text || !cartitem?.engrave_font) {
      setCartAlert({
        code: 500,
        message: "Please complete your Inscription."
      })
      return false;
    } else {
      return true;
    }
  };

  const handleCartUpdateOnClick = async () => {
    if (validateProductToCart()) {
      var formdata = {
        id: cartitem?.id,
        quantity: 1,
        engrave: {
          text: cartitem?.engrave_text,
          font: cartitem?.engrave_font,
        }
      }
      const res = await axiosJWT
        .put(`${process.env.REACT_APP_API_ENDPOINT}/user/cart`, formdata)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.code === 200) {
        setCartAlert(res?.data);
      }
    }

  }

  return (
    <Card className="card-order-item mb-3">
      <Card.Body>
        <Row>
          <Col md="5" className="text-center mb-2">
            <div className="col-img mb-2">
              <a href={"/product/" + cart.slug_id}>
                <img alt="Product 1" src={cartitem?.thumbnail} />
              </a>
            </div>
            <small
              className=" icon-delete mt-2"
              onClick={(e) => handleCartOnDelete(e, cart.id)}
            >
              <DeleteOutline />
              Remove
            </small>
          </Col>
          <Col md="7">
            <Row>
              <Col sm="8">
                <a href={"/product/" + cart.slug_id}>
                  <strong className="">{cart.product_name}</strong>
                </a>
                <br />
                <small>Qty: {cartitem?.quantity}</small><br />
                {
                  cartitem?.name ? (<><small>Color: {cartitem?.name}</small><br /></>) : ""
                }
                {cartitem?.ring_size ? (
                  <>
                    <small>Size: {cartitem?.ring_size}</small>
                    <br />
                  </>
                ) : (
                  ""
                )}
                <small>Engrave Text ({maxEngravingLength} characters only): </small>
                <br />
                <Form.Control type="text" value={cartitem?.engrave_text} onChange={handleEngravingOnChange} className={
                  "mb-2 engrave-" + (cartitem?.engrave_font ? cartitem?.engrave_font?.toLowerCase() : "normal")
                } />
                <div>
                  {variantoptions.fontstyle.map((option, index) => (
                    <div className={classNames({
                      "opt-fontstyle": true,
                      active: cartitem?.engrave_font?.toLowerCase() === option.label.toLowerCase()
                    })}
                      onClick={e => handleSelectedFontstyleOnChange(option)}>
                      <img src={option.img} alt={option.label} className="img-fluid" />
                      <div className="text-center">
                        {option.label}
                      </div>
                    </div>
                  ))}
                </div>
                <Button className="btn-red text-uppercase w-100" onClick={handleCartUpdateOnClick}>Update</Button>

              </Col>
              <Col sm="4" className="text-right text-price">
                <small>
                  <strong>Total:</strong>
                </small>
                <br />
                <h5>
                  RM
                  {(cart.price * cart.quantity)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h5>
              </Col>
            </Row>
            {
              cartAlert?.code ? (
                <Row className="mt-2">
                  <Col xs="12">
                    <Alert
                      variant={cartAlert?.code === 200 ? "success" : "danger"}
                      className="mb-3"
                      onClose={(e) => setCartAlert(null)}
                      dismissible={cartitem?.is_available}
                    >
                      {cartAlert?.message}
                    </Alert>
                  </Col>
                </Row>
              ) : ""
            }
            {cartitem?.diamond?.CERTIFICATE_NO ? (
              <>
                <hr />
                <Row>
                  <Col sm="8">
                    <strong className="">
                      {cartitem?.diamond?.CARAT} CARAT {cartitem?.diamond?.SHAPE}{" "}
                      DIAMOND
                    </strong>
                    <br />
                    <small>{cartitem?.diamond?.LAB} Cert: <a href={cartitem?.diamond?.CERTIFICATE_LINK} target="_blank" rel="noreferrer">{cartitem?.diamond?.CERTIFICATE_NO}</a></small>
                    <br />
                    <small>
                      {
                        cutsfilters.filter((cutsfilter) => {
                          return cutsfilter.value === cartitem?.diamond?.CUT;
                        })[0]?.label
                      }{" "}
                      Cut, {cartitem?.diamond?.COLOR} Color,{cartitem?.diamond?.CLARITY}{" "}
                      Clarity
                    </small>
                    <br />
                  </Col>
                  <Col sm="4" className="text-right text-price">
                    <small>
                      <strong>Total:</strong>
                    </small>
                    <br />
                    <h5>
                      RM
                      {parseFloat(cartitem?.diamond?.MYR_PRICE)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h5>
                  </Col>
                </Row>

              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CartItem;
