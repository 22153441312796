import { DescriptionOutlined, LocalShippingOutlined, PhoneOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row, Card, Alert, Button, Form } from "react-bootstrap";
import HeaderBreadcrumb from "../../../components/headerbreadcrumb/HeaderBreadcrumb";
import "./orderHistoryDetail.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { cutsfilters } from "../../../json/diamondfilters";
import useAuthorizationToken from "../../../auth/useAuthorizationToken";

function OrderHistoryDetail() {
  const [breadcrumbs, setBreadcrumbs] = useState();
  const { axiosJWT, getAccessToken } = useAuthorizationToken();
  const navigate = useNavigate();
  let params = useParams();
  let location = useLocation();

  const trackingid = params?.trackingid;
  const shouldRunEffect = useRef(true);
  const [order, setOrder] = useState();
  const [orderAlert, setOrderAlert] = useState();

  const handleOnSubmitError = (err) => {
    if (err.response) {
      setOrderAlert(err.response.data);
    }
  };

  const initializeBreadcrumb = useCallback((order_tracking_id) => {
    var curBreadcrumb = [{
      url: "/orders",
      label: "Order History"
    }];
    if (order_tracking_id) {
      curBreadcrumb.push({
        url: "",
        label: order_tracking_id
      });
    }
    setBreadcrumbs(curBreadcrumb);
  }, []);

  useEffect(() => {
    async function fetchOrderDetail() {
      const res = await axiosJWT
        .get(`${process.env.REACT_APP_API_ENDPOINT}/order/${trackingid}`)
        .catch((err) => {
          handleOnSubmitError(err);
        });
      if (res?.data?.order?.order_tracking_id) {
        setOrder(res?.data?.order);

        initializeBreadcrumb(res?.data?.order?.order_tracking_id);
      } else {
        setOrder(null);
        // setOrderAlert(res?.data);
        window.location.replace("/orders");
      }
    }

    if (!getAccessToken()) {


      sessionStorage.removeItem("_pointerredirect");
      sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? window.location.pathname : "/profile");
      window.location.replace("/login");
    } else {
      if (shouldRunEffect.current) {

        fetchOrderDetail();


        const queryParams = new URLSearchParams(location.search);
        const qrystrStatus = queryParams.get("status");
        if (qrystrStatus && qrystrStatus.toLowerCase() === "success") {
          setOrderAlert({
            code: 200,
            message: "The order have been checkout successfully.",
          });
        } else if (qrystrStatus && qrystrStatus.toLowerCase() === "failed") {
          setOrderAlert({
            code: 500,
            message:
              "The payment have been failed. Please contact us for the enquiry.",
          });
        }
        shouldRunEffect.current = false;
      }
    }
  }, [axiosJWT, navigate, getAccessToken, trackingid, location, initializeBreadcrumb]);

  const handlePayNowOnClick = async (e) => {
    const res = await axiosJWT
      .put(`${process.env.REACT_APP_API_ENDPOINT}/order/update`, { payment_data: order?.payment_data })
      .catch((err) => {
        handleOnSubmitError(err);
      });

    if (res?.data?.code === 200) {
      const form = document.getElementById("ptFrmeGHL");
      if (form) {
        form.submit();
      }
    }


  }

  return (
    <>
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="orderhistorydetail-content">
        <div className="container mb-5">
          <h1 className=" text-uppercase">Order Detail</h1>

          {orderAlert?.code === 500 ? (
            <Col xs="12">
              <Alert
                variant="danger"
                className="mb-3"
                onClose={(e) => setOrderAlert(null)}
                dismissible
              >
                {orderAlert?.message ? orderAlert?.message : orderAlert}
              </Alert>
            </Col>
          ) : (
            ""
          )}
          {orderAlert?.code === 200 ? (
            <Col xs="12">
              <Alert
                variant="success"
                className="mb-3"
                onClose={(e) => setOrderAlert(null)}
                dismissible
              >
                {orderAlert?.message ? orderAlert?.message : orderAlert}
              </Alert>
            </Col>
          ) : (
            ""
          )}
          <Row>
            <Col md="7">
              {order?.orders.map((item, index) => (
                <Card className="card-order-item mb-3">
                  <Card.Body>
                    <Row>
                      <Col md="3">
                        <a href={"/product/" + item.slug_id}>
                          <img
                            alt="Product 1"
                            src={item.thumbnail}
                            className="img-fluid"
                          />
                        </a>
                      </Col>
                      <Col sm="6">
                        <a href={"/product/" + item.slug_id}>
                          <strong className="">{item.product_name}</strong>
                        </a>
                        <br />
                        <small>
                          <strong>
                            Unit Price: RM
                            {parseFloat(item.price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </strong>
                        </small>
                        <br />
                        {item?.weight ? (
                          <>
                            <small>Weight: {item?.weight}</small>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {item?.name ? (
                          <>
                            <small>Color: {item?.name}</small>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {item?.ring_size ? (
                          <>
                            <small>Size: {item?.ring_size}</small>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <small>Qty: {item.quantity}</small>
                        {item?.engrave_text ? (
                          <>
                            <br />
                            <small>Engrave Text: <font className={"engrave-" + (item?.engrave_font ? item?.engrave_font?.toLowerCase() : "normal")}>{item?.engrave_text}</font></small>

                          </>
                        ) : (
                          ""
                        )}
                        {item?.engrave_font ? (
                          <>
                            <br />
                            <small>Engrave Style: {item?.engrave_font}</small>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md="3" className="text-right text-price">
                        <small>
                          <strong>Total:</strong>
                        </small>
                        <br />
                        <h5>
                          RM
                          {parseFloat(item.price * item.quantity)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h5>
                        <br />
                      </Col>
                    </Row>
                    {item?.diamond?.CERTIFICATE_NO ? (
                      <>
                        <Row className="mt-3">
                          <Col md="3"></Col>
                          <Col sm="6">
                            <strong className="">
                              {item?.diamond?.CARAT} CARAT{" "}
                              {item?.diamond?.SHAPE} DIAMOND
                            </strong>
                            <br />
                            <small>
                              {item?.diamond?.LAB} Cert: <a href={item?.diamond?.CERTIFICATE_LINK} target="_blank" rel="noreferrer">{item?.diamond?.CERTIFICATE_NO}</a>
                            </small>
                            <br />
                            <small>
                              {
                                cutsfilters.filter((cutsfilter) => {
                                  return (
                                    cutsfilter.value === item?.diamond?.CUT
                                  );
                                })[0]?.label
                              }{" "}
                              Cut, {item?.diamond?.COLOR} Color,
                              {item?.diamond?.CLARITY} Clarity
                            </small>
                            <br />
                          </Col>
                          <Col sm="3" className="text-right text-price">
                            <small>
                              <strong>Total:</strong>
                            </small>
                            <br />
                            <h5>
                              RM
                              {parseFloat(item?.diamond?.MYR_PRICE)
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h5>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Card>
              ))}
              {
                order?.remark ? (
                  <Card className="card-order-item mb-3">
                    <Card.Body>
                      <h4 className=" text-uppercase">
                        <DescriptionOutlined /> Order Remark
                      </h4>
                      <p>{order?.remark}</p>
                    </Card.Body>
                  </Card>
                ) : ""
              }

            </Col>
            <Col md="5">
              <Card className="mb-3">
                <Card.Body>
                  <h4 className=" text-uppercase">
                    <LocalShippingOutlined /> Shipping Information
                  </h4>
                  <div>
                    <strong>{order?.address?.name}</strong>
                  </div>
                  {/* <div>POINTERS JEWELLERS (M) SDN. BHD.</div> */}
                  <div>{order?.address?.address} </div>
                  <div>
                    {order?.address?.postal} {order?.address?.city}
                  </div>
                  <div>{order?.address?.state} </div>
                  <div>{order?.address?.country} </div>
                  <div>
                    <PhoneOutlined />: {order?.address?.phone}
                  </div>
                  {/* <div><Email />: jack@gmail.com</div> */}
                </Card.Body>
              </Card>

              <Card className="mb-5 text-right">
                <Card.Body>
                  <Row>
                    <Col>
                      <strong>Order Status: </strong>
                    </Col>
                    <Col className="col-total-amount">
                      <strong>{order?.order_status}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Order Number: </strong>
                    </Col>
                    <Col className="col-total-amount">
                      {order?.order_tracking_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Date Ordered: </strong>
                    </Col>
                    <Col className="col-total-amount">
                      {order?.created_date}
                    </Col>
                  </Row>
                  {/* <Row>
                                <Col><strong>Order Status: </strong></Col>
                                <Col className="col-total-amount"><h5>In Progress</h5></Col>
                            </Row> */}
                  <hr />
                  {/* <Row>
                                <Col><strong>Subtotal: </strong></Col>
                                <Col className="col-total-amount">RM3,699.00</Col>
                            </Row>
                            <Row>
                                <Col><strong>Shipping: </strong></Col>
                                <Col className="col-total-amount">RM10.00</Col>
                            </Row>
                            <Row>
                                <Col><strong>SST (6%): </strong></Col>
                                <Col className="col-total-amount">RM221.94</Col>
                            </Row>
                            <hr /> */}
                  <Row className="row-grandtotal">
                    <Col className="col-total-amount">
                      <h3>RM{parseFloat(order?.order_total)
                        ?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                    </Col>
                  </Row>
                  <hr />

                  {order?.payment_data?.HashValue ? (
                    <><Form
                      action={`${process.env.REACT_APP_EGHL_ENDPOINT}`}
                      method="POST" id="ptFrmeGHL"
                    >
                      {Object.keys(order?.payment_data).map((key, index) => (
                        <input
                          type="hidden"
                          name={key}
                          value={order?.payment_data[key]} />
                      ))}

                    </Form><Button
                      type="button"
                      variant="dark"
                      size="lg"
                      className="btn-red btn-100 text-uppercase" onClick={handlePayNowOnClick}
                    >
                        Pay Now
                      </Button></>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}

export default OrderHistoryDetail;
