import React, { useEffect, useRef, useState } from "react";
import "./ringDiamondStep.css";
import classNames from "classnames";

function RingDiamondStep({ currrent_step }) {
  const shouldRunEffect = useRef(true);
  const selectedProduct = sessionStorage.getItem("_pointerssetting")
    ? JSON.parse(sessionStorage.getItem("_pointerssetting"))
    : null;
  const selectedDiamond = sessionStorage.getItem("_pointersdiamond")
    ? JSON.parse(sessionStorage.getItem("_pointersdiamond"))
    : null;
  const [sequence, setSequence] = useState();

  useEffect(() => {
    if (shouldRunEffect.current) {
      var curSequence = null;
      if ((sessionStorage.getItem("_pointersstep")==="diamond")||(selectedDiamond?.STOCK_ID && !selectedProduct?.name)) {
        sessionStorage.removeItem("_pointersstep");
        curSequence = ["diamond", "product"];
      } else {
        curSequence = ["product", "diamond"];
      }
      setSequence(curSequence);
      shouldRunEffect.current = false;
    }
    // setSequence(curSequence);
  }, [selectedDiamond, selectedProduct]);

  const handleRingChangeOnclick = () => {
    sessionStorage.removeItem("_pointerssetting");
    // sessionStorage.setItem("_pointersdiamond", JSON.stringify({}));
    window.location.replace("/products");
  };
  const handleDiamondChangeOnclick = () => {
    sessionStorage.removeItem("_pointersdiamond");
    window.location.replace("/choose-diamond");
  };

  return (
    <div className="container wizard-step-container">
      <div className="wizard-steps">
        {sequence?.map((step, index) => (
          <>
            {step === "product" ? (
              <div
                className={classNames({
                  step: true,
                  active: currrent_step >= index + 1,
                })}
              >
                <div className="node">
                  <div className="num">{index + 1}</div>
                  {selectedProduct?.images ? (
                    <div className="stepimg">
                      <img
                        alt={selectedProduct?.name}
                        src={selectedProduct?.images}
                        className="img-info"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="stepinfo">
                    <h2 className="nostyle-heading">Choose Ring</h2>
                    {selectedProduct?.name ? (
                      <div className="data">{selectedProduct?.name}</div>
                    ) : (
                      ""
                    )}
                    {selectedProduct?.price ? (
                      <div className="data">
                        RM{" "}
                        {selectedProduct?.price
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedProduct?.slug ? (
                      <div className="action" onClick={handleRingChangeOnclick}>
                        <span>Change</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {step === "diamond" ? (
              <div
                className={classNames({
                  step: true,
                  active: currrent_step >= index + 1,
                })}
              >
                <div className="node">
                  <div className="num">{index + 1}</div>
                  {selectedDiamond?.IMAGE ? (
                    <div className="stepimg">
                      <img
                        alt={
                          selectedDiamond?.CARAT +
                          " Carat " +
                          selectedDiamond?.SHAPE
                        }
                        src={selectedDiamond?.IMAGE}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="stepinfo">
                    <h2 className="nostyle-heading">Choose Diamond</h2>
                    {selectedDiamond?.STOCK_ID ? (
                      <>
                        <div className="data">
                          {selectedDiamond?.CARAT} Carat{" "}
                          {selectedDiamond?.SHAPE}
                        </div>
                        <div className="data">
                          RM {selectedDiamond?.MYR_PRICE}
                        </div>
                        <div
                          className="action"
                          onClick={handleDiamondChangeOnclick}
                        >
                          Change
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ))}

        <div
          className={classNames({
            step: true,
            active: currrent_step >= 3,
          })}
        >
          <div className="node">
            <div className="num">3</div>
            <div className="stepimg">
              <img alt="Completed" src="/pointers/icons/ring.svg" />
            </div>
            <div className="stepinfo">
              <h2 className="nostyle-heading">Completed</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RingDiamondStep;
