import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function FreeResizing() {
    const headerinfo = {
        title: "Jewellery Resizing | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Jewellery Resizing | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Jewellery Resizing "
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">Jewellery Resizing </h1>
                    <h4 className=" text-uppercase mt-4">Searching your perfect ring size </h4>
                    <p>Determining your ring size involves ensuring that the ring slides over your knuckle with a slight amount of resistance and fits comfortably on your finger. When taking it off, you should experience a gentle resistance and may need to twist the ring for removal.</p>

                    <h4 className=" text-uppercase mt-4">How to precisely determine your ring size?</h4>
                    <p>Various techniques can be found online, such as employing a paper sizer or a piece of thread. We will explore each of these methods and outline their individual advantages and disadvantages.</p>
                    <h4 className=" text-uppercase mt-4">Ring Sizer</h4>
                    <p>The most effective approach remains utilizing ring sizers as described below. You can acquire a complete set online or visit any jewelry store to test them out. At Pointers, we offer our ring sizers for you to try on.</p>
                    <h4 className=" text-uppercase mt-4">Thread/Paper Strip</h4>
                    <p>While utilizing common household items may seem convenient, we recommend refraining from using string or paper to gauge your ring size. These materials have the potential to stretch or twist, leading to an imprecise measurement. Additionally, they do not factor in the size of your knuckles.</p>
                    <h4 className=" text-uppercase mt-4">Ring Mandrel</h4>
                    <p>If you possess a well-fitting ring, it is advisable to utilize a ring mandrel, as depicted below, to accurately measure its size. You can have your ring measured at any nearby jewelry stores.</p>
                    <h4 className=" text-uppercase mt-4">Printable Ring Sizers</h4>
                    <p>While printable ring sizers may appear simple and convenient by placing your current ring on the paper, inaccuracies may arise if the printed size is not precise due to printer settings. Furthermore, individuals with untrained eyes may struggle to align the rings accurately with the paper.</p>
                    <h4 className=" text-uppercase mt-4">Ring Size Chart</h4>
                    <p>If you already know your ring size, utilize our International Ring Size Conversion Chart to convert between various ring sizes. Pointers use the Hong Kong ring size standard.</p>

                    <h4 className=" text-uppercase mt-4">Factors that influence getting the right ring size</h4>
                    <ol>
                        <li>Large Knuckles: Opt for the smallest size that comfortably fits over your knuckles without causing discomfort for small fingers with prominent knuckles.</li>
                        <li>Hand: Recognize that each finger may have a different size, with the dominant hand typically having larger measurements.</li>
                        <li>Time of Day: Keep in mind that finger diameter tends to be smaller in the morning and larger at night.</li>
                        <li>Temperature: Finger diameter can vary with temperature, being smaller in colder conditions and larger in warmer weather.</li>
                        <li>Swelling: Factors like water retention, associated with excessive salt consumption or pregnancy, can cause temporary changes in ring size.</li>
                        <li>Weight Fluctuations: Changes in weight, whether loss or gain, can impact your ring size.</li>

                    </ol>
                    <p>We recommend measuring your ring size during midday at room temperature. It's advisable to measure 3-4 times to ensure accuracy.</p>
                    <p>If you're uncertain about the measurement process, feel free to <a href="/contact-us" className="text-underline">contact us</a> for guidance.</p>


                </div>
            </div>
        </>
    )
}

export default FreeResizing