import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';
import { Col, Row } from 'react-bootstrap';

function Video360Technology() {
    const headerinfo = {
        title: "360° Video Technology | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "360° Video Technology | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "360° Video Technology"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">360° Video Technology</h1>
                    <p>Examine our diamonds from every angle using a comprehensive 360° view, enhancing the magnification to provide you with a thorough insight into the quality of your diamond from all perspectives. This ensures a seamless and stress-free purchasing process.</p>
                    <Row className="mb-4">
                        <Col md="6">
                            <img src="/360videotech/360-video-tech-1.jpg" className="img-fluid w-100" alt="REAL-TIME DIAMOND INSPECTION" />
                        </Col>
                        <Col md="6">
                            <h4 className=" text-uppercase mt-4">REAL-TIME DIAMOND INSPECTION</h4>
                            <p>Embark on a journey of unrivaled diamond discovery with POINTERS' Real-Time Diamond Inspection. Revel in the beauty of diamonds like never before, where each element – from shape and color to clarity, beauty, and sparkle – is showcased in meticulous detail. Utilizing state-of-the-art technology, we offer a 360-degree view, enabling you to explore every facet and brilliance. Choose POINTERS for a diamond-buying experience that brings transparency and confidence to the forefront, revealing the true essence of your precious gem from every angle.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <img src="/360videotech/360-video-tech-2.jpg" className="img-fluid w-100" alt="RSMART WAY TO BUY DIAMONDS" />
                        </Col>
                        <Col md="6">
                            <h4 className=" text-uppercase mt-4">RSMART WAY TO BUY DIAMONDS</h4>
                            <p>While a diamond certificate provides only a limited glimpse of the gem, our revolutionary 360-degree video technology unveils the entire story. Save countless hours on your diamond research, as not every online diamond seller offers this level of transparency. Witness your diamond from every angle, ensuring a comprehensive view that goes beyond the confines of a traditional certificate</p>
                        </Col>
                    </Row>



                </div>
            </div>
        </>
    )
}

export default Video360Technology