import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function PointersPackaging() {
    const headerinfo = {
        title: "Pointers Packaging | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Pointers Packaging | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Pointers Packaging"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">POINTERS PACKAGING</h1>
                    <p>As a testament to POINTERS' dedication to our cherished customers, we offer a seamlessly secure package for delivery.</p>
                    <h4 className=" text-uppercase mt-4">Specialized boxes designed for each piece of jewelry.</h4>
                    <p>Every item purchased from POINTERS is accompanied by custom-tailored cases, guaranteeing secure doorstep delivery for each customer.</p>
                    <p>Engagement rings, wedding bands, men's and women's fashion rings are carefully packaged in specialized ring boxes, while earrings, bracelets, and necklaces are placed in their respective jewelry boxes.</p>
                    <h4 className=" text-uppercase mt-4">Exclusive Engagement Ring Proposal Box</h4>
                    <p>We acknowledge the importance of concealing your ring box during a marriage proposal. Unlike a standard ring box, which primarily serves as a storage container, POINTERS' Proposal Engagement Ring Box is specifically designed to meet the unique requirements of a marriage proposal. This slim, rectangular-shaped box is pocket-fitted and crafted to effortlessly position the ring towards your loved one when opened.</p>

                    <h4 className=" text-uppercase mt-4">The Shipment Package Box</h4>
                    <p className="mb-0">POINTERS' discreet shipping is facilitated through our specialized delivery box. All items are carefully packed within a Rosewood Apricot POINTERS box for secure delivery. The following items, if applicable, will be included in the delivery box:</p>
                    <ul>
                        <li>Invoice of products</li>
                        <li>Appraisal Certificates - GIA Diamond Grading Report</li>
                        <li>Return Instructions</li>
                        <li>An Additional Proposal Ring Box for Engagement Rings (if available)</li>

                    </ul>

                    <h4 className=" text-uppercase mt-4">The Security Sticker</h4>
                    <p>Every delivery parcel dispatched by POINTERS is safeguarded with a security sticker affixed to the opening section, ensuring protection against tampering. We strongly advise all customers to examine their package for any damages before accepting or signing for receipt. In the event that the security sticker has been tampered with, please refrain from signing the parcel and promptly reach out to our customer service for assistance.</p>




                </div>
            </div>
        </>
    )
}

export default PointersPackaging