import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function ReturnPolicy() {
    const headerinfo = {
        title: "Return Policy | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Return Policy | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Return Policy"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Return Policy</h1>
                    <p>We graciously accept returns to accommodate resizing, refurbishment, and warranty claims.</p>
                    <h4 className=" text-uppercase mt-4">Resize</h4>
                    <p>Experience the convenience of a complimentary resize for our rings, up to 3 sizes up or down (e.g., 10, 10.5, 11). Charges apply for resizing beyond 3 sizes. Detailed information on jewelry resizing can be found [here](link to resizing details).</p>
                    <p>*Note: For certain designs, resizing may not be advisable due to design proportions. We recommend consulting with our knowledgeable advisors for further guidance.</p>

                    <h4 className=" text-uppercase mt-4">Refurbish</h4>
                    <p>Benefit from an annual complimentary refurbishment for all jewelry, lasting for a decade. Charges are applicable for additional refurbishment services. Further insights into our meticulous jewelry refurbishing process can be accessed [here](link to refurbishing details).</p>
                    <h4 className=" text-uppercase mt-4">Warranty</h4>
                    <p>Explore the specifics of our comprehensive jewelry warranty <a href="/lifetime-manufacturer-warranty" className="text-underline">here</a>. Please familiarize yourself with our detailed Terms and Conditions for returns <a href="/terms-conditions" className="text-underline">here</a>.</p>

                    <h4 className=" text-uppercase mt-4">Return Instructions</h4>
                    <ul>
                        <li>Kindly inform our esteemed sales consultant about your return request.</li>
                        <li>Safely place the jewelry within a POINTERS branded jewelry box and a secure delivery box.</li>
                        <li>Seal the delivery box with an official POINTERS security sticker, and provide us with a corresponding image.</li>
                        <li>Await the scheduled courier pickup.</li>
                    </ul>
                    <p>*Note: For returns related to resizing and refurbishing, the submission of the invoice, receipt, and diamond certificate is not required.</p>


                    <h4 className=" text-uppercase mt-4">Return Shipping Charges</h4>
                    <p>Reference our discerning policy on Return Shipping Charges [here](link to return shipping charges).</p>


                    <h4 className=" text-uppercase mt-4">Refund Policy</h4>
                    <p className="mb-0">At POINTERS, we uphold the unwavering quality of our products, allowing customers to request a refund under the following conditions:</p>
                    <ul>
                        <li>Discrepancy between the GIA number inscribed on the diamond and its GIA certificate.</li>
                    </ul>
                    <p className="mb-0">Non-attainment of High Light Performance in the GemEx certificate for LUMOS by POINTERS Diamond.</p>
                    <ul>
                        <li>Explore the complete and nuanced Terms and Conditions for refunds <a href="/terms-conditions#return_refund" className="text-underline">here</a>.</li>
                    </ul>

                    <h4 className=" text-uppercase mt-4">Cancellation Policy</h4>
                    <p>Customers retain the flexibility to cancel or modify any order before the initiation of payment. Post partial or full payment, cancellation or refund requests will not be accommodated.</p>

                </div>
            </div>
        </>
    )
}

export default ReturnPolicy