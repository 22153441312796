import React from 'react';
import "./headerbreadcrumb.css";
import { Breadcrumb } from 'react-bootstrap';

function HeaderBreadcrumb({ activePage, breadcrumbs }) {

  const isActivePage = (idx) => {
    if (idx === breadcrumbs.length - 1) return true;
    else return false;
  }

  return (
    <div className="breadcrumb-area">
      <div className="container py-2">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          {
            breadcrumbs?.map((breadcrumb, idx) => (

              isActivePage(idx) ? (<Breadcrumb.Item active>{breadcrumb?.label}</Breadcrumb.Item>) : (<Breadcrumb.Item href={breadcrumb?.url}>{breadcrumb?.label}</Breadcrumb.Item>)

            ))
          }

        </Breadcrumb>
      </div>
    </div>
  )
}

export default HeaderBreadcrumb