import React from 'react';
import "./ourStory.css";
import { Col, Row } from 'react-bootstrap';
import MetaInformation from '../../components/metainformation/MetaInformation';


function OurStory() {
    const headerinfo = {
        title: "About Pointers | Pointers Jewellers",
        description: "Pointers Jewellers (M) Sdn Bhd, (Pointers) is a jeweler and specialty retailer; whose merchandise offerings include customized jewelry, corporate and employee gifts, special events and extensive selection of jewelry for all our customers.",
        keyword: "",
        author: "",
        og: {
            title: "About Pointers | Pointers Jewellers",
            description: "Pointers Jewellers (M) Sdn Bhd, (Pointers) is a jeweler and specialty retailer; whose merchandise offerings include customized jewelry, corporate and employee gifts, special events and extensive selection of jewelry for all our customers.",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    return (
        <><MetaInformation headerinfo={headerinfo} /><div className="page-container">
            <div className="banner-ourstory" style={{
                backgroundImage: `url("../../pointers/ourstory/pointers-ourstory-banner.png")`
            }}>
                <div className="banner-content left">
                    <h2><span>POINTERS JEWELLERS</span></h2>
                    <div>IS A MALAYSIA-BASED</div>
                    <div>CUSTOM JEWELLERY BRAND THAT LAUNCHED</div>
                    <div>ITS STORE IN EARLY 1992 AND UNVEILED</div>
                    <div>ITS FIRST FLAGSHIP BOUTIQUE AT SUBANG JAYA!</div>
                </div>
            </div>
            <div className="container ourstory-content pt-5">
                <h1 className="text-center  mb-5">
                    ABOUT POINTERS
                </h1>
                <div className="row-about">
                    <div className="col-about">
                        <div className="card-about">
                            <img alt="CUSTOMIZATION" src="../../pointers/ourstory/pointers-ourstory-icon-customization.png" />
                            <h4 className="text-about">
                                CUSTOMIZATION<br />&nbsp;
                            </h4>
                        </div>

                    </div>
                    <div className="col-about">

                        <div className="card-about">
                            <img alt="ONE-STOP SOLUTION" src="../../pointers/ourstory/pointers-ourstory-icon-solution.png" style={{ maxWidth: "80px" }} />
                            <h4 className="text-about">
                                ONE-STOP<br />SOLUTION
                            </h4>
                        </div>
                    </div>
                    <div className="col-about">
                        <div className="card-about">
                            <img alt="JEWELLERY UPGRADE & REDESIGN" src="../../pointers/ourstory/pointers-ourstory-icon-upgrade.png" />
                            <h4 className="text-about">
                                JEWELLERY <br />UPGRADE & REDESIGN
                            </h4>

                        </div>
                    </div>
                </div>

                <div className="m-5 text-center">

                    <p>Pointers Jewellers (M) Sdn Bhd, (Pointers) is a jeweler and specialty retailer; whose merchandise offerings include customized jewelry, corporate and employee gifts, special events and extensive selection of jewelry for all our customers. We engage ourselves in customized product design, manufacturing and retailing activities.</p>
                    <p>Pointers was founded in 1992 by Johnny Yong Boon Chon when he chose offer personal statement to customers who wears the jewelry he designed, all while improving his family condition. Till today, Pointers has served countless customers from all social classes, corporates and organisations, charities and the royalties, all regardless of their social status, gender and age.</p>
                    <p>We are honour and proud to present ourselves as the member of the Federation of Goldsmiths And Jewellers Association of Malaysia (FGJAM). We also participate in oversea jewelry exhibitions, to share our passion in luxury and our story across the country.</p>
                    <p>Pointers is renowned for our professional workmanship and designs that portray quality, service, prestige, and gracious style. The jewelries we made are in line with characteristic of timeless, exclusive and meant to be passed down as legacy.</p>
                </div>
                <div className="row-vision">
                    <div className="col-vision" style={{
                        backgroundImage: `url("../../pointers/ourstory/pointers-ourstory-scopeofwork.png")`
                    }}>

                    </div>
                    <div className="col-vision">
                        <div className="p-5">
                            <h4>SCOPE OF WORK</h4>
                            <ul>
                                <li>Pointers is Specialty Store Retailer of Private Label Apparel (SPA) that is able to customize and satisfy customer request in the shortest amount of time.</li>
                                <li>Maintain good relationship with our suppliers and always take extra measure in ensuring the quality of goods acquired.</li>
                                <li>Keep customers abreast on the activities, events, latest designs and promotions by Pointers.</li>
                                <li>Strongly monitoring the process of customized products ordered by customers, inspects and disapproves any irregularities before is delivered into customers’ hand.</li>
                                <li>Staying financially healthy with minimum liabilities, reduce trade debtors, maintain positive cash flow and controls expenditures.</li>
                                <li>Provide one-to-one design and immediate attentive repair service to customers who visit our company.</li>
                                <li>Provide support and advice to customers whom in need of assistance in their jewelry care and couture their fashion.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row-vision reverse">
                    <div className="col-vision" style={{
                        backgroundImage: `url("../../pointers/ourstory/pointers-ourstory-strategy.png")`
                    }}>

                    </div>
                    <div className="col-vision">
                        <div className="p-5">
                            <h4>THE COMPANY’S KEY STRATEGIES</h4>
                            <ul>
                                <li>To enhance customer awareness of the Pointers, its DNA, its products and its association with quality, service and luxury.</li>
                                <li>To maintain an active product development program.To enhance the customer experience with superior customer service to fulfill the believe of “exceptional good can be for ordinary people”</li>
                                <li>To expand the company with strong company image, while controlling the quality and services Pointers has been providing.</li>
                                <li>To increasingly provide quality that exceeds the expectations of our esteemed customers.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row-vision">
                    <div className="col-vision" style={{
                        backgroundImage: `url("../../pointers/ourstory/pointers-ourstory-craftmanship.png")`
                    }}>

                    </div>
                    <div className="col-vision">
                        <div className="p-5 text-center">
                            <h4>EXQUISITE CRAFTMANSHIP</h4>
                            <p>Pointers is synonymous with unrivalled quality and craftmanship and has always demonstrated an uncompromising dedication in maintaining the highest industry standard.</p>
                        </div>
                    </div>
                </div>
                <h1 className="text-center  mb-5 mt-5">
                    AVAILABLE PRODUCTS & SERVICES
                </h1>
                <Row className="row-services mb-5">
                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="PURE FACTORY CUSTOM MAKE" src="../../pointers/ourstory/pointers-icon-png_jpg-26.png" />

                        <div className=" text-services">PURE FACTORY CUSTOM MAKE</div>
                    </Col>

                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="DIAMOND GIA, HRD & IGI" src="../../pointers/ourstory/pointers-icon-png_jpg-27.png" />

                        <div className=" text-services">DIAMOND GIA, HRD & IGI</div>
                    </Col>

                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="RUBY, SAPPHIRE, EMERALD, JADE & PEARL" src="../../pointers/ourstory/pointers-icon-png_jpg-28.png" />

                        <div className=" text-services">RUBY, SAPPHIRE, EMERALD, JADE & PEARL</div>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="HIGH GRADE PLATINUM & 18K JEWELLERY" src="../../pointers/ourstory/pointers-icon-png_jpg-29.png" />

                        <div className=" text-services">HIGH GRADE PLATINUM & 18K JEWELLERY</div>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="999 & 916 GOLD JEWELLERY" src="../../pointers/ourstory/pointers-icon-png_jpg-30.png" />

                        <div className=" text-services">999 & 916 GOLD JEWELLERY</div>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="text-center col-services">
                        <img alt="INSTANCE FACTORY REPAIR" src="../../pointers/ourstory/pointers-icon-png_jpg-31.png" />

                        <div className=" text-services">INSTANCE FACTORY REPAIR</div>
                    </Col>
                </Row>
            </div>
        </div></>
    )
}

export default OurStory