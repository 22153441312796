import React, { useState, useEffect, useRef } from "react";

import "./diamondWizard.css";
import HeaderBreadcrumb from "../../components/headerbreadcrumb/HeaderBreadcrumb";
import { Col, Row, Form, Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Close,
  Fullscreen,
  Search,
  ThreeSixtyOutlined,
} from "@material-ui/icons";
import { Slider } from "@material-ui/core";
import classNames from "classnames";

import {
  shapesfilters,
  colorsfilters,
  clarityfilters,
  cutsfilters,
  polishfilters,
  symmetryfilters,
  fluorescencefilters,
  diamondtypefilters,
  idealcutfilters,
  diamondlabfilters,
} from "../../json/diamondfilters";
import RingDiamondStep from "../../components/ringdiamondsteps/RingDiamondStep";
import MetaInformation from "../../components/metainformation/MetaInformation";
import { useParams } from "react-router-dom";
import useAuthorizationToken from "../../auth/useAuthorizationToken";
import { Info } from "@mui/icons-material";

function DiamondWizard({ setLoading }) {
  const { getAccessToken } = useAuthorizationToken();
  const headerinfo = {
    title: "Diamond | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
      title: "Diamond | Pointers Jewellers",
      description: "",

      image: window.location.origin + "/pointers/pointers_logo_social.png",
      url: window.location.href,
    },
  };
  let params = useParams();
  // const [loading, setLoading] = useState(false);
  const caratMinMax = [0.18, 10.0];
  const priceMinMax = [0.0, 200000.0];

  const shouldRunEffect = useRef(true);
  const [diamondtype, setDiamondType] = useState();
  const [shapes, setShapes] = useState([]);
  const [colors, setColors] = useState([]);
  const [labs, setLabs] = useState([]);
  const [clarities, setClarities] = useState([]);
  const [idealCut, setIdealCut] = useState();
  const [cuts, setCuts] = useState([]);
  const [polishs, setPolishs] = useState([]);
  const [symmetries, setSymmetries] = useState([]);
  const [fluorescences, setFluorescences] = useState([]);
  const [caratMin, setCaratMin] = useState(caratMinMax[0]);
  const [caratMax, setCaratMax] = useState(caratMinMax[1]);
  const [priceMin, setPriceMin] = useState(priceMinMax[0]);
  const [priceMax, setPriceMax] = useState(priceMinMax[1]);
  const [showAdvSearch, setShowAdvSearch] = useState(false);

  const [priceInputType, setPriceInputType] = useState("text");
  const [diamondsList, setDiamondsList] = useState([]);
  const [diamondDetail, setDiamondDetail] = useState();
  // const [totalRows, setTotalRows] = useState();
  const perPage = 1000;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showDiamondModal, setShowDiamondModal] = useState(false);
  const [showDiamondImageModal, setShowDiamondImageModal] = useState(false);

  const handleDiamondModalClose = () => setShowDiamondModal(false);
  const handleDiamondModalShow = () => setShowDiamondModal(true);

  const handleDiamondImageModalClose = () => setShowDiamondImageModal(false);
  const handleDiamondImageModalShow = () => setShowDiamondImageModal(true);

  const saveFilterToStorage = () => {
    var row = {
      shape: shapes,
      color: colors,
      lab: labs,
      clarity: clarities,
      cut: cuts,
      polish: polishs,
      symmetry: symmetries,
      fluorescence: fluorescences,
      carat: [caratMin, caratMax], //caratMin + "-" + caratMax,
      totalprice_min: priceMin,
      totalprice_max: priceMax,
      diamondtype: diamondtype
    };
    sessionStorage.setItem("_pointersdiamondfilters", JSON.stringify(row));
  }

  // window.addEventListener("beforeunload", saveFilterToStorage);

  useEffect(() => {


    async function resetAllFilters() {
      var shape = [];
      var prevfilters = sessionStorage.getItem("_pointersdiamondfilters") ? JSON.parse(sessionStorage.getItem("_pointersdiamondfilters")) : {};
      // var color = [];
      // var clarity = [];
      // var cut = [];
      // var polish = [];
      // var symmetry = [];
      // var fluorescence = [];
      var linkshape = null;
      var linktype = null;
      if (params?.shape) {
        if (params?.shape !== "all-shapes") {
          linkshape = shapesfilters.filter((shapefilter) => {
            return shapefilter.label.toLowerCase() === params?.shape;
          });
          if (linkshape[0]) {
            shape.push(linkshape[0]?.value);
          }
        } else {
          shapesfilters.forEach((shapefilter) => {
            shape.push(shapefilter?.value);
          });
        }
        setShapes(shape);
      } else if (prevfilters?.shape?.length > 0) {
        setShapes(prevfilters?.shape);
      }
      if (params?.type) {
        linktype = diamondtypefilters.filter((diamondtypefilter) => {
          return (
            diamondtypefilter.url.toLowerCase() === params?.type?.toLowerCase()
          );
        });
      }
      if (!linktype) {
        linktype = diamondtypefilters;
        setDiamondType(linktype[0]?.value);
      } else if (prevfilters?.diamondtype) {
        setDiamondType(prevfilters?.diamondtype);
      } else {
        setDiamondType(linktype[0]?.value);
      }
      console.log(prevfilters);
      if (!prevfilters?.cut
        && !prevfilters?.polish
        && !prevfilters?.symmetry
        && !prevfilters?.fluorescence) {
        var defaultidealtype = idealcutfilters?.filter((idealcut) => {
          return idealcut?.value === "3EX";
        });
        console.log("No FIlter");
        setIdealCut(defaultidealtype[0]?.value);
        setCuts(defaultidealtype[0]?.preselect?.cut);
        setPolishs(defaultidealtype[0]?.preselect?.polish);
        setSymmetries(defaultidealtype[0]?.preselect?.symmetry);
        setFluorescences(defaultidealtype[0]?.preselect?.fluorescence);
      } else {
        if (prevfilters?.color?.length > 0) setColors(prevfilters?.color);
        if (prevfilters?.lab?.length > 0) setLabs(prevfilters?.lab);
        if (prevfilters?.clarity?.length > 0) setClarities(prevfilters?.clarity);
        if (prevfilters?.cut?.length > 0) setCuts(prevfilters?.cut);
        if (prevfilters?.polish?.length > 0) setPolishs(prevfilters?.polish);
        if (prevfilters?.symmetry?.length > 0) setSymmetries(prevfilters?.symmetry);
        if (prevfilters?.fluorescence?.length > 0) setFluorescences(prevfilters?.fluorescence);
        if (prevfilters?.carat[0] > 0.00) setCaratMin(prevfilters?.carat[0]);
        if (prevfilters?.carat[1] > 0.00) setCaratMax(prevfilters?.carat[1]);
        if (prevfilters?.totalprice_min > 0.00) setPriceMin(prevfilters?.totalprice_min);
        if (prevfilters?.totalprice_max > 0.0) setPriceMax(prevfilters?.totalprice_max);
      }
      // setDiamondType(linktype[0]?.value);
      // setShapes(shape);
      // setColors(color);
      // setClarities(clarity);
      // setCuts(cut);
      // setPolishs(polish);
      // setSymmetries(symmetry);
      // setFluorescences(fluorescence);
    }
    if (shouldRunEffect.current) {

      resetAllFilters();
      sessionStorage.removeItem("_pointersdiamond");

      if (!getAccessToken()) {
        sessionStorage.removeItem("_pointerredirect");
        sessionStorage.setItem(
          "_pointerredirect",
          window.location.pathname !== "/login"
            ? window.location.pathname
            : "/profile"
        );
      }

      shouldRunEffect.current = false;
    }
  }, [params, getAccessToken]);

  const selectAllFilters = () => {
    var shape = [];
    var color = [];
    var clarity = [];
    var cut = [];
    var polish = [];
    var symmetry = [];
    var fluorescence = [];
    var lab = [];

    shapesfilters.forEach((item) => {
      shape.push(item.value);
    });

    colorsfilters.forEach((item) => {
      color.push(item.value);
    });
    clarityfilters.forEach((item) => {
      clarity.push(item.value);
    });
    cutsfilters.forEach((item) => {
      cut.push(item.value);
    });
    polishfilters.forEach((item) => {
      polish.push(item.value);
    });
    symmetryfilters.forEach((item) => {
      symmetry.push(item.value);
    });
    fluorescencefilters.forEach((item) => {
      fluorescence.push(item.value);
    });
    diamondlabfilters.forEach((item) => {
      lab.push(item.value);
    });

    setShapes(shape);
    setColors(color);
    setClarities(clarity);
    setCuts(cut);
    setPolishs(polish);
    setSymmetries(symmetry);
    setFluorescences(fluorescence);
    setLabs(lab);
  };

  const handleDiamondTypeChange = (event, newValue) => {
    setDiamondType(newValue);
    // handleResetFiltersClick();
    setDiamondDetail(null);
    setDiamondsList([]);
    setCurrentPage(1);
    setCurrentPage(1);
    // setTotalRows(null);
  };

  const handleCaratSliderChange = (event, newValue) => {
    setCaratMin(newValue[0]);
    setCaratMax(newValue[1]);
  };

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handleCaratMinChange = (e) => {
    var value = parseFloat(e.target.value);
    setCaratMin(value);
  };

  const handleCaratMinBlur = (e) => {
    var value = parseFloat(e.target.value);
    if (value < 0) value = -value;
    if (value > caratMinMax[1]) value = caratMinMax[1];
    if (value < caratMinMax[0]) value = caratMinMax[0];
    setCaratMin(value);
  };

  const handleCaratMaxChange = (e) => {
    var value = parseFloat(e.target.value);
    setCaratMax(value);
  };

  const handleCaratMaxBlur = (e) => {
    var value = parseFloat(e.target.value);
    if (value < 0) value = -value;
    if (value > caratMinMax[1]) value = caratMinMax[1];
    if (value < caratMinMax[0]) value = caratMinMax[0];
    setCaratMax(value);
  };

  const handlePriceSliderChange = (event, newValue) => {
    setPriceMin(newValue[0]);
    setPriceMax(newValue[1]);
  };

  const formatPriceWithComma = (price) => {
    if (price) return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return 0.0;
  };

  const handlePriceMinChange = (e) => {
    var value = parseFloat(e.target.value?.replace(",", ""));
    setPriceMin(value < 0 ? -value : value);
  };

  const handlePriceMaxChange = (e) => {
    var value = parseFloat(e.target.value?.replace(",", ""));

    setPriceMax(value < 0 ? -value : value);
  };

  const handlePriceMinFocus = (e) => {
    setPriceInputType("number");
  };

  const handlePriceMaxFocus = (e) => {
    setPriceInputType("number");
  };

  const handlePriceMinBlur = (e) => {
    setPriceInputType("text");
    var value = parseFloat(e.target.value?.replace(",", ""));
    if (value <= priceMinMax[0]) {
      value = priceMinMax[0];
    } else if (value >= priceMinMax[1]) {
      value = priceMinMax[1];
    }

    if (value > priceMax) {
      setPriceMin(priceMax < 0 ? -priceMax : priceMax);
      setPriceMax(value < 0 ? -value : value);
    } else {
      setPriceMin(value < 0 ? -value : value);
    }
  };

  const handlePriceMaxBlur = (e) => {
    setPriceInputType("text");
    var value = parseFloat(e.target.value?.replace(",", ""));
    // if (/^\d+(\.\d*)?$/.test(value)) {
    if (value <= priceMinMax[0]) {
      value = priceMinMax[0];
    } else if (value >= priceMinMax[1]) {
      value = priceMinMax[1];
    }

    if (value < priceMin) {
      setPriceMax(priceMin < 0 ? -priceMin : priceMin);
      setPriceMin(value < 0 ? -value : value);
    } else {
      setPriceMax(value < 0 ? -value : value);
    }
    // } else {
    //   alert("Please enter the valid price.");
    // }
  };

  const handleShapesChange = (e, shape) => {
    var curShapes = [...shapes];
    const index = curShapes.indexOf(shape);
    if (index > -1) {
      // only splice array when item is found
      curShapes.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curShapes.push(shape);
    }
    setShapes(curShapes);
  };

  const handleColorsChange = (e, color) => {
    var curColors = [...colors];
    const index = curColors.indexOf(color);
    if (index > -1) {
      // only splice array when item is found
      curColors.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curColors.push(color);
    }
    setColors(curColors);
  };

  const handleClaritiesChange = (e, clarity) => {
    var curclarities = [...clarities];
    const index = curclarities.indexOf(clarity);
    if (index > -1) {
      // only splice array when item is found
      curclarities.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curclarities.push(clarity);
    }
    setClarities(curclarities);
  };

  const handleLabsChange = (e, lab) => {
    var curLabs = [...labs];
    const index = curLabs.indexOf(lab);
    if (index > -1) {
      // only splice array when item is found
      curLabs.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curLabs.push(lab);
    }
    setLabs(curLabs);
  };

  const handleIdealCutChange = (e, selectedidealcut) => {
    var curIdealCut = idealCut;
    if (curIdealCut === selectedidealcut?.value) {
      // only splice array when item is found
      curIdealCut = "";

      setCuts([]);
      setPolishs([]);
      setSymmetries([]);
      setFluorescences([]);
    } else {
      curIdealCut = selectedidealcut?.value;
      setCuts(selectedidealcut?.preselect?.cut);
      setPolishs(selectedidealcut?.preselect?.polish);
      setSymmetries(selectedidealcut?.preselect?.symmetry);
      setFluorescences(selectedidealcut?.preselect?.fluorescence);
    }
    setIdealCut(curIdealCut);
  };

  const handleCutsChange = (e, cut) => {
    var curcuts = [...cuts];
    const index = curcuts.indexOf(cut);
    if (index > -1) {
      // only splice array when item is found
      curcuts.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curcuts.push(cut);
    }
    setCuts(curcuts);
  };
  const handlePolishsChange = (e, polish) => {
    var curpolishs = [...polishs];
    const index = curpolishs.indexOf(polish);
    if (index > -1) {
      // only splice array when item is found
      curpolishs.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curpolishs.push(polish);
    }
    setPolishs(curpolishs);
  };
  const handleSymmetriesChange = (e, symmetry) => {
    var cursymmetries = [...symmetries];
    const index = cursymmetries.indexOf(symmetry);
    if (index > -1) {
      // only splice array when item is found
      cursymmetries.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      cursymmetries.push(symmetry);
    }
    setSymmetries(cursymmetries);
  };
  const handleFluorescencesChange = (e, fluorescence) => {
    var curfluorescences = [...fluorescences];
    const index = curfluorescences.indexOf(fluorescence);
    if (index > -1) {
      // only splice array when item is found
      curfluorescences.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      curfluorescences.push(fluorescence);
    }
    setFluorescences(curfluorescences);
  };

  const fetchData = async (page, per_page, isresset = false) => {
    var api =
      `${process.env.REACT_APP_API_ENDPOINT}/diamond/list/` + diamondtype;
    if (page > 1) {
      api += "?page=" + page;
    }
    var dapiheader = {
      "Content-Type": "application/json",
      "X-Channel-ID": "pointers-web",
      "X-Request-ID":
        "POINTERS_WEB" +
        Date.now() +
        (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
          .toString()
          .padStart(7, "0"),
    };

    var defaultidealtype = idealcutfilters?.filter((idealcut) => {
      return idealcut?.value === "3EX";
    });

    var dformdata = {
      shape: shapes.join(","),
      color: colors.join(","),
      lab: labs.join(","),
      clarity: clarities.join(","),
      cut: cuts.join(","),
      polish: showAdvSearch ? polishs.join(",") : defaultidealtype[0]?.preselect?.polish.join(","),
      symmetry: showAdvSearch ? symmetries.join(",") : defaultidealtype[0]?.preselect?.symmetry.join(","),
      fluorescence: showAdvSearch ? fluorescences.join(",") : defaultidealtype[0]?.preselect?.fluorescence.join(","),
      carat: [caratMin, caratMax], //caratMin + "-" + caratMax,
      totalprice_min: priceMin ? priceMin : 0.0,
      totalprice_max: priceMax ? priceMax : 0.0,
    };
    setLoading(true);

    await fetch(api, {
      method: "POST",
      headers: dapiheader,
      body: JSON.stringify(dformdata),
    })
      .then((response) => response.json())
      .then((diamondslistresponse) => {
        setLoading(false);
        if (diamondslistresponse?.success === true) {
          var curDiamondsList = null;
          if (isresset) {
            curDiamondsList = [...diamondslistresponse?.data];
          } else {
            curDiamondsList = [...diamondsList, ...diamondslistresponse?.data];
          }
          setDiamondsList(curDiamondsList);
          // setTotalRows(diamondslistresponse?.total);
          setCurrentPage(page);
          if (curDiamondsList.length < diamondslistresponse?.total - 1) {
            setIsLoaded(true);
          } else {
            setIsLoaded(false);
          }
          // document.getElementById('title_search_result').scrollIntoView({ behavior: 'smooth' });
        } else {
          alert(diamondslistresponse?.message);
        }
      })
      .catch((error) => console.log(error));

  };

  const handleDiamondDetailModalOpen = async (row) => {
    var api =
      `${process.env.REACT_APP_API_ENDPOINT}/diamond/` + row?.CERTIFICATE_NO;
    var dapiheader = {
      "Content-Type": "application/json",
      "X-Channel-ID": "pointers-web",
      "X-Request-ID":
        "POINTERS_WEB" +
        Date.now() +
        (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
          .toString()
          .padStart(7, "0"),
    };
    await fetch(api, {
      method: "GET",
      headers: dapiheader,
    })
      .then((response) => response.json())
      .then((diamonddetailresponse) => {
        if (diamonddetailresponse?.success === true) {
          setDiamondDetail(diamonddetailresponse?.data);
        }
      })
      .catch((error) => console.log(error));

    // setDiamondDetail(row);
    handleDiamondModalShow();
  };

  const handleDiamondImageModalOpen = async (row) => {
    var api =
      `${process.env.REACT_APP_API_ENDPOINT}/diamond/` + row?.CERTIFICATE_NO;
    var dapiheader = {
      "Content-Type": "application/json",
      "X-Channel-ID": "pointers-web",
      "X-Request-ID":
        "POINTERS_WEB" +
        Date.now() +
        (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
          .toString()
          .padStart(7, "0"),
    };
    await fetch(api, {
      method: "GET",
      headers: dapiheader,
    })
      .then((response) => response.json())
      .then((diamonddetailresponse) => {
        if (diamonddetailresponse?.success === true) {
          setDiamondDetail(diamonddetailresponse?.data);
        }
      })
      .catch((error) => console.log(error));

    // setDiamondDetail(row);
    handleDiamondImageModalShow();
  };

  const handleBtnSearchClick = async (e) => {
    fetchData(1, perPage, true);
  };

  const handleAdvSearchClick = (e, show) => {
    setShowAdvSearch(show);
    
  }

  const handleChooseThisDiamondOnClick = async (row) => {
    saveFilterToStorage();
    console.log(row);
    var api =
      `${process.env.REACT_APP_API_ENDPOINT}/diamond/` + row?.CERTIFICATE_NO;
    var dapiheader = {
      "Content-Type": "application/json",
      "X-Channel-ID": "pointers-web",
      "X-Request-ID":
        "POINTERS_WEB" +
        Date.now() +
        (Math.floor(Math.random() * (9999999 - 1 + 1)) + 1)
          .toString()
          .padStart(7, "0"),
    };
    await fetch(api, {
      method: "GET",
      headers: dapiheader,
    })
      .then((response) => response.json())
      .then((diamonddetailresponse) => {
        if (diamonddetailresponse?.success === true) {
          row.CERTIFICATE_NO = diamonddetailresponse?.data?.CERTIFICATE_NO;
          setDiamondDetail(row);
          sessionStorage.setItem("_pointersdiamond", JSON.stringify(row));

          var setting = sessionStorage.getItem("_pointerssetting")
            ? JSON.parse(sessionStorage.getItem("_pointerssetting"))
            : null;
          if (!setting?.slug) {
            window.location.replace("/products/engagementring");
          } else {
            if (!getAccessToken()) {

              sessionStorage.removeItem("_pointerredirect");
              sessionStorage.setItem("_pointerredirect", window.location.pathname !== "/login" ? "/wizard-completed" : "/profile");
              window.location.replace("/login");
            } else {
              window.location.replace("/wizard-completed");
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb
        breadcrumbs={[
          {
            url: "",
            label: "Diamond",
          },
        ]}
      />
      <RingDiamondStep currrent_step={2} />
      <div className="choose-diamond-content">
        <div className="container mb-5">
          <div className="text-diamond-type">
            {diamondtypefilters
              .filter((diamondtypefilter) => {
                return diamondtype === diamondtypefilter.value;
              })
              .map((diamondtypefilter, index) => (
                <>
                  <h1 className=" text-uppercase text-center">
                    {diamondtypefilter.label}
                  </h1>
                  <p className="h1desc">
                    <div>{diamondtypefilter.desc}</div>
                  </p>
                </>
              ))}
          </div>
          <div className="section text-diamond-type">
            {diamondtypefilters.map((diamondtypefilter, index) => (
              <div
                className={classNames({
                  "btn-shape": true,
                  active: diamondtype === diamondtypefilter.value,
                })}
                onClick={(e) =>
                  handleDiamondTypeChange(e, diamondtypefilter.value)
                }
              >
                <div>{diamondtypefilter.label}</div>
              </div>
            ))}

            <div className="clearfix"></div>
          </div>
          <div className="text-shape">
            <strong>Shape</strong>
          </div>
          <div className="section text-shape">
            {shapesfilters.map((shapefilter, index) => (
              <div
                className={classNames({
                  "btn-shape": true,
                  active: shapes.includes(shapefilter.value),
                })}
                onClick={(e) => handleShapesChange(e, shapefilter.value)}
              >
                <img alt="Round" src={shapefilter.img} className="img-fluid" />
                <div>{shapefilter.label}</div>
              </div>
            ))}

            <div className="clearfix"></div>
          </div>
          <Row>
            <Col sm="6">
              <strong>Carat <OverlayTrigger placement="right" overlay={
                <Tooltip>
                  Prioritize size over quality if budget is a concern.
                </Tooltip>
              }>
                <Info className="icon-info" />
              </OverlayTrigger></strong>
              <div className="section">
                <Slider
                  value={[caratMin, caratMax]}
                  onChange={handleCaratSliderChange}
                  min={caratMinMax[0]}
                  max={caratMinMax[1]}
                  step={0.01}
                  valueLabelDisplay="auto"
                />
                <Form.Control
                  type="number"
                  value={caratMin}
                  min={caratMinMax[0]}
                  max={caratMinMax[1]}
                  // onBlur={handleCaratMinBlur}
                  onChange={handleCaratMinChange}
                  onFocus={handleInputFocus}
                  onBlur={handleCaratMinBlur}
                  className="txtCaratRange left"
                />
                <Form.Control
                  type="number"
                  value={caratMax}
                  min={caratMinMax[0]}
                  max={caratMinMax[1]}
                  onFocus={handleInputFocus}
                  onBlur={handleCaratMaxBlur}
                  onChange={handleCaratMaxChange}
                  className="txtCaratRange right"
                />

                <div className="clearfix"></div>
              </div>
            </Col>
            <Col sm="6">
              <strong>Diamond Price (MYR)</strong>
              <div className="section">
                <Slider
                  value={[priceMin ? priceMin : 0.0, priceMax ? priceMax : 0.0]}
                  onChange={handlePriceSliderChange}
                  min={priceMinMax[0]}
                  max={priceMinMax[1]}
                  step={0.01}
                  valueLabelDisplay="auto"
                />
                <Form.Control
                  type={priceInputType}
                  value={
                    priceInputType === "text"
                      ? formatPriceWithComma(priceMin)
                      : priceMin
                  }
                  onChange={handlePriceMinChange}
                  onFocus={handlePriceMinFocus}
                  onBlur={handlePriceMinBlur}
                  min={priceMinMax[0]}
                  max={priceMinMax[1]}
                  className="txtPriceRange left"
                />
                <Form.Control
                  type={priceInputType}
                  value={
                    priceInputType === "text"
                      ? formatPriceWithComma(priceMax)
                      : priceMax
                  }
                  onChange={handlePriceMaxChange}
                  onFocus={handlePriceMaxFocus}
                  onBlur={handlePriceMaxBlur}
                  min={priceMinMax[0]}
                  max={priceMinMax[1]}
                  className="txtPriceRange right"
                />
                <div className="clearfix"></div>
              </div>
            </Col>
            <Col sm="12">
              <Row>
                <Col sm="6">
                  <div className="clearfix"></div>
                  <strong>Color <OverlayTrigger placement="right" overlay={
                    <Tooltip>
                      Aim for near colorless (D-F) for a diamond that appears pure.
                    </Tooltip>
                  }>
                    <Info className="icon-info" />
                  </OverlayTrigger></strong>
                  <div className="section">
                    {colorsfilters.map((colorfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: colors.includes(colorfilter.value),
                        })}
                        onClick={(e) => handleColorsChange(e, colorfilter.value)}
                      >
                        <div>{colorfilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                </Col>
                <Col sm="6">
                  <strong>Cut <OverlayTrigger placement="right" overlay={
                    <Tooltip>
                      Ideal and Excellent cut grade enhances sparkle
                    </Tooltip>
                  }>
                    <Info className="icon-info" />
                  </OverlayTrigger>
                  </strong>
                  <div className="section">
                    {cutsfilters.map((cutfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: cuts.includes(cutfilter.value),
                        })}
                        onClick={(e) => handleCutsChange(e, cutfilter.value)}
                      >
                        <div>{cutfilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                  <strong>Clarity <OverlayTrigger placement="right" overlay={
                    <Tooltip>
                      Fewer imperfections (VS2 or higher) offer better brilliance over price
                    </Tooltip>
                  }>
                    <Info className="icon-info" />
                  </OverlayTrigger>

                  </strong>
                  <div className="section">
                    {clarityfilters.map((clarityfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: clarities.includes(clarityfilter.value),
                        })}
                        onClick={(e) =>
                          handleClaritiesChange(e, clarityfilter.value)
                        }
                      >
                        <div>{clarityfilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm="12" className={classNames({
              "d-block": showAdvSearch,
              "d-none": !showAdvSearch
            })}>
              <Row>
                <Col sm="12">
                  <div className="text-ideal-cut">
                    <strong>Ideal Cut</strong>
                    <div className="section">
                      {idealcutfilters.map((idealcutfilter, index) => (
                        <div
                          className={classNames({
                            "btn-shape": true,
                            active: idealCut === idealcutfilter.value,
                          })}
                          onClick={(e) => handleIdealCutChange(e, idealcutfilter)}
                        >
                          <div>{idealcutfilter.label}</div>
                        </div>
                      ))}

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </Col>
                <Col sm="6" md="3">
                  <strong>Polish</strong>
                  <div className="section">
                    {polishfilters.map((polishfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: polishs.includes(polishfilter.value),
                        })}
                        onClick={(e) => handlePolishsChange(e, polishfilter.value)}
                      >
                        <div>{polishfilter.label}</div>
                      </div>
                    ))}
                    <div className="clearfix"></div>
                  </div>


                </Col>
                <Col sm="6" md="3">
                  <strong>Symmetry</strong>
                  <div className="section">
                    {symmetryfilters.map((symmetryfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: symmetries.includes(symmetryfilter.value),
                        })}
                        onClick={(e) =>
                          handleSymmetriesChange(e, symmetryfilter.value)
                        }
                      >
                        <div>{symmetryfilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                </Col>
                <Col sm="6" md="3">
                  <strong>Fluorescence</strong>
                  <div className="section">
                    {fluorescencefilters.map((fluorescencefilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: fluorescences.includes(fluorescencefilter.value),
                        })}
                        onClick={(e) =>
                          handleFluorescencesChange(e, fluorescencefilter.value)
                        }
                      >
                        <div>{fluorescencefilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>

                </Col>
                <Col sm="6" md="3">
                  <strong>Certificate</strong>
                  <div className="section">
                    {diamondlabfilters.map((diamondlabfilter, index) => (
                      <div
                        className={classNames({
                          "btn-shape": true,
                          active: labs.includes(diamondlabfilter.value),
                        })}
                        onClick={(e) => handleLabsChange(e, diamondlabfilter.value)}
                      >
                        <div>{diamondlabfilter.label}</div>
                      </div>
                    ))}

                    <div className="clearfix"></div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="text-center">
              <Button
                type="button"
                variant="primary"
                size="lg"
                className="btn-red text-uppercase"
                onClick={(e) => handleBtnSearchClick(e)}
              >
                <Search /> Search
              </Button>
              {
                showAdvSearch ? (<div
                  className="link-reset mt-2"
                  onClick={e => handleAdvSearchClick(e, false)}
                >
                  Hide Filter
                </div>) : (
                  <div
                    className="link-reset mt-2"
                    onClick={e => handleAdvSearchClick(e, true)}
                  >
                    Advanced Filter <OverlayTrigger placement="right" overlay={
                      <Tooltip>For Professional User
                      </Tooltip>
                    }>
                      <Info className="icon-info" />
                    </OverlayTrigger>
                  </div>
                )
              }

              <div className="link-reset mt-2" onClick={selectAllFilters}>
                Select All
              </div>
            </Col>
          </Row>
          <div className="clearfix"></div>
          <hr />
          <h4 className=" text-uppercase text-center" id="title_search_result">
            Search Results
          </h4>
          {diamondsList?.length === 0 ? (
            <p className="text-center">No results found.</p>
          ) : (
            ""
          )}
          <Row>
            {diamondsList?.map((diamond, index) => (
              <Col xs="12" sm="6" md="3">
                <Card className="card-product">
                  <div className="img-product img-diamond">
                    <Card.Img
                      variant="top"
                      src={
                        diamond?.IMAGE
                          ? diamond?.IMAGE
                          : "../../pointers/diamond_no_image.jpeg"
                      }
                      className="img-fluid"
                    />
                    {diamond?.VIDEO || diamond?.IMAGE ? (
                      <div className="viewmore text-center">
                        {diamond?.VIDEO ? (
                          <ThreeSixtyOutlined
                            onClick={(e) =>
                              handleDiamondDetailModalOpen(diamond)
                            }
                            className="buttonview"
                          />
                        ) : (
                          ""
                        )}
                        {diamond?.IMAGE ? (
                          <Fullscreen
                            onClick={(e) =>
                              handleDiamondImageModalOpen(diamond)
                            }
                            className="buttonview"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Card.Body className="text-center desc-diamond">
                    <strong className="title1">
                      MYR{" "}
                      {parseFloat(diamond?.MYR_PRICE)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </strong>
                    <Row>
                      <Col xs="12">
                        <p className="text-uppercase text-center">
                          {diamond?.LAB} Cert:{" "}
                          <a
                            href={diamond?.CERTIFICATE_LINK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {diamond?.CERTIFICATE_NO}
                          </a>
                        </p>
                      </Col>
                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>{diamond.SHAPE ? diamond.SHAPE : "-"}</strong>
                        <div className="top-dotted">Shape</div>
                      </Col>

                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>{diamond.CARAT ? diamond.CARAT : "-"}</strong>
                        <div className="top-dotted">Carat</div>
                      </Col>

                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>{diamond.COLOR ? diamond.COLOR : "-"}</strong>
                        <div className="top-dotted">Colour</div>
                      </Col>
                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>{diamond.CUT ? diamond.CUT : "-"}</strong>
                        <div className="top-dotted">Cut</div>
                      </Col>
                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>
                          {diamond.CLARITY ? diamond.CLARITY : "-"}
                        </strong>
                        <div className="top-dotted">Clarity</div>
                      </Col>
                      <Col xs="6" sm="6" className="text-center mt-2">
                        <strong>
                          {diamond.FLUORESCENCE ? diamond.FLUORESCENCE : "-"}
                        </strong>
                        <div className="top-dotted">Fluorescence</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" className="text-center my-4">
                        <Button
                          type="button"
                          variant="primary"
                          className="btn-red text-uppercase"
                          onClick={(e) =>
                            handleChooseThisDiamondOnClick(diamond)
                          }
                        >
                          Choose This Diamond
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          {isLoaded ? (
            <div className="text-center">
              <Button
                type="button"
                variant="primary"
                size="lg"
                className="btn-red text-uppercase"
                onClick={(e) => fetchData(currentPage + 1, perPage)}
              >
                Load More
              </Button>
            </div>
          ) : (
            ""
          )}
          {/* <div className="table-diamonds">
            <DataTable
              columns={columns}
              data={diamondsList}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[perPage]}
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              className="mt-3"
            />
          </div> */}
        </div>
      </div>
      <Modal
        show={showDiamondModal}
        onHide={handleDiamondModalClose}
        size="lg"
        className="modal-diamond-detail"
      >
        <Modal.Body>
          <Close
            className="icon-close"
            onClick={(e) => {
              handleDiamondModalClose();
            }}
          />
          <div className="text-center">
            <iframe
              src={diamondDetail?.VIDEO}
              title={diamondDetail?.STOCK_ID}
              width="350"
              height="350"
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDiamondImageModal}
        onHide={handleDiamondImageModalClose}
        size="lg"
        className="modal-diamond-detail"
      >
        <Modal.Body>
          <Close
            className="icon-close"
            onClick={(e) => {
              handleDiamondImageModalClose();
            }}
          />
          <div className="text-center">
            <img
              src={
                diamondDetail?.IMAGE
                  ? diamondDetail?.IMAGE
                  : "../../pointers/diamond_no_image.jpeg"
              }
              className="w-100 img-fluid"
              alt={diamondDetail?.CERTIFICATE_NO}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DiamondWizard;
