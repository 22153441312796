import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function Printing3D() {
    const headerinfo = {
        title: "3D Printing | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "3D Printing | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "3D Printing"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">

                    <h1 className=" text-uppercase">3D Printing</h1>
                    <p>Unlock the Future of Personalized Elegance with Our Revolutionary 3D Printing Service! Tailored for our clientele seeking unique and personalized jewelry creations, we are delighted to introduce an innovative 3D printing service. This cutting-edge offering is designed not only to fulfill bespoke requests but also to significantly decrease production timelines, providing our customers with enhanced convenience and a seamless experience.</p>

                    <h4 className=" text-uppercase mt-4">RING SIZER</h4>
                    <img src="/3d-printing/3d-printing-ring-sizer.jpg" className="img-fluid w-100" alt="RING SIZER" />
                    <p className="mt-3">To minimize the necessity for resizing and ensure a hassle-free experience, we are pleased to offer 3D printed ring sizers. These precision-crafted tools allow you to accurately determine your ring size, promoting a perfect fit for your cherished pieces and eliminating the need for adjustments.</p>

                    <h4 className=" text-uppercase mt-4">BESPOKE RING</h4>
                    <img src="/3d-printing/3d-printing-bespoke-ring.jpg" className="img-fluid w-100" alt="BESPOKE RING" />
                    <p className="mt-3">Our commitment to providing an unparalleled personalized experience extends to offering customers the opportunity to try on the 3D printed version of their custom-made order. This unique service enables individuals to assess and refine their chosen design before finalizing the details. Once the design is confirmed to meet their expectations, POINTERS will seamlessly proceed with the production of the jewelry in their preferred material. Whether it's the timeless allure of Platinum, the classic elegance of 18k White Gold, the romantic warmth of Rose Gold or Yellow Gold, or the opulence of 22k Gold, we ensure the realization of their vision in the finest materials.</p>

                    <p>*Exclusive to custom-made or bespoke designs, POINTERS’s 3D printing service caters specifically to individuals seeking a truly unique and personalized touch for their jewelry creations.</p>
                    <p>*The provision of 3D printed rings is reserved exclusively for confirmed custom-made orders from clients who have made an initial payment of 70% for Engagement Rings or 50% for Wedding Bands. This ensures a dedicated and personalized service for those committed to bringing their special jewelry designs to life.</p>
                </div>
            </div>
        </>
    )
}

export default Printing3D