import React from 'react';
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';

import MetaInformation from '../../components/metainformation/MetaInformation';

function PointersFAQ() {
    const headerinfo = {
        title: "Pointers FAQ | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Pointers FAQ | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Pointers FAQ"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Pointers FAQ</h1>
                    <h4 className=" text-uppercase mt-4">General Information</h4>
                    <strong>Why do Pointers offer more affordable diamonds?</strong>
                    <p>Pointers directly source diamonds from global manufacturers, allowing them to provide stones at nearly wholesale prices to retail customers. As an online-focused company, Pointers operate with lower overheads, enabling them to offer stones at a more competitive rate.</p>
                    <strong>How does the 14-day refund guarantee function?</strong>
                    <p>If you find our product quality dissatisfactory (excluding GIA certified diamonds and design preferences), contact us within 14 days for an official refund. Make arrangements to return the goods in their original condition. Upon receipt, we'll record a sealed package video for authenticity verification, and the refund will be processed within 30 working days to your bank account.</p>
                    <strong>What does the lifetime manufacturer warranty encompass?</strong>
                    <p>All Pointers&trade; products include a lifetime warranty against manufacturing defects, specifically on jewelry. Refer to our product warranty details for more information.</p>
                    <strong>How does Pointers ensure diamond authenticity?</strong>
                    <p>Pointers&trade; exclusively offers certified diamonds by GIA or HRD. To guarantee authenticity, we provide customers with pictures or videos of the laser inscription on the diamond before product delivery.</p>
                    <strong>What payment options are available, and how can I make a purchase?</strong>
                    <p>After confirming your desired item, proceed to the checkout page. You can choose to pay either 70% or 100% via Bank Transfer, Credit Card, or Cash (schedule an appointment for cash payments).</p>
                    <strong>Can I preview jewelry at your showroom before buying?</strong>
                    <p>Yes, we showcase samples highlighting Pointers' craftsmanship. If the chosen design lacks samples, we'll provide rendered images for your reference.</p>


                    <h4 className=" text-uppercase mt-4">Diamond and Jewelry Information</h4>
                    <strong>What exactly is fluorescence?</strong>
                    <p>Diamonds described as fluorescent contain particles that emit a visible (typically blue) glow when exposed to UV lighting. Although high levels of fluorescence can occasionally give a milky or hazy appearance to the stone, in most cases, it does not significantly affect its beauty or sparkle. In fact, fluorescence can enhance the perceived colorlessness of lower-grade colors (I, J, K, L, etc.).</p>
                    <strong>Do all Pointers diamond products come with certification?</strong>
                    <p>All our diamonds above 0.30 carats are accompanied by GIA Certification. Diamonds below 0.30 carats, however, do not have certification. Our Sales Invoice serves as both the certification and warranty.</p>
                    <strong>What precious metals do you utilize?</strong>
                    <p>We offer the majority of our jewelry in 18k and 22k Gold (white, rose, and yellow) as well as Platinum. Due to the density of platinum, certain intricate pieces cannot be produced in Platinum.</p>
                    <strong>Why does my white gold jewelry change color over time?</strong>
                    <p>Your white gold ring is essentially yellow gold alloyed with nickel or palladium to bleach the gold to a white color. Nickel is the most common alloy in the jewelry industry for this purpose, resulting in a slightly "gray-yellow" appearance in the final polished white gold alloy. To conceal this, white gold is usually electroplated with bright rhodium. As time progresses and the rhodium layer wears off, exposure to chloride present in household chemicals and skin perspiration can cause the exposed white gold alloy to darken to a darker gray-yellow color.</p>

                    <strong>What if I don't know my ring size?</strong>
                    <p>With every purchase, we will provide you with a complimentary ring sizer. Once payment is made, the item will be couriered to you.</p>

                    <strong>What if I order the wrong size?</strong>
                    <p>No need to worry! Your initial resize is complimentary, and you can visit our showroom to have your finger sized correctly. Alternatively, you can check with a local jeweler and then send the ring back to us. Please note that our resize allowance has a limit of three sizes up or three sizes down. If the resize falls outside this range, additional charges will apply.</p>



                    <h4 className=" text-uppercase mt-4">Shipping and Delivery Details</h4>
                    <strong>Do you deliver your products to Sabah & Sarawak?</strong>
                    <p>Yes, we do.</p>
                    <strong>Do you offer International shipping?</strong>
                    <p>We provide international shipping through Fedex. However, the shipping cost will be the responsibility of the buyer.</p>
                    <strong>Is the delivery insured?</strong>
                    <p>All items are covered by our insurance until they reach the designated delivery address, and insurance coverage extends until a signature is obtained upon delivery.</p>
                    <strong>How can I monitor the progress of my order?</strong>
                    <p>Upon dispatch of your item, we will send you an email containing a tracking number and an online link for you to monitor the delivery status of your order. Alternatively, you can access this information from your personal account.</p>

                </div>
            </div>
        </>
    )
}

export default PointersFAQ