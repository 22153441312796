import React from 'react'
import "./freeWorldwideShipping.css";
import HeaderBreadcrumb from '../../components/headerbreadcrumb/HeaderBreadcrumb';
import { Table } from 'react-bootstrap';
import MetaInformation from '../../components/metainformation/MetaInformation';

function FreeWorldwideShipping() {
    const headerinfo = {
        title: "Free Worldwide Shipping | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Free Worldwide Shipping | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "",
        label: "Free Worldwide Shipping"
    }];
    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="page-container mb-5">
                <div className="container">
                    <h1 className=" text-uppercase">Free Worldwide Shipping</h1>
                    <p>At Pointers, we understand that the anticipation of receiving your exquisite diamonds or colored gemstones is part of the joy of making a purchase. That's why we're dedicated to ensuring that once your product is dispatched from our facility, it arrives swiftly and securely at your doorstep.
                    </p>
                    <h4 className=" text-uppercase mt-4">Self-collection</h4>
                    <p className="mb-0">Orders can be collected at our Pointers Showrooms in Malaysia at the below address:</p>
                    <p><strong>Pointers Jewellers (M) Sdn. Bhd.</strong><br />
                        C3, Jalan SS 15/4D,<br />
                        47500 Subang Jaya,<br />
                        Selangor Darul Ehsan,<br />
                        Malaysia.</p>
                    <p>Global Insured Shipping, On Us!</p>
                    <p className="mb-0">Rest easy knowing that all orders come with complimentary worldwide insured shipping. Our deliveries are scheduled on weekdays, from Monday to Friday.
                    </p>
                    <div className="table-delivery">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Country/Region</th>
                                    <th>Delivery Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Malaysia</td>
                                    <td>2-5 days</td>
                                </tr>
                                <tr>
                                    <td>Malaysia (outskirts of Sabah & Sarawak)</td>
                                    <td>7 days</td>
                                </tr>
                                <tr>
                                    <td>Singapore</td>
                                    <td>3-7 days</td>
                                </tr>
                                <tr>
                                    <td>Australia</td>
                                    <td>4-7 days</td>
                                </tr>
                                <tr>
                                    <td>Philippines</td>
                                    <td>4-7 days</td>
                                </tr>
                                <tr>
                                    <td>Brunei</td>
                                    <td>3-7 days</td>
                                </tr>
                                <tr>
                                    <td>USA</td>
                                    <td>5-10 days</td>
                                </tr>
                                <tr>
                                    <td>Indonesia</td>
                                    <td>5-10 days</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <ul className="mb-3">
                        <li>Please note that shipping times are estimates and may vary.</li>
                        <li>If your country is not included in the list above, kindly get in touch with us for further details.</li>
                        <li>To ensure security, we regret that we are unable to deliver to P.O. Box addresses.</li>

                    </ul>

                    <p className="mb-0"><strong>For Malaysia Orders:</strong></p>
                    <ol>
                        <li>Enjoy FREE one-time return shipping for Resize, Refurbishment & Warranty Claim* due to Manufacturing Defects by Pointers.</li>
                        <li>For subsequent returns, shipping charges to Pointers will be covered by us, but a nominal fee of USD20 will be levied when the revised product is ready for dispatch to you.</li>

                    </ol>
                    <p className="mb-0"><strong>For International Orders:</strong></p>
                    <ol>
                        <li>Experience FREE one-time international return shipping for Warranty Claims* arising from Manufacturing Defects by Pointers exclusively. Please note that Warranty Claims do not cover Resizing or Refurbishment.</li>
                        <li>Subsequent returns for Warranty Claims will incur a fee of USD120 per shipment.</li>
                        <li>For international returns unrelated to Warranty Claims, a fee of USD120 will be charged per shipment.</li>

                    </ol>

                    <p>Please be aware that any GST, VAT, Duty Tax, or other charges incurred will be the responsibility of the customer.
                    </p>
                    <p>*Terms and conditions apply for Warranty Claims.</p>

                    <h4 className=" text-uppercase mt-4">Courier Service</h4>
                    <p>Following shipment, we will send you a tracking number and estimated delivery date.</p>
                    <div className="table-delivery">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th colSpan={2}>Courier Service</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Malaysia</td>
                                    <td>GDex	</td>
                                    <td><a href="https://www.gdexpress.com/malaysia/e-tracking" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>Singapore</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>Australia</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>Philippines</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>Brunei</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>USA</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                                <tr>
                                    <td>Indonesia</td>
                                    <td>FedEx</td>
                                    <td><a href="https://www.fedex.com/apps/fedextrack/?action=track" target="_blank" rel="noreferrer">Tracking</a></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <h4 className=" text-uppercase mt-4">Taxes & Duties</h4>
                    <p>Countries may impose GST, VAT, or Duty Tax on shipments entering their borders. The responsibility to pay these charges directly to the local tax authority lies with the purchaser</p>
                    <div className="table-delivery">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Tax</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Malaysia</td>
                                    <td>0%</td>
                                </tr>
                                <tr>
                                    <td>Singapore</td>
                                    <td>7%</td>
                                </tr>
                                <tr>
                                    <td>Australia</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>Philippines</td>
                                    <td>12%</td>
                                </tr>
                                <tr>
                                    <td>Brunei</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>USA</td>
                                    <td>0-7.25%</td>
                                </tr>
                                <tr>
                                    <td>Indonesia</td>
                                    <td>10%</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>


                    <h4 className=" text-uppercase mt-4">Delivery Package:</h4>
                    <ul>
                        <li>Every item from Pointers arrives in our signature Pointers Delivery Box, along with all relevant documents including appraisals and diamond certificates (if applicable).</li>
                        <li>Engagement rings and wedding bands are presented in specially designed ring boxes.</li>
                        <li>Loose diamonds and gemstones are securely packaged.</li>
                    </ul>
                    <p>To ensure the safety of your parcel, we use our protective delivery box and seal it with a security sticker. We strongly recommend inspecting the package for any signs of damage or tampering before accepting it.</p>
                    <p>If the security sticker appears tampered with, please do not accept the parcel and promptly contact our customer service.</p>

                    <h4 className=" text-uppercase mt-4">Fully Insured and Secure:</h4>
                    <ul>
                        <li>All international orders are fully insured by trusted luxury goods logistics companies like Malca-Amit or Brinks.</li>
                        <li>For domestic orders within Malaysia, including Sabah and Sarawak, Liberty Insurance provides full coverage.</li>

                    </ul>
                    <p>Please note that a signature is required for all deliveries, regardless of any waivers you may have with the shipping company. The person listed on the invoice must sign for receipt of the package.</p>
                </div>
            </div>
        </>
    )
}

export default FreeWorldwideShipping