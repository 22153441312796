import React, { useEffect, useRef, useState } from 'react';
import "./addressForm.css";
import HeaderBreadcrumb from '../../../components/headerbreadcrumb/HeaderBreadcrumb';
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { stateslist } from '../../../json/checkoutinfo';
import { Add, ExitToApp, Save } from '@material-ui/icons';
import useAuthorizationToken from '../../../auth/useAuthorizationToken';
import MetaInformation from '../../../components/metainformation/MetaInformation';

function AddressForm() {

    const { axiosJWT } = useAuthorizationToken();
    let params = useParams();
    const shouldRunEffect = useRef(true);
    const updateid = params?.updateid;
    const [addressAlert, setAddressAlert] = useState();
    const [address, setAddress] = useState();
    const headerinfo = {
        title: (updateid ? "Edit Address" : "Create New Address") + " | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: (updateid ? "Edit Address" : "Create New Address") + " | Pointers Jewellers",
            description: "",
            
            image: window.location.origin+"/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const breadcrumbs = [{
        url: "/addresses",
        label: "My Address Book"
    }, {
        url: "",
        label: updateid ? "Edit Address" : "Create New Address"
    }];

    const handleOnSubmitError = (err) => {
        if (err.response) {
            setAddressAlert(err.response.data);
        }
    }


    useEffect(() => {
        async function fetchAddressData() {
            try {
                const res = await axiosJWT.get(`${process.env.REACT_APP_API_ENDPOINT}/user/address`).catch((err) => {
                    // window.location.replace("/login");
                    handleOnSubmitError(err);
                });
                // setAddresses(res?.data?.addresses);
                var updatedAddress = res?.data?.addresses.filter((address) => {
                    return parseInt(address.id) === parseInt(updateid);
                });

                if (updatedAddress.length > 0) {
                    setAddress(updatedAddress ? updatedAddress[0] : null);
                } else {
                    window.location.replace("/addresses");
                }

            } catch (err) {
                
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname!=="/login"?window.location.pathname:"/profile");
                window.location.replace("/login");
            }
        }

        if (shouldRunEffect.current && parseInt(updateid) > 0) {
            fetchAddressData();
            shouldRunEffect.current = false;
        }
    }, [axiosJWT, updateid]);



    const handleInfoOnChange = (key, value) => {
        var curAddress = { ...address };
        if (key === 'name') curAddress.name = value;
        if (key === 'phone') curAddress.phone = value;
        if (key === 'address') curAddress.address = value;
        if (key === 'postal') curAddress.postal = value;
        if (key === 'city') curAddress.city = value;
        if (key === 'state') curAddress.state = value;
        if (key === 'is_default') curAddress.is_default = value;
        setAddress(curAddress);

    }

    const handleAddressOnSubmit = async e => {
        e.preventDefault();
        var res = null;
        var curAddress = { ...address };
        if (!curAddress.is_default) {
            curAddress.is_default = false;
        }
        if (updateid) {
            res = await axiosJWT.put(`${process.env.REACT_APP_API_ENDPOINT}/user/address`, curAddress).catch((err) => {
                handleOnSubmitError(err);
            });
        } else {
            res = await axiosJWT.post(`${process.env.REACT_APP_API_ENDPOINT}/user/address`, curAddress).catch((err) => {
                handleOnSubmitError(err);
            });
        }
        if (res?.data?.code === 200) {
            alert(res?.data?.message);
            window.location.replace("/addresses");
        } else {
            setAddressAlert(res?.data);
        }
    };

    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
            <div className="myprofile-content">
                <div className="container pb-5">
                    <h1 className=" text-uppercase">{updateid ? "Edit Address" : "Create New Address"}</h1>
                    {
                        addressAlert && addressAlert?.code !== 200 ? (
                            <Alert variant="danger" className="mb-0 mt-3" onClose={e => setAddressAlert(null)} dismissible>
                                {addressAlert?.message}
                            </Alert>
                        ) : ""
                    }
                    {
                        addressAlert && addressAlert?.code === 200 ? (
                            <Alert variant="success" className="mb-0 mt-3" onClose={e => setAddressAlert(null)} dismissible>
                                {addressAlert?.message}
                            </Alert>
                        ) : ""
                    }
                    <Form onSubmit={handleAddressOnSubmit}>
                        <Row>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>Name</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        required={true}
                                        value={address?.name}
                                        onChange={e => handleInfoOnChange('name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>Phone</strong></Form.Label>
                                    <div>

                                        <Form.Control type="text" value="+60" readOnly className="txt-phone-country" />
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone"
                                            required={true}
                                            value={address?.phone}
                                            className="txt-phone"
                                            onChange={e => handleInfoOnChange('phone', e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>Address</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Address"
                                        required={true}
                                        value={address?.address}
                                        onChange={e => handleInfoOnChange('address', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>Postcode</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Postcode"
                                        required={true}
                                        value={address?.postal}
                                        onChange={e => handleInfoOnChange('postal', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>City</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        required={true}
                                        value={address?.city}
                                        onChange={e => handleInfoOnChange('city', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Label className="requiredLabel"><strong>State</strong></Form.Label>
                                    <Form.Select
                                        required={true}
                                        value={address?.state}
                                        onChange={e => handleInfoOnChange('state', e.target.value)}>
                                        <option>-- Please Select State* --</option>
                                        {stateslist.map((state, index) => (
                                            <option value={state.label}>{state.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="12" sm="6">
                                <Form.Group className="m-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Set as Default Address"
                                        onChange={e => { handleInfoOnChange('is_default', e.target.checked) }} checked={address?.is_default}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="text-center mt-3">

                            <Button variant="primary" type="submit" size="lg" className="btn-red text-uppercase">
                                {updateid ? (
                                    <>
                                        <Save /> Update
                                    </>
                                ) : (
                                    <>
                                        <Add /> Create
                                    </>
                                )}
                            </Button>
                            <a href="/addresses">
                                <Button
                                    variant="outline-dark"
                                    type="button" size="lg"
                                    className="btn-white text-uppercase"
                                >
                                    <ExitToApp /> Back
                                </Button>
                            </a>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default AddressForm