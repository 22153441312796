import React, { useEffect, useRef, useState } from "react";
import "./orderHistoryList.css";
import HeaderBreadcrumb from "../../../components/headerbreadcrumb/HeaderBreadcrumb";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Alert, Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { cutsfilters } from "../../../json/diamondfilters";
import useAuthorizationToken from "../../../auth/useAuthorizationToken";
import MetaInformation from "../../../components/metainformation/MetaInformation";

function OrderHistoryList() {
  const headerinfo = {
    title: "Order History | Pointers Jewellers",
    description: "",
    keyword: "",
    author: "",
    og: {
      title: "Order History | Pointers Jewellers",
      description: "",
      
            image: window.location.origin+"/pointers/pointers_logo_social.png",
      url: window.location.href
    }
  };
  const breadcrumbs = [{
    url: "/",
    label: "Order History"
  }];
  const { axiosJWT, getAccessToken } = useAuthorizationToken();
  const navigate = useNavigate();
  const shouldRunEffect = useRef(true);
  const [orders, setOrders] = useState([]);
  const perPage = 50;
  const currentPage = 1;
  // const [currentPage, setCurrentPage] = useState(1);
  const [ordersAlert, setOrdersAlert] = useState();
  // const [isLoaded, setIsLoaded] = useState(false);

  const handleOnSubmitError = (err) => {
    if (err.response) {
      setOrdersAlert(err.response.data);
    }
  };


  useEffect(() => {
    async function fetchOrdersList() {
      var api = `${process.env.REACT_APP_API_ENDPOINT}/order/list?page=${currentPage}&limit=${perPage}`;
      const res = await axiosJWT.get(api).catch((err) => {
        // window.location.replace("/login");
        handleOnSubmitError(err);
      });
      if (res?.data?.orders) {
        setOrders(res?.data?.orders);
      } else {
        setOrders(null);
        // setOrdersAlert(res?.data);
        // window.location.replace("/login");
      }
    }

    if (!getAccessToken()) {

      
    sessionStorage.removeItem("_pointerredirect");
    sessionStorage.setItem("_pointerredirect", window.location.pathname!=="/login"?window.location.pathname:"/profile");
      window.location.replace("/login");
    } else {
      if (shouldRunEffect.current) {
        fetchOrdersList();
        shouldRunEffect.current = false;
      }
    }
  }, [axiosJWT, navigate, getAccessToken]);

  return (
    <>
      <MetaInformation headerinfo={headerinfo} />
      <HeaderBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="orderhistorylist-content">
        <div className="container mb-5">
          <h1 className=" text-uppercase">Order History</h1>
          {ordersAlert ? (
            <Col xs="12">
              <Alert
                variant="danger"
                className="mb-3"
                onClose={(e) => setOrdersAlert(null)}
                dismissible
              >
                {ordersAlert?.message ? ordersAlert?.message : ordersAlert}
              </Alert>
            </Col>
          ) : (
            ""
          )}

          {orders?.map((order, index) => (
            <a href={"/orders/" + order?.order_tracking_id}>
              <Accordion
                expanded={true}
                className="mb-3 accordion-orderhistory"
              >
                <AccordionSummary>
                  <strong>Order Number: {order?.order_tracking_id}</strong>
                  <strong className="label-order-status">
                    {order?.order_status}
                    <br />
                    {order?.created_date}
                  </strong>
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    {order?.orders?.map((item, item_index) => (
                      <>
                        <Col md="6">
                          <Card className="card-order-item mb-3">
                            <Card.Body>
                              <Row>
                                <Col md="3">
                                  <a href={"/product/" + item?.slug_id}>
                                    <img
                                      alt="Product 1"
                                      src={item?.thumbnail}
                                      className="img-fluid"
                                    />
                                  </a>
                                </Col>
                                <Col sm="6">
                                  <a href={"/product/" + item?.slug_id}>
                                    <strong className="">
                                      {item?.product_name}
                                    </strong>
                                  </a>
                                  <br />
                                  <small>
                                    <strong>
                                      Unit Price: RM
                                      {parseFloat(item?.price)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </strong>
                                  </small>
                                  <br />
                                  {item?.weight ? (
                                    <>
                                      <small>Weight: {item?.weight}</small>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {item?.name ? (
                                    <>
                                      <small>Color: {item?.name}</small>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {item?.ring_size ? (
                                    <>
                                      <small>Size: {item?.ring_size}</small>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <small>Qty: {item?.quantity}</small>
                                </Col>
                                <Col md="3" className="text-right text-price">
                                  <small>
                                    <strong>Total:</strong>
                                  </small>
                                  <br />
                                  <strong>
                                    RM
                                    {(item?.price * item?.quantity)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </strong>
                                  <br />
                                </Col>
                              </Row>
                              {item?.diamond?.CERTIFICATE_NO ? (
                                <>
                                  <Row className="mt-3">
                                    <Col md="3"></Col>
                                    <Col sm="6">
                                      <strong className="">
                                        {item?.diamond?.CARAT} CARAT{" "}
                                        {item?.diamond?.SHAPE} DIAMOND
                                      </strong>
                                      <br />
                                      <small>
                                      {item?.diamond?.LAB} Cert:{" "}
                                      <a href={item?.diamond?.CERTIFICATE_LINK} target="_blank" rel="noreferrer">{item?.diamond?.CERTIFICATE_NO}</a>
                                      </small>
                                      <br />
                                      <small>
                                        {
                                          cutsfilters.filter((cutsfilter) => {
                                            return (
                                              cutsfilter.value ===
                                              item?.diamond?.CUT
                                            );
                                          })[0]?.label
                                        }{" "}
                                        Cut, {item?.diamond?.COLOR} Color,
                                        {item?.diamond?.CLARITY} Clarity
                                      </small>
                                      <br />
                                    </Col>
                                    <Col
                                      sm="3"
                                      className="text-right text-price"
                                    >
                                      <small>
                                        <strong>Total:</strong>
                                      </small>
                                      <br />
                                      <strong>
                                        RM
                                        {parseFloat(item?.diamond?.MYR_PRICE)
                                          ?.toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </strong>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>

                  {/* <hr />
                                        <Row className="row-grandtotal">
                                            <Col className="col-total-amount">
                                                <small>Order Total:</small>
                                                <h3>RM3,930.94</h3>
                                            </Col>
                                        </Row> */}
                </AccordionDetails>
              </Accordion>
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default OrderHistoryList;
