import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import './homeBanners.css';

function HomeBanners() {

    const bannerslidersettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: "",
        prevArrow: "",
        autoplay: true,
        customPaging: function (i) {
            return (
                <div className="slick-bullet"></div>
            );
        },
    };
    return (
        <div className="banners">
            <Row>
                <Col md="12">
                    <Slider {...bannerslidersettings}>
                        
                        <div className="banner-item">
                            <a href="/">
                                <img src="../../homepage/banners/banner1.PNG" alt="Banner 1" className="w-100 d-none d-md-block" />
                                <img src="../../homepage/banners/banner1-mobile.PNG" alt="Banner 1" className="w-100 d-md-none" />
                            </a>
                            <div className="banner-item-content">
                                <h1>Cupid's Bow</h1>
                                <p>You're lovestruck. Seal the deal with unforgettable sparkle. </p>
                                <Row>
                                    <Col md="6">
                                        <a href="/products/engagementring">
                                            <Button
                                                type="button"
                                                variant="dark"
                                                className="btn-red w-100 mb-2">
                                                Shop Engagement Rings
                                            </Button>
                                        </a>
                                    </Col>
                                    <Col md="6">
                                        <a href="/products/weddingring">
                                            <Button
                                                type="button"
                                                variant="dark"
                                                className="btn-red w-100 mb-2">
                                                Shop Wedding Rings
                                            </Button>
                                        </a>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Slider>
                </Col>
            </Row>
        </div>
    )
}

export default HomeBanners