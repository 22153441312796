export const homepage = {
  categoriessliders: [
    {
      label: "Engagement Ring",
      img: "../../homepage/categories/engagement-ring.PNG",
      url: "/products/engagementring",
      linklabel: "Shop Now",
    },
    {
      label: "Women Ring",
      img: "../../homepage/categories/women-wedding-ring.PNG",
      url: "/products/rings/women",
      linklabel: "Shop Now",
    },
    {
      label: "Men Ring",
      img: "../../homepage/categories/men-wedding-ring.PNG",
      url: "/products/rings/men",
      linklabel: "Shop Now",
    },
    {
      label: "Gemstone Ring",
      img: "../../homepage/categories/gemstone-ring.PNG",
      url: "/products/rings/gemstone",
      linklabel: "Shop Now",
    },
    {
      label: "Fine Jewellery",
      img: "../../homepage/categories/fine-jewlery.PNG",
      url: "/products/necklacespendants",
      linklabel: "Shop Now",
    },
  ],
  bridals: [
    {
      img: "../../homepage/bridals/modern-wedding-ring.PNG",
      title: "Modern Wedding Ring",
      linklabel: "Shop Now",
      url: "/products/womenweddingring/modern",
    },
    {
      img: "../../homepage/bridals/classic-wedding-ring.PNG",
      title: "Classic Wedding Ring",
      linklabel: "Shop Now",
      url: "/products/womenweddingring/womenclassic",
    },
    {
      img: "../../homepage/bridals/eternity-ring.PNG",
      title: "Eternity Ring",
      linklabel: "Shop Now",
      url: "/products/womenweddingring/eternity",
    },
    {
      img: "../../homepage/bridals/curve-vintage-ring.PNG",
      title: "Curve & Vintage Ring",
      linklabel: "Shop Now",
      url: "/products/womenweddingring/curved",
    },
  ],
  brides: [
    {
      img: "../../homepage/bride-edit/men-wedding-ring.PNG",
      title: "Men's Wedding Ring",
      linklabel: "Shop Now",
      url: "/products/menweddingring",
    },
    {
      img: "../../homepage/bride-edit/classic-plain.PNG",
      title: "Classic Plain",
      linklabel: "Shop Now",
      url: "/products/menweddingring/menclassic",
    },
    {
      img: "../../homepage/bride-edit/matte.PNG",
      title: "Matte",
      linklabel: "Shop Now",
      url: "/products/menweddingring/matte",
    },
    {
      img: "../../homepage/bride-edit/mix-metal.PNG",
      title: "Mix Metal",
      linklabel: "Shop Now",
      url: "/products/menweddingring/mixedmetal",
    },
    {
      img: "../../homepage/bride-edit/unique.PNG",
      title: "Unique",
      linklabel: "Shop Now",
      url: "/products/menweddingring/menunique",
    },
  ],
  gifts: [
    {
      label: "Gifts Under RM500",
      img: "../../homepage/gifts/gift-under-500.jpeg",
      url: "/",
    },
    {
      label: "Gifts for Her",
      img: "../../homepage/gifts/gift-for-her.jpeg",
      url: "/",
    },
    {
      label: "Gifts for Him",
      img: "../../homepage/gifts/gift-for-him.jpeg",
      url: "/",
    },
    {
      label: "Heart Shaped Jewlery",
      img: "../../homepage/gifts/heart-shaped-jewlery.jpeg",
      url: "/",
    },
  ],
  google_review_url:"https://www.google.com/maps/place/Pointers+Jewellers/@3.0736723,101.5848955,17z/data=!4m8!3m7!1s0x31cc4cf5fdd989c5:0x5c45121e111ae437!8m2!3d3.0736669!4d101.5874704!9m1!1b1!16s%2Fg%2F1td563j0",
  reviews:[
    {
      rate:5,
      img:"../../homepage/reviews/Amanda Leia Ooi.png",
      name:"Amanda Leia Ooi",
      comment:"So glad that we got our wedding bands from Pointers! Top notch service from Irene! Although we’ve set our minds on getting wedding bands only, Irene was so patient in bringing us through every section of the store (absolutely no hard sale whatsoever). ",
      url:"https://g.co/kgs/tBfEhsn"
    },
    {
      rate:5,
      img:"../../homepage/reviews/Jacky Au.jpg",
      name:"Jacky Au",
      comment:"Visiting Pointer Jewellers was a delightful experience. My friends was looking for an engagement ring,I was looking for any bracelet and was impressed by the excellent customer service.",
      url:"https://g.co/kgs/1NgaTYn"
    },
    {
      rate:5,
      img:"../../homepage/reviews/Woon Teng Siew.jpg",
      name:"Woon Teng Siew",
      comment:"Superb service and really impressed by the designs this jeweller offers! Great service by Alan as well! Highly recommended. Even discovered that they can set engagement rings in 30 mins to an hour.",
      url:"https://g.co/kgs/12iRz4A"
    },
    {
      rate:5,
      img:"../../homepage/reviews/Sher Rhie Gan.jpg",
      name:"Sher Rhie Gan",
      comment:"Love the experience here! Everyone was really friendly and knowledgable. So happy and no regrets getting my wedding band from Pointers. 100% would recommend to those who loves gold and jewellery. :)",
      url:"https://g.co/kgs/kAW4kfC"
    },
    {
      rate:5,
      img:"../../homepage/reviews/Kenny Lew.jpg",
      name:"Kenny Lew",
      comment:"This is going to be my go-to store for jewelries in the future. The staffs were super friendly and accommodating. They have almost every possible designs you’d want and I even got my wedding band here.",
      url:"https://g.co/kgs/wjgWY3a"
    }
  ]
};
