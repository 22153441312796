import React from 'react';
import "./homepage.css";
import {
    shapesfilters,

} from "../../json/diamondfilters";

import Slider from 'react-slick';
import MetaInformation from '../../components/metainformation/MetaInformation';
import HomeBanners from '../../components/homebanners/HomeBanners';
import { homepage } from '../../json/homepage';
import { Button, Col, Row } from 'react-bootstrap';
import { Star } from '@mui/icons-material';

function Homepage({ setStep }) {
    const headerinfo = {
        title: "Best Jewellery Shop In Kuala Lumpur, Malaysia | Pointers Jewellers",
        description: "",
        keyword: "",
        author: "",
        og: {
            title: "Best Jewellery Shop In Kuala Lumpur, Malaysia | Pointers Jewellers",
            description: "",
            image: window.location.origin + "/pointers/pointers_logo_social.png",
            url: window.location.href
        }
    };
    const reviewsslidersetting = {
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        autoplay: true,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
        ]
    };

    const shapeslidersetting = {
        slidesToShow: 10,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 8
                }
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5
                }
            }
        ]
    };

    const categoriesslidersetting = {
        slidesToShow: 3.5,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        autoplay: true,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 2.5
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.5
            }
        }
        ]
    };

    const bridesslidersetting = {
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        autoplay: true,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 2.5
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.5
            }
        }
        ]
    };


    return (
        <>
            <MetaInformation headerinfo={headerinfo} />
            <div className="container-home">
                <HomeBanners />
                <div className="container text-center pt-5">
                    <h2>Our Customer Reviews</h2>
                    <Slider {...reviewsslidersetting} className="mt-4">
                        {
                            homepage?.reviews.map((review, idx) => (
                                <a href={review.url} target="_blank" rel="noreferrer">
                                    <div className="review px-2">
                                        <img src={review.img} alt={review?.name} className="img-profile" />
                                        <p className="mt-2 mb-0"><strong>{review?.name}</strong></p>
                                        <div>
                                            {[...Array(review?.rate)].map((_, i) => (
                                                <Star className="star-yellow" />
                                            ))}
                                        </div>
                                        <p>{review?.comment}</p>
                                    </div>
                                </a>
                            ))
                        }
                    </Slider>

                    <a href={homepage?.google_review_url} target="_blank" rel="noreferrer">
                        <Button
                            type="button"
                            variant="dark"
                            size="lg"
                            className="btn-red mb-2">
                            See More Review
                        </Button>
                    </a>
                </div>
                <div className="container text-center pt-5">
                    <h2>Shop Diamonds by Shape</h2>
                    <Slider {...shapeslidersetting}>
                        {
                            shapesfilters.map((shape, idx) => (
                                <a href={"/choose-diamond/shape/" + shape.label.toLowerCase()} onClick={setStep("diamond")}>
                                    <div className="px-1">
                                        <img src={shape.img} alt={shape.label} className="img-fluid mb-1" />
                                        {shape.label}
                                    </div>
                                </a>
                            ))
                        }
                    </Slider>
                </div>
                <div className="container pt-5">
                    <h2 className="text-center">Shop by Category</h2>
                    <Slider {...categoriesslidersetting} className="mt-4">
                        {
                            homepage?.categoriessliders.map((category, idx) => (
                                <a href={category.url}>
                                    <div className="px-2">
                                        <img src={category.img} alt={category.label} className="img-fluid" />
                                        <p className="mt-2 mb-0"><strong>{category.label}</strong></p>
                                        <p className="text-underline">{category?.linklabel}</p>
                                    </div>
                                </a>
                            ))
                        }
                    </Slider>
                </div>
                <div className="container text-center pt-5">
                    <h2>The Bridal Edit</h2>
                    <p>Distinct designs that speak to your love story.</p>
                    <Row className="row-bridals">
                        {
                            homepage?.bridals.map((bridal, idx) => (
                                <Col xs="6" className="mb-3 text-left">
                                    <a href={bridal?.url}>
                                        <img src={bridal?.img} alt={bridal?.title} className="w-100 img-fluid" />
                                        <h5 className="mt-2 mb-0">{bridal?.title}</h5>
                                        <p className="text-underline">{bridal?.linklabel}</p>
                                    </a>
                                </Col>
                            ))
                        }

                    </Row>
                </div>

                <div className="container row-bridals pt-5">

                    <a href="/">
                        <img src="../../homepage/propose-now-choose-later.PNG" className="w-100 img-fluid" alt="Propose Now Choose Later" />
                        <h5 className="mt-2">Propose Now Choose Later</h5>
                        <p className="text-underline">Shop Now</p>
                    </a>
                </div>

                <div className="container pt-5 mb-5">
                    <h2>The Bride Edit</h2>
                    <p>Distinct designs that speak to your love story.</p>
                    <Slider {...bridesslidersetting} className="mt-4">
                        {
                            homepage?.brides.map((bride, idx) => (
                                <a href={bride.url}>
                                    <div className="px-2">
                                        <img src={bride.img} alt={bride.label} className="img-fluid" />
                                        <p className="mt-2 mb-0"><strong>{bride.title}</strong></p>
                                        <p className="text-underline">{bride?.linklabel}</p>
                                    </div>
                                </a>
                            ))
                        }
                    </Slider>
                </div>
                {/* <div className="container row-bridals pt-5">
                    <h2>Top Valentine's Day Gifts</h2>
                    <Slider {...giftsslidersetting} className="mt-4">
                        {
                            homepage?.gifts.map((gift, idx) => (
                                <a href={gift.url}>
                                    <div className="px-2">
                                        <img src={gift.img} alt={gift.label} className="img-fluid" />
                                        <p className="mt-2"><strong>{gift.label}</strong></p>
                                    </div>
                                </a>
                            ))
                        }
                    </Slider>
                </div>

                <div className="container row-bridals pt-5">
                    <Row>
                        <Col md="6">
                            <a href="/"><img src="../../homepage/the-essentials-edit.jpeg" className="w-100 img-fluid" alt="Valentine's Day Gifts" /></a>
                            <h5 className="mt-2">The Essentials Edit</h5>
                            <div>It's time to refresh your jewelry box with the latest in timeless must-haves.</div>
                            <a href="/"><Button className="btn-red mt-2 mb-4" >Shop Now</Button></a>
                        </Col>
                        <Col md="6">
                            <a href="/"><img src="../../homepage/the-sol-collection.jpeg" className="w-100 img-fluid" alt="Valentine's Day Gifts" /></a>
                            <h5 className="mt-2">The Sol Collection</h5>
                            <div>Discover fine jewelry designed to capture a distinctive style and story.</div>
                            <a href="/"><Button className="btn-red mt-2 mb-4" >Shop Now</Button></a>
                        </Col>
                    </Row>
                </div>

                <div className="container row-bridals py-5">
                    <a href="/contact-us"><img src="../../homepage/we-are-here-for-you.jpeg" className="w-100 img-fluid" alt="Valentine's Day Gifts" /></a>
                    <h5 className="mt-2">We're Here For You</h5>
                    <div>Appointments curated just for you. Start your stack, find your fit, design the perfect piece.</div>
                    <a href="/contact-us"><Button className="btn-red mt-2" >Contact Us Now</Button></a>
                </div> */}

            </div>

        </>
    )
}

export default Homepage